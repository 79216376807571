import { useState, useEffect } from "react";
import "./inputPageFD.css";
import { Checkbox } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { sendUploadedData } from "../../../actions/action";
import { FileSyncButton } from "../../utility/button/button";
import { IoMdClose, IoIosLogOut, IoMdMore } from "react-icons/io";
import { MdOutlineCameraAlt } from "react-icons/md";
import { SiIconify } from "react-icons/si";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FormInp } from "../../adminPanel/adminSignup/adminSignUp";

function InputPageFD() {
  const [selStrgMdlDisplay, setSelStrgMdlDisplay] = useState(false);

  async function onDrop(e) {
    console.log("getting data on drop =========>", e);
    let formData = new FormData(),
      dataArr = [];

    e?.forEach((x) => {
      // formData.append("file", x || "");
      // formData.append("file_type", x?.type || "");
      // formData.append("model_name", "model name");
      // formData.append("action_type", 1);
      // formData.append("file_size", x?.size || 0);

      let dataObj = {
        file: x,
        file_type: x?.type || "",
        model_name: "model name",
        action_type: 1,
        file_size: x?.size || 0,
      };

      dataArr?.push(dataObj);
    });
    formData.append("fileList", dataArr);
    await sendUploadedData(formData);
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    directory: true, // Allow dropping directories
  });

  const headers = [
    "Image/ Video/ Files",
    "Model Name",
    "Size",
    "Upload Date & Time",
    "Action",
  ];
  const userData = [
    ["Image001", "Model 01", "2Mb", "23/05/2023, 12:00:10"],
    ["Video002", "Model 02", "20Mb", "23/05/2023, 12:00:10"],
    ["Image001", "Model 01", "2Mb", "23/05/2023, 12:00:10"],
    ["Video002", "Model 02", "20Mb", "23/05/2023, 12:00:10"],
    ["Image001", "Model 01", "2Mb", "23/05/2023, 12:00:10"],
    ["Video002", "Model 02", "20Mb", "23/05/2023, 12:00:10"],
    ["Image001", "Model 01", "2Mb", "23/05/2023, 12:00:10"],
    ["Video002", "Model 02", "20Mb", "23/05/2023, 12:00:10"],
    ["Image001", "Model 01", "2Mb", "23/05/2023, 12:00:10"],
    ["Video002", "Model 02", "20Mb", "23/05/2023, 12:00:10"],
    ["Image001", "Model 01", "2Mb", "23/05/2023, 12:00:10"],
    ["Video002", "Model 02", "20Mb", "23/05/2023, 12:00:10"],
    ["Image001", "Model 01", "2Mb", "23/05/2023, 12:00:10"],
    ["Video002", "Model 02", "20Mb", "23/05/2023, 12:00:10"],
    ["Image001", "Model 01", "2Mb", "23/05/2023, 12:00:10"],
    ["Video002", "Model 02", "20Mb", "23/05/2023, 12:00:10"],
    // Add more rows as needed
  ];

  const columnMinWidths = [43, 43, 43, 43, 43, 43];

  useEffect(() => {
    if (selStrgMdlDisplay) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [selStrgMdlDisplay]);

  return (
    <div className="inputPageFD">
      <SelectStorageModalComp
        display={selStrgMdlDisplay}
        closeModalhandler={() => {
          setSelStrgMdlDisplay(false);
        }}
      />
      <FileSyncButton
        title="Connect Storage"
        stylObj={{
          background: "#3F4041",
          color: "white",
          marginBottom: "1rem",
          alignSelf: "end",
        }}
        handleClick={() => {
          setSelStrgMdlDisplay(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          style={{ marginRight: "10px" }}
        >
          <path
            d="M12 5.25C10.0137 5.25 8.28809 6.17871 7.05469 7.54688C6.95215 7.53516 6.86133 7.5 6.75 7.5C5.10059 7.5 3.75 8.85059 3.75 10.5C2.45215 11.291 1.5 12.627 1.5 14.25C1.5 16.7256 3.52441 18.75 6 18.75H9.75V17.25H6C4.33594 17.25 3 15.9141 3 14.25C3 13.0049 3.75586 11.9414 4.82812 11.4844L5.34375 11.2734L5.27344 10.7109C5.25879 10.5879 5.25 10.5176 5.25 10.5C5.25 9.66211 5.91211 9 6.75 9C6.85547 9 6.97266 9.01465 7.10156 9.04688L7.57031 9.16406L7.85156 8.78906C8.8125 7.55566 10.3125 6.75 12 6.75C14.458 6.75 16.5088 8.44043 17.0859 10.7109L17.2266 11.2969L17.8594 11.2734C18.0176 11.2646 18.0527 11.25 18 11.25C19.6641 11.25 21 12.5859 21 14.25C21 15.9141 19.6641 17.25 18 17.25H14.25V18.75H18C20.4756 18.75 22.5 16.7256 22.5 14.25C22.5 11.8828 20.6338 9.9873 18.3047 9.82031C17.3877 7.18066 14.9443 5.25 12 5.25ZM12 11.25L9 14.25H11.25V20.25H12.75V14.25H15L12 11.25Z"
            fill="white"
          />
        </svg>
      </FileSyncButton>
      <div className="dropzoneWrpprFD">
        <div {...getRootProps()} className="dropzoneDashBorder">
          <input {...getInputProps()} />
          <button className="dropzoneUpldIconFD">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 5.25C10.0137 5.25 8.28809 6.17871 7.05469 7.54688C6.95215 7.53516 6.86133 7.5 6.75 7.5C5.10059 7.5 3.75 8.85059 3.75 10.5C2.45215 11.291 1.5 12.627 1.5 14.25C1.5 16.7256 3.52441 18.75 6 18.75H9.75V17.25H6C4.33594 17.25 3 15.9141 3 14.25C3 13.0049 3.75586 11.9414 4.82812 11.4844L5.34375 11.2734L5.27344 10.7109C5.25879 10.5879 5.25 10.5176 5.25 10.5C5.25 9.66211 5.91211 9 6.75 9C6.85547 9 6.97266 9.01465 7.10156 9.04688L7.57031 9.16406L7.85156 8.78906C8.8125 7.55566 10.3125 6.75 12 6.75C14.458 6.75 16.5088 8.44043 17.0859 10.7109L17.2266 11.2969L17.8594 11.2734C18.0176 11.2646 18.0527 11.25 18 11.25C19.6641 11.25 21 12.5859 21 14.25C21 15.9141 19.6641 17.25 18 17.25H14.25V18.75H18C20.4756 18.75 22.5 16.7256 22.5 14.25C22.5 11.8828 20.6338 9.9873 18.3047 9.82031C17.3877 7.18066 14.9443 5.25 12 5.25ZM12 11.25L9 14.25H11.25V20.25H12.75V14.25H15L12 11.25Z"
                fill="#00AFEF"
              />
            </svg>
          </button>
          <p>
            {isDragActive
              ? "Drop files here..."
              : "Drag / click to upload files or folders here"}
            <br />
            <span>Click Here to Upload / Choose Folder Path</span>
          </p>
        </div>
      </div>
      {/* <p className="headingParaInputPageFD">Your Files...</p> */}

      <TableCompFD
        headers={headers}
        data={userData}
        columnMinWidths={columnMinWidths}
      />
    </div>
  );
}

export default InputPageFD;

function CircularActionButtonFD({ children, bgCol = "" }) {
  return (
    <>
      <button className="cirActnBtnFD" style={{ background: bgCol }}>
        {children}
      </button>
    </>
  );
}

function ImageSvgIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M0.875 2.1875V11.8125H13.125V2.1875H0.875ZM1.75 3.0625H12.25V9.14648L9.93945 6.82227L9.625 6.50781L7.64258 8.49023L5.12695 5.94727L4.8125 5.63281L1.75 8.69531V3.0625ZM10.5 3.9375C10.0164 3.9375 9.625 4.32886 9.625 4.8125C9.625 5.29614 10.0164 5.6875 10.5 5.6875C10.9836 5.6875 11.375 5.29614 11.375 4.8125C11.375 4.32886 10.9836 3.9375 10.5 3.9375ZM4.8125 6.87695L8.83203 10.9375H1.75V9.93945L4.8125 6.87695ZM9.625 7.75195L12.25 10.377V10.9375H10.0762L8.25781 9.10547L9.625 7.75195Z"
          fill="white"
        />
      </svg>
    </>
  );
}

function DeleteSvgIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M6.125 1.75C5.896 1.75 5.66187 1.83032 5.49609 1.99609C5.33032 2.16187 5.25 2.396 5.25 2.625V3.0625H2.1875V3.9375H2.66602L3.5 11.8535L3.54102 12.25H10.459L10.5 11.8535L11.334 3.9375H11.8125V3.0625H8.75V2.625C8.75 2.396 8.66968 2.16187 8.50391 1.99609C8.33813 1.83032 8.104 1.75 7.875 1.75H6.125ZM6.125 2.625H7.875V3.0625H6.125V2.625ZM3.55469 3.9375H10.4453L9.66602 11.375H4.33398L3.55469 3.9375ZM5.25 5.25V10.0625H6.125V5.25H5.25ZM6.5625 5.25V10.0625H7.4375V5.25H6.5625ZM7.875 5.25V10.0625H8.75V5.25H7.875Z"
          fill="white"
        />
      </svg>
    </>
  );
}

function DownloadSvgIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M7 2.625C5.84131 2.625 4.83472 3.16675 4.11523 3.96484C4.05542 3.95801 4.00244 3.9375 3.9375 3.9375C2.97534 3.9375 2.1875 4.72534 2.1875 5.6875C1.43042 6.14893 0.875 6.92822 0.875 7.875C0.875 9.31909 2.05591 10.5 3.5 10.5H5.6875V9.625H3.5C2.5293 9.625 1.75 8.8457 1.75 7.875C1.75 7.14868 2.19092 6.52832 2.81641 6.26172L3.11719 6.13867L3.07617 5.81055C3.06763 5.73877 3.0625 5.69775 3.0625 5.6875C3.0625 5.19873 3.44873 4.8125 3.9375 4.8125C3.99902 4.8125 4.06738 4.82104 4.14258 4.83984L4.41602 4.9082L4.58008 4.68945C5.14062 3.96997 6.01562 3.5 7 3.5C8.43384 3.5 9.63013 4.48608 9.9668 5.81055L10.0488 6.15234L10.418 6.13867C10.5103 6.13354 10.5308 6.125 10.5 6.125C11.4707 6.125 12.25 6.9043 12.25 7.875C12.25 8.8457 11.4707 9.625 10.5 9.625H8.3125V10.5H10.5C11.9441 10.5 13.125 9.31909 13.125 7.875C13.125 6.49414 12.0364 5.38843 10.6777 5.29102C10.1428 3.75122 8.71753 2.625 7 2.625ZM6.5625 7.875V11.375H5.25L7 13.125L8.75 11.375H7.4375V7.875H6.5625Z"
          fill="white"
        />
      </svg>
    </>
  );
}

function FolderSvgIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M2.625 1.3125V12.6875H11.375V6.75391L12.127 6.00195L12.25 5.86523V1.3125H2.625ZM3.5 2.1875H9.625V5.86523L9.74805 6.00195L10.5 6.75391V11.8125H3.5V2.1875ZM10.5 2.1875H11.375V5.49609L10.9375 5.93359L10.5 5.49609V2.1875Z"
          fill="white"
        />
      </svg>
    </>
  );
}

function PlaySvgIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M3.9375 2.25586V11.7441L4.60742 11.3066L11.3066 7L4.60742 2.69336L3.9375 2.25586ZM4.8125 3.85547L9.69336 7L4.8125 10.1445V3.85547Z"
          fill="white"
        />
      </svg>
    </>
  );
}

function TableCompFD({ headers = [], data = [], columnMinWidths = [] }) {
  return (
    <>
      <table>
        <thead className="mtHeadFDFI">
          <tr>
            <th style={{ width: `50px` }}>
              <Checkbox
                //   checked={admin?.crtdUsers?.length === selectedUserList?.length}
                //   onChange={handleSelectAllUsers}
                sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
              />
            </th>
            {headers.map((title, index) => (
              <th key={index} style={{ width: `${columnMinWidths[index]}%` }}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mthCheckBoxRuleNoFDFI mtBodyFDFI">
          {data.map((row, rowIndex) => (
            <tr key={`row-${rowIndex}`} className="datasetTableRowFDFI">
              <td width={"50px"}>
                <Checkbox
                  // checked={selectedUserList?.includes(e.id)}
                  // onChange={() => {
                  //   handleSingleSelectUser(e);
                  // }}

                  sx={{
                    color: "#00AFEF",
                    "&.Mui-checked": { color: "#00AFEF" },
                  }}
                />
              </td>
              {row.map((cell, cellIndex) => (
                <td
                  key={`cell-${rowIndex}-${cellIndex}`}
                  style={{ width: `${columnMinWidths[cellIndex]}%` }}
                >
                  {cell}
                </td>
              ))}
              <td
                style={{
                  width: `${columnMinWidths[columnMinWidths?.length - 1]}%`,
                }}
              >
                <>
                  <CircularActionButtonFD bgCol="#FF004D">
                    <DeleteSvgIcon />
                  </CircularActionButtonFD>

                  <CircularActionButtonFD bgCol="#0EC096">
                    <DownloadSvgIcon />
                  </CircularActionButtonFD>

                  <CircularActionButtonFD bgCol="#00AFEF">
                    <PlaySvgIcon />
                  </CircularActionButtonFD>
                </>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

// ************************** SELECT STORAGE MODAL **************************

function SelectStorageModalComp({
  display = false,
  closeModalhandler = () => {},
}) {
  const [actvSideNavOpt, setActvSideNavOpt] = useState("cctv");

  const sideNavOptArr = [
    {
      icon: <MdOutlineCameraAlt />,
      label: "CCTV",
      slang: "cctv",
    },
    {
      icon: <IoCloudUploadOutline />,
      label: "Cloud Storage",
      slang: "cloudstrg",
    },
    {
      icon: <IoIosLogOut />,
      label: "Logout",
      slang: "logout",
    },
  ];

  return (
    <>
      <div
        className={`selStorageModalMainWrppr ${
          display ? "actvSelStrgMdl" : ""
        }`}
      >
        <div className="selStorageModalMain">
          <div className="selStorageModalHead">
            <p>Select Storage</p>
            <IoMdClose
              className="closeIconSelStrgMdlFD"
              onClick={closeModalhandler}
            />
          </div>
          <div className="selStorageModalActivitySec">
            <div className="selStorageModalSideNav">
              <SideNavOptionList
                optionList={sideNavOptArr}
                clickHandler={(v) => {
                  setActvSideNavOpt(v);
                }}
                currentOption={actvSideNavOpt}
              />
            </div>
            <div className="selStorageModalActvArea">
              {/* <CloudStorageScreen /> */}
              <RenderSelStrgModal currentScreen={actvSideNavOpt} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SideNavOptionList({
  optionList = [
    {
      icon: <SiIconify />,
      label: "label",
      slang: "ssss",
    },
  ],
  currentOption = "",
  clickHandler = () => {},
}) {
  // let tempActvCont = "cctv";

  return (
    <>
      {optionList?.map((e, i) => {
        return (
          <div
            className={`sideNavOptCont ${
              currentOption === e?.slang ? "actvSideNavOptFD" : ""
            }`}
            onClick={() => clickHandler(e?.slang)}
            key={`sideNavOptCont_SFSDFE${i}`}
          >
            <div className="iconWrpprSideNavOptSelStrg">{e?.icon}</div>
            <p>{e?.label}</p>
          </div>
        );
      })}
    </>
  );
}

function CloudPlatformCardSelStrgModal({
  title = "",
  logo = "",
  totalStrg = 15,
  occupiedStrg = 0,
}) {
  return (
    <>
      <div className="cloudPlatformCardSelStrgModalWrppr">
        <div className="cloudPlatformCardSSM_logoSec">
          <img src={logo} alt="disk" />
          <IoMdMore className="cloudPlatformCardSSM_moreIcon" />
        </div>
        <h2 className="cloudPlatformCardSSM_title">{title}</h2>
        <div className="cloudPlatformCardSSM_strg_ind_wrppr">
          {totalStrg}
          {occupiedStrg}
        </div>
        <FileSyncButton title="Connect" />
      </div>
    </>
  );
}

function CloudStorageScreen() {
  return (
    <>
      <div className="cloudPlatformCardWrpprSSMAA_outer">
        <div className="cloudPlatformCardWrpprSSMAA">
          <CloudPlatformCardSelStrgModal
            logo={"./assests/img/defaultStrgLogo.svg"}
            title={"Default Storage"}
          />
          <CloudPlatformCardSelStrgModal
            logo={"./assests/img/defaultStrgLogo.svg"}
            title={"Default Storage"}
          />
          <CloudPlatformCardSelStrgModal
            logo={"./assests/img/defaultStrgLogo.svg"}
            title={"Default Storage"}
          />
          <CloudPlatformCardSelStrgModal
            logo={"./assests/img/defaultStrgLogo.svg"}
            title={"Default Storage"}
          />
          <CloudPlatformCardSelStrgModal
            logo={"./assests/img/defaultStrgLogo.svg"}
            title={"Default Storage"}
          />
          <CloudPlatformCardSelStrgModal
            logo={"./assests/img/defaultStrgLogo.svg"}
            title={"Default Storage"}
          />
          <CloudPlatformCardSelStrgModal
            logo={"./assests/img/defaultStrgLogo.svg"}
            title={"Default Storage"}
          />
        </div>
      </div>
      <div className="selStorageModalActvArea_btnWrppr">
        <FileSyncButton
          title="Save"
          stylObj={{
            boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
            color: "white",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        />
        <FileSyncButton
          title="Cancel"
          stylObj={{
            background: "white",
            color: "#00afef",
            // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        />
      </div>
    </>
  );
}

function CCTVCameraScreen() {
  const [cameraDetails, setCameraDetails] = useState({
    name: "",
    userName: "",
    password: "",
    cameraIP: "",
    portNumber: "",
  });

// headers for camera list table ************

const headers = [
  "Camera List",
  "Active/Inactive"
];

const userData = [
  ["camera001","active"],
  ["camera002","active"],
  ["camera003","in-active"],
  ["camera004","in-active"],
];

// headers for camera list table ************

  function handleInputChange(v) {
    console.log("getting input change in add cctv camera", v);
  }
  return (
    <>
      <div className="addCCTVCameraScreenSSMAA_mainWrppr">
        <div className="addCameraBarSSMAA">
          <div className="addCameraNameFormInpWrpprSSMAA">
            <FormInp
              title={"Camera Name"}
              placeHldr={"Enter Camera Name"}
              nameStr={"name"}
              value={cameraDetails?.name}
              handlChange={handleInputChange}
            />
          </div>
          <div className="addCameraBtnWrpprSSMAA">
            <FileSyncButton title="Add" />
          </div>
        </div>
        <div className="cameraDetailsSectionSSMAA">
          <div className="selCameraListWrpprSSMAA">
            <div className="selCameraListWrpprSSMAA_innerWrppr">
            <ActvCameraTable headers={headers} data={userData} />
            </div>
          </div>
          <div className="cameraDetailsFormWrpprSSMAA">
          <div className="addCameraNameFormInpWrpprSSMAA">
            <FormInp
              title={"Camera Name"}
              placeHldr={"Enter Camera Name"}
              nameStr={"name"}
              value={cameraDetails?.name}
              handlChange={handleInputChange}
            />
          </div>
          <div className="addCameraNameFormInpWrpprSSMAA">
            <FormInp
              title={"Camera Name"}
              placeHldr={"Enter Camera Name"}
              nameStr={"name"}
              value={cameraDetails?.name}
              handlChange={handleInputChange}
            />
          </div>
          <div className="addCameraNameFormInpWrpprSSMAA">
            <FormInp
              title={"Camera Name"}
              placeHldr={"Enter Camera Name"}
              nameStr={"name"}
              value={cameraDetails?.name}
              handlChange={handleInputChange}
            />
          </div>
          <div className="addCameraNameFormInpWrpprSSMAA">
            <FormInp
              title={"Camera Name"}
              placeHldr={"Enter Camera Name"}
              nameStr={"name"}
              value={cameraDetails?.name}
              handlChange={handleInputChange}
            />
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

/* *************************************** CCTV CAMERA SCREEN TABLE *************************************** */

function ActvCameraTable({headers = [], data = [], columnMinWidths = []}) {
  return (
    <>
      <table>
        <thead className="mtHeadFDFI">
          <tr>
            {/* <th style={{ width: `50px` }}>
              <Checkbox
                //   checked={admin?.crtdUsers?.length === selectedUserList?.length}
                //   onChange={handleSelectAllUsers}
                sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
              />
            </th> */}
            {headers?.map((title, index) => (
              <th key={index} style={{ width: `${columnMinWidths[index]}%` }}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mthCheckBoxRuleNoFDFI mtBodyFDFI">
          {data?.map((row, rowIndex) => (
            <tr key={`row-${rowIndex}`} className="datasetTableRowFDFI">
              {/* <td width={"50px"}>
                <Checkbox
                  // checked={selectedUserList?.includes(e.id)}
                  // onChange={() => {
                  //   handleSingleSelectUser(e);
                  // }}

                  sx={{
                    color: "#00AFEF",
                    "&.Mui-checked": { color: "#00AFEF" },
                  }}
                />
              </td> */}
              {row?.map((cell, cellIndex) => (
                <td
                  key={`cell-${rowIndex}-${cellIndex}`}
                  style={{ width: `${columnMinWidths[cellIndex]}%` }}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

/* *************************************** CCTV CAMERA SCREEN TABLE *************************************** */

function RenderSelStrgModal({ currentScreen = "" }) {
  /*
cctv
cloudstrg
logout
*/
  if (currentScreen === "cctv") {
    return (
      <>
        <h2 className="selStrgHead">Add Camera Details</h2>
        <p className="selStrgPara">
          Connect your security camera with our software{" "}
        </p>
        <CCTVCameraScreen />
      </>
    );
  } else if (currentScreen === "cloudstrg") {
    return (
      <>
        <h2 className="selStrgHead">Select Storage Type</h2>
        <p className="selStrgPara">
          Connect your own storage with our software{" "}
        </p>
        <CloudStorageScreen />
      </>
    );
  } else if (currentScreen === "logout") {
    return <>Log Out</>;
  } else {
    return <>nothing</>;
  }
}

// ************************** SELECT STORAGE MODAL **************************
