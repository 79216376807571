import AdminAddUser from "../adminPanel/adminAddUser/adminAddUser";
import ProtectedRoute from "./protectedRoute";
import {
  HeadingContAdminRoutes,
  HeadingContRoutes,
  ContAdminAppRoutes,
  ContAdminAdvSearchRoutes,
  HeadingContPPTMakerRoutes,
  HeadingContEngTutorRoutes,
  HeadingContHomePage,
  ProductAuthorization
} from "./routes";

export function AdminAddUserPR() {
  return (
    <>
      <ProtectedRoute status="admin" redirection="/login">
        <AdminAddUser />
      </ProtectedRoute>
    </>
  );
}

export function HeadingContAdminRoutesPR() {
  return (
    <>
      <ProtectedRoute status="admin" redirection="/login">
        <HeadingContAdminRoutes />
      </ProtectedRoute>
    </>
  );
}

export function HeadingContRoutesPR() {
  return (
    <>
      <ProtectedRoute status="user" redirection="/login">
        <HeadingContRoutes />
      </ProtectedRoute>
    </>
  );
}

export function HomePageRoutesPR() {
  return (
    <>
      <ProtectedRoute status="user" redirection="/login">
        <HeadingContHomePage userType={"user"} />
      </ProtectedRoute>
    </>
  );
}

export function HomePageRoutesAdminPR() {
  return (
    <>
      <ProtectedRoute status="admin" redirection="/login">
        <HeadingContHomePage userType={"admin"} />
      </ProtectedRoute>
    </>
  );
}


export function ProductProtectionPR(){
  return (
    <>
    <ProtectedRoute status="user" redirection="/login">
      <ProductAuthorization/>
    </ProtectedRoute>
    </>
  )
}

export function ProductProtectionAdminPR(){
  return (
    <>
    <ProtectedRoute status="admin" redirection="/login">
      <ProductAuthorization/>
    </ProtectedRoute>
    </>
  )
}

export function AdminAppRoutesPR() {
  return (
    <>
      <ProtectedRoute status="admin" redirection="/login">
        <ContAdminAppRoutes />
      </ProtectedRoute>
    </>
  );
}

export function AdminAdvSearchRoutesPR() {
  return (
    <>
      <ProtectedRoute status="admin" redirection="/login">
        <ContAdminAdvSearchRoutes />
      </ProtectedRoute>
    </>
  );
}

export function PPTMakerRoutesPR() {
  return (
    <>
      <ProtectedRoute status="user" redirection="/login">
        <HeadingContPPTMakerRoutes />
      </ProtectedRoute>
    </>
  );
}


export function EngTutorRoutesPR() {
  return (
    <>
      <ProtectedRoute status="user" redirection="/login">
        <HeadingContEngTutorRoutes />
      </ProtectedRoute>
    </>
  );
}