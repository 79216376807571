import "./voiceSearchPanel.css";
import { toast } from "react-toastify";
import { useState, useEffect, useRef, useContext } from "react";
import { MdSettingsVoice } from "react-icons/md";
import {BsFillMicFill} from "react-icons/bs";
import Wave_Anim from "../utility/wave_animation/wave_anim";
import { HeadingContSearchPanel } from "../headingCont/headingCont";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  sendVoiceDataForAdmin,
  // getUsrConvForSrchPnl,
  searchByTextSrchPnl,
} from "../../actions/action";
import { MyContext } from "../../context/context";
import LoadingComponent from "../loadingSpinner/loadingSpinner";

const mimeType = "audio/webm";

function VoiceSearchPanel() {
  return (
    <div className="advSearchPanelMainSection">
      <HeadingContSearchPanel />
      <InputComp />
      <SearchPanelResultConsole />
    </div>
  );
}

export default VoiceSearchPanel;

function InputComp({ handlInp }) {
  const [inpVal, setInpVal] = useState("");

  const [permission, setPermission] = useState(false);
  const [stream, setStream] = useState(null);

  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioState, setAudioState] = useState("");

  const { admin, userDetails } = useContext(MyContext);

  function handleChange(e) {
    let val = e.target.value;
    // setInpVal(val);
    admin.upateSrchInpByText(val);
  }

  async function sendingInput() {
    console.log("sending text input...");
    if (!admin?.srchByTextInp) {
      // toast.info("can't add empty prompt");
      return;
    }
    const userType = userDetails?.user_type || "";
    let resp = await searchByTextSrchPnl(admin?.srchByTextInp, userType);

    if (!resp?.success) return;

    console.log(
      "getting searchByTextSrchPnl resp---->",
      resp?.payload?.data?.output_text
    );
    // setInpVal("");
    admin.upateSrchInpByText("");
    // await admin.addPromptData({rules:inpVal,ruleNo:'',id:''});
    let convrsn = {
      ques: admin?.srchByTextInp,
      ans: resp?.payload?.data?.output_text,
    };
    admin?.updateSrchConv(convrsn);
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      sendingInput();
    }
  }

  function handleStartRecording() {
    if (permission) {
      if (recordingStatus === "inactive") {
        startRecording();
      } else if (recordingStatus === "recording") {
        stopRecording();
      }
    } else {
      toast.info("The MediaRecorder API is not supported in your browser.");
    }
  }

  // getting permission to record audio...........

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        // console.log("Stream Data -------> ", streamData);
        setStream(streamData);
      } catch (err) {
        toast.error(err.message);
      }
    } else {
      toast.info("The MediaRecorder API is not supported in your browser.");
    }
  };

  // getting permission to record audio...........

  // Start recording audio...........

  const startRecording = async () => {
    admin.updateVoiceResp({
      audio: "",
    });

    setRecordingStatus("recording");
    //create new Media recorder instance using the stream
    const media = new MediaRecorder(stream, { type: mimeType });
    //set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };

    // setting audio processing state to listening...

    setAudioState("Listening...");

    // setting audio processing state to listening...

    setAudioChunks(localAudioChunks);
  };

  // Start recording audio...........

  // Stop recording audio...........

  async function stopRecording() {
    setRecordingStatus("inactive");

    // setting audio processing state to processing...

    setAudioState("Processing...");

    // setting audio processing state to processing...

    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = async () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      //creates a playable URL from the blob file.
      const audioUrl = URL.createObjectURL(audioBlob);
      console.log(
        "recorded audio ---------->",
        audioUrl,
        audioBlob,
        audioChunks
      );

      // sending audio blob to server....

      const formData = new FormData();
      formData.append("voice_data", audioBlob, "recorded_audio.wav");
      console.log("formated recorded audio ---------->", formData);
      const userType = userDetails?.user_type || "";
      const resp_sendVoiceData = await sendVoiceDataForAdmin(
        formData,
        userType
      );

      console.log(
        "response sendVoiceData audio ---------->",
        resp_sendVoiceData?.payload
      );

      // setting audio processing state to '' ...

      setAudioState("");

      // setting audio processing state to '' ...

      // sending audio blob to server....
      if (!resp_sendVoiceData?.success) {
        console.log(
          "getting error in speech:------->",
          resp_sendVoiceData?.payload
        );
        return;
      }

      admin.updateVoiceResp({
        text: resp_sendVoiceData?.payload?.text,
        audio: resp_sendVoiceData?.payload?.audio,
      });

      admin?.updateSrchConv(resp_sendVoiceData?.payload?.text);
      // setAudio(resp_sendVoiceData?.payload?.output_file_path);
      setAudioChunks([]);
    };
  }

  // Stop recording audio...........

  useEffect(() => {
    getMicrophonePermission();
  }, []);

  return (
    <>
      <div className="apInputWrppr inpCompAdvSrchPanel">
        <input
          type="text"
          value={admin?.srchByTextInp}
          onChange={handleChange}
          className="apInput"
          placeholder="search here..."
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
        />

        {audioState === "Processing..." ? (
          <div className="loadingWrpprAdvSrchPanelLWASP">
          <LoadingComponent
            size={15}
            styl={{ position: "relative", right: "0px", bottom: "0px" }}
            />
            </div>
        ) : (
          <>
            {/* <div
              className={`recordIconWrpprSrchPanel ${
                permission
                  ? recordingStatus === "recording"
                    ? "greenVoiceBtn"
                    : ""
                  : "greyVoiceBtn"
              }`}
              onClick={handleStartRecording}
            >
              <MdSettingsVoice size={"24px"} />
            </div> */}

            <div
              className={`recordIconWrpprSrchPanel ${
                permission
                  ? recordingStatus === "recording"
                    ? "greenVoiceBtnAdvSrch"
                    : ""
                  : "greyVoiceBtnAdvSrch"
              }`}
              onClick={handleStartRecording}
            >
              <BsFillMicFill size={"18px"} />
              <Wave_Anim
                switchVar={permission && recordingStatus === "recording"}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

function SearchPanelResultConsole() {
  const { admin } = useContext(MyContext);
  const audioRef = useRef(null),
    chatContRef = useRef(null);

  function handleRefresh() {
    admin.resetSrchConv();
    admin.upateSrchInpByText("");
    stopAudioPlaying();
  }

  // Stop playing audio...........

  function stopAudioPlaying() {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset playback to the beginning
    }
  }

  // Stop playing audio...........

  // async function updatingUsrConv() {
  //   let respUsrConv = await getUsrConvForSrchPnl();

  //   console.log(
  //     "getting usr conv response in comp--->",
  //     respUsrConv?.payload?.text
  //   );
  //   admin.updateSrchConv({ text: respUsrConv?.payload?.text });
  // }

  function scrollToBottom() {
    chatContRef.current.scrollTop = chatContRef.current.scrollHeight;
  }

  useEffect(() => {
    scrollToBottom();
  }, [admin?.searchConv]);

  return (
    <>
      {admin?.voiceResp?.audio ? (
        <div className="audio-container" style={{ display: "none" }}>
          <audio
            ref={audioRef}
            src={admin?.voiceResp?.audio}
            controls
            autoPlay
          ></audio>
        </div>
      ) : null}
      <div className="uploadContWrpprAdvSrch">
        <div className="topUploadContAdvSrch" ref={chatContRef}>
          {admin?.searchConv?.map((e) => (
            <>
              <div className="chat_bubble_wrppr chat_bubble_questn">
                <div className="chat_bubble chat_bubble_ques_radius">
                  {e.ques}
                </div>
              </div>
              <div className="chat_bubble_wrppr chat_bubble_answer">
                <div className="chat_bubble chat_bubble_ans_radius">
                  {e.ans}
                </div>
              </div>
            </>
          ))}
        </div>
        <div className="bottomUploadContAdvSrch">
          <RefreshIcon
            style={{ cursor: "pointer", color: "#00AFEF" }}
            onClick={handleRefresh}
          />
        </div>
      </div>
    </>
  );
}
