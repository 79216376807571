import { useContext, useEffect, useState } from "react";

import { HeadingCont, HeadingContAdmin } from "../headingCont/headingCont.js";
import ActivityArea from "../activityArea/activityArea.js";
import AdminPanel from "../adminPanel/adminPanel.js";
import Header from "../header/header.js";
import VoiceSearchPanel from "../voiceSearchPanel/voiceSearchPanel.js";
import HomePptm from "../pptMaker/pptMHome/pptMHome.js";
import Eng_tutor from "../english_tutor/eng_tutor.js";
import HomePage from "../homePage/homePage.js";

import { MyContext } from "../../context/context.js";
import { useParams } from "react-router-dom";
import { reDirectTo } from "../utility/functions/helperFunc.js";
import { toast } from "react-toastify";
import LoadingComponent from "../loadingSpinner/loadingSpinner.js";

export function HeadingContRoutes({ userType = "user" }) {
  return (
    <>
      <Header userStatus={userType} />
      <HeadingCont />
      <ActivityArea />
    </>
  );
}

export function HeadingContAdminRoutes() {
  return (
    <>
      <Header userStatus={"admin"} />
      <HeadingCont />
      <AdminPanel />
    </>
  );
}

export function ContAdminAppRoutes() {
  return (
    <>
      <Header userStatus={"admin"} />
      <HeadingCont />
      <ActivityArea />
    </>
  );
}

export function ContAdminAdvSearchRoutes({ userType = "user" }) {
  return (
    <>
      <Header userStatus={userType} />
      <VoiceSearchPanel />
    </>
  );
}

export function HeadingContPPTMakerRoutes({ userType = "user" }) {
  return (
    <>
      <Header userStatus={userType} />
      <HomePptm />
    </>
  );
}

export function HeadingContEngTutorRoutes({ userType = "user" }) {
  return (
    <div className="mainContWrpprEngTutor">
      <Header userStatus={userType} />
      <Eng_tutor />
    </div>
  );
}

export function HeadingContHomePage({ userType = "user" }) {
  // const { userDetails } = useContext(MyContext);

  // const userType = userDetails?.user_type || "";
  // console.log("user type homepage head cont ----->",userType);
  return (
    <>
      <Header userStatus={userType} />
      <HomePage />
    </>
  );
}

export function ProductAuthorization() {
  const { userDetails } = useContext(MyContext);
  const { productId } = useParams();
  const [status, setStatus] = useState("load");
  const [productDetlST, setProductDetlST] = useState({
    usrTyp: "",
    productToken: "",
  });
  const currentUrl = window.location.href;

  console.log("product Auth current url ---------->", currentUrl);
  console.log("product Auth productId ---------->", productId);
  console.log("product Auth user details ---------->", userDetails);

  function checkProductAccess() {
    const arr = userDetails?.product_access || [],
      userType = userDetails?.user_type || "";

    // console.log("prod acccess arr ---->",arr);
    const IsAccess = arr.some((x) => x?.id == productId);
    let prodToken = "";
    arr.forEach((x) => {
      if (x?.id == productId) {
        prodToken = x?.slug;
      }
    });
    setProductDetlST({ productToken: prodToken, usrTyp: userType });
    // console.log("isaccess variable ---->",IsAccess);
    if (IsAccess) {
      setStatus("success");
    } else {
      setStatus("failed");
      let x = setTimeout(()=>{
        toast.warn("Unauthorize user");
        clearTimeout(x);
      },1500)
      if (userType === "user") {
        reDirectTo("/");
      } else if (userType === "admin") {
        reDirectTo("/admin");
      }
    }
  }

  useEffect(() => {
    checkProductAccess();
  }, []);

  return (
    <>
      {status === "success" && (
        <>
          <RenderProductsByProdToken productToken={productDetlST?.productToken} userType={productDetlST?.usrTyp} />
        </>
      )}
      {status === "load" && (
        <>
        <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingComponent
          styl={{
            position: "relative",
            top: "0px",
            bottom: "0px",
            right: "0px",
          }}
        />
      </div>
        </>
      )}
    </>
  );
}

function RenderProductsByProdToken({ productToken, userType = "user" }) {
  // need to asign some token to each app

  switch (productToken) {
    case process.env.REACT_APP_DOC_SUMM:
      // doc summarizer...
      return <HeadingContRoutes userType={userType} />;
    case process.env.REACT_APP_SRCH_PANEL:
      // search panel...
      return <ContAdminAdvSearchRoutes userType={userType} />;
    case process.env.REACT_APP_LANG_TUT:
      // language tutor...
      return <HeadingContEngTutorRoutes userType={userType} />;
    case process.env.REACT_APP_PPT_GEN:
      // ppt generator...
      return <HeadingContPPTMakerRoutes userType={userType} />;
    default:
      return <>not authorized</>;
  }
}
