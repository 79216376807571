import { toast } from "react-toastify";

export function reDirectTo(str = "") {
  let crrntURL = window.location.href;
  crrntURL = crrntURL.split("/")[0];
  crrntURL = crrntURL + str;
  window.location.href = crrntURL;
}

export function readAndUpdateRoutes(str = "") {
  let crrntURL = window.location.href;
  if (!crrntURL.includes("/admin")) return;
  crrntURL = crrntURL.split("/admin");
  console.log("readAndUpdate111111----------->", crrntURL);
  crrntURL = crrntURL[crrntURL.length - 1];
  console.log("readAndUpdateRoutes----------->", crrntURL);

  if (crrntURL === "/voiceSearchPanel") {
    console.log("inside voiceSearchPanel----------->", crrntURL);
  } else if (crrntURL === "/addUser") {
    console.log("inside addUser----------->", crrntURL);
  } else if (crrntURL === "/app") {
    console.log("inside app----------->", crrntURL);
  } else if (crrntURL === "") {
    console.log("inside admin----------->");
  }
}

export function validateEmail(email) {
  let err = "";
  if (!email) {
    err = "Email is required.";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    err = "Invalid email format.";
  }

  return err;
}
export function validateMobile(mobileNumber) {
  let err = "";
  if (!mobileNumber) {
    err = "Mobile number is required.";
  } else if (!/^\d{10}$/.test(mobileNumber)) {
    err = "Invalid mobile number format. It should contain 10 digits.";
  }

  return err;
}
// export function validateForm(firstName, lastName, email, mobileNumber) {
//   let errors = {};

//   if (!firstName) {
//     errors.firstName = "First name is required.";
//   }

//   if (!lastName) {
//     errors.lastName = "Last name is required.";
//   }

//   if (!email) {
//     errors.email = "Email is required.";
//   } else if (!/\S+@\S+\.\S+/.test(email)) {
//     errors.email = "Invalid email format.";
//   }

//   if (!mobileNumber) {
//     errors.mobileNumber = "Mobile number is required.";
//   } else if (!/^\d{10}$/.test(mobileNumber)) {
//     errors.mobileNumber =
//       "Invalid mobile number format. It should contain 10 digits.";
//   }
//   let arr = Object.keys(errors);
//   if (arr.length === 0) {
//     return false;
//   }
//   // console.log("arr from validation function..",arr);
//   return errors;
// }

export function formatChatDate(inputDate) {
  const currentDate = new Date();
  const chatDate = new Date(inputDate);

  // Check if the date is today
  if (
    chatDate.getDate() === currentDate.getDate() &&
    chatDate.getMonth() === currentDate.getMonth() &&
    chatDate.getFullYear() === currentDate.getFullYear()
  ) {
    return `Today, ${formatTime(chatDate)}`;
  }

  // Check if the date is yesterday
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  if (
    chatDate.getDate() === yesterday.getDate() &&
    chatDate.getMonth() === yesterday.getMonth() &&
    chatDate.getFullYear() === yesterday.getFullYear()
  ) {
    return `Yesterday ${formatTime(chatDate)}`;
  }

  // For older dates
  const formattedDate = chatDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  return `${formattedDate}, ${formatTime(chatDate)}`;
}

function formatTime(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes}${ampm}`;
}

// Example usage
// const inputDate = "2023-10-04T11:39:53.366765Z";
// const formattedChatDate = formatChatDate(inputDate);
// console.log(formattedChatDate);

export async function playAudioOnce(audioFilePath = "") {
  if (!audioFilePath) {
    console.log("empty audio url, early return........");
    return { status: false };
  }
  return new Promise((resolve, reject) => {
    console.log("getting audio url from component------>");
    const audio = new Audio(audioFilePath);

    audio.onerror = () => {
      console.error("Error loading audio:", audioFilePath);

      // You can provide user-friendly feedback here, like displaying a message on the UI

      toast.error("error loading the audio file");

      reject({
        status: false,
      });
    };

    audio.onloadedmetadata = () => {
      // Play the audio
      audio.play();
      console.log("playing the audio file.....");
      // Function to stop the audio
      const stopAudio = () => {
        audio.pause();
        audio.currentTime = 0;
      };
      resolve({
        stopAudio,
        audioDuration: audio.duration * 1000, // Convert seconds to milliseconds
        status: true,
      });
      // Return an object with stopAudio function and audioDuration in milliseconds

      // return {
      //   stopAudio,
      //   audioDuration: audio.duration * 1000, // Convert seconds to milliseconds
      // };
    };
  });
}

export async function waitingFunction(milisec = 0) {
  return new Promise((res) => {
    setTimeout(() => {
      console.log("wait ended -------->");
      res(true);
    }, milisec);
  });
}



export function formatDateForNotes(dateString) { // "2023-12-19T09:26:35.854796Z"  =========>  "December 19 at 2:56 PM"
  const options = {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;
}

// const inputDateString = "2023-12-19T09:26:35.854796Z";
// const formattedDate = formatDateForNotes(inputDateString);

// console.log(formattedDate); // Output: "December 19, 2023, 9:26 AM"
