import "./pptMHome.css";

import React, { useState, useRef, useContext, useEffect } from "react";
import { FileSyncButton } from "../../utility/button/button";
import { BsFillMicFill } from "react-icons/bs";
import { MyContext } from "../../../context/context";
import { toast } from "react-toastify";
import LoadingComponent from "../../loadingSpinner/loadingSpinner";
import { BiArrowBack } from "react-icons/bi";
import { FcDocument } from "react-icons/fc";
import { AiFillCloseCircle } from "react-icons/ai";
import Wave_Anim from "../../utility/wave_animation/wave_anim";
import { WiStars } from "react-icons/wi";


const mimeType = "audio/webm";

function HomePptm() {
  const [permission, setPermission] = useState(false);
  const [stream, setStream] = useState(null);
  const [toRender, setToRender] = useState({
    swtchVal: 1,
    fileInpTxt: "",
    BGInpTxt: "",
  });
  const [dwnldPPTURL, setDwnldPPTURL] = useState("");

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(streamData);
      } catch (err) {
        toast.error(err.message);
      }
    } else {
      toast.info("The MediaRecorder API is not supported in your browser.");
    }
  };

  useEffect(() => {
    getMicrophonePermission();
  }, []);

  return (
    <>
      
      {toRender.swtchVal === 1 ? (
        <InitialHomeCompPptm
          stream={stream}
          permission={permission}
          switchHandler={(v) => setToRender({ ...v })}
          updateDwnlFile={(v) => {
            setDwnldPPTURL(v);
          }}
        />
      ) : (
        <PresentationPreviewCompPptm
          stream={stream}
          permission={permission}
          switchHandler={(v) => setToRender({ ...v })}
          dwnldFileURL={dwnldPPTURL}
          prevRetainVals={toRender}
          updateDwnlFile={(v) => {
            setDwnldPPTURL(v);
          }}
        />
      )}
    </>
  );
}

export default HomePptm;

function InitialHomeCompPptm({
  stream,
  permission,
  switchHandler,
  updateDwnlFile,
}) {
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadImgs, setUploadImgs] = useState({ srcUrl: "", filesList: [] });
  const [textFileCont, setTextFileCont] = useState("");
  const [textBGDetail, setTextBGDetail] = useState("");
  const [voiceInpFileCont, setVoiceInpFileCont] = useState(null);
  const [voiceInpBGDetl, setVoiceInpBGDetl] = useState(null);
  const [isLoadingPPT, setIsLoadingPPT] = useState(false);
  const [disableAllInp, setDisableAllInp] = useState(false);

  const { presentationMaker } = useContext(MyContext);

  async function updateTxtFilesList(files) {
    console.log("getting selected files in the parent cont....", files);
    if (!files[0]) {
      console.log("inside emply file list ------->");
      setUploadFiles([]);
      return;
    }
    const newFiles = [];
    for (let i = 0; i < files.length; i++) {
      newFiles.push(files[i]);
    }
    console.log("generating new files array parent cont ------->", newFiles);
    
    setUploadFiles([...uploadFiles, ...newFiles]);

    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("topic_file", files[i]);
    }
    let resp = await presentationMaker?.reqFileToText(formData);

    console.log("file to text response ------->", resp);
    if (resp?.success) {
      console.log("file to text response ------->", resp?.payload);
      setTextFileCont(resp?.payload?.topic_text);
    }
  }

  function updateImgFilesList(files) {
    console.log("getting selected images in the parent cont....", files);
    

    const newFiles = [];
    if (!files[0]) {
      setUploadImgs("");
      return console.log("no files to render ---->");
    }
    for (let i = 0; i < files.length; i++) {
      newFiles.push(files[i]);
    }
    let reader = new FileReader();
    reader.onload = (event) => {
      console.log("inside onload image ------->", event.target.result);
      setUploadImgs({ srcUrl: event.target.result, filesList: newFiles });
    };
    reader.readAsDataURL(files[0]);
    console.log("new files array ------>", newFiles);
  }

  function removeFilesFromFileList(name) {
    if (disableAllInp) return;
    setTextFileCont("");
    let newFL = [];
    console.log("getting name remove files ------>", name);
    
    console.log("updated FL remove files ------>", newFL);
    setUploadFiles(newFL);
  }

  async function generatePPTHandler() {
    let myGenPPTObj = {
      file: {
        txt: textFileCont,
        file: uploadFiles,
        voice: voiceInpFileCont,
      },
      BGDetl: {
        txt: textBGDetail,
        file: uploadImgs.filesList,
        voice: voiceInpBGDetl,
      },
    };

    const variablesToCheck = [
      textFileCont,
      uploadFiles[0],
      voiceInpFileCont,
      textBGDetail,
      uploadImgs.filesList,
      voiceInpBGDetl,
    ];

    if (variablesToCheck.every((variable) => !variable)) {
      return; // If none of the variables hold a value, return early
    } else if (!textFileCont && !uploadFiles[0] && !voiceInpFileCont) {
      toast.warn("Please provide some content for PPT");
      return;
    }

    setDisableAllInp(true);

    let formData = new FormData();

    // appending to from data.....

    console.log("getting topic file", myGenPPTObj?.file?.file);

    formData.append("topic_text", myGenPPTObj?.file?.txt || "");

    for (let i = 0; i < myGenPPTObj?.file?.file?.length; i++) {
      formData.append("files", myGenPPTObj?.file?.file[i]);
    }

    formData.append("topic_file", myGenPPTObj?.file?.file || "");
    formData.append("topic_voice_data", myGenPPTObj?.file?.voice || "");

    if(myGenPPTObj?.BGDetl?.file?.length){

      for (let i = 0; i < myGenPPTObj?.BGDetl?.file?.length; i++) {
        formData.append("background_image", myGenPPTObj?.BGDetl?.file[i] || "");
      }
    }else{
      formData.append("background_image", "");
    }


    formData.append("background_text", myGenPPTObj?.BGDetl?.txt || "");
    formData.append("background_voice", myGenPPTObj?.BGDetl?.voice || "");

    console.log("generate ppt with following details -------->", myGenPPTObj);
    setIsLoadingPPT(true);
    let response = await presentationMaker.requestGeneratePPt(formData);
    setDisableAllInp(false);
    setIsLoadingPPT(false);
    if (response.success) {
      switchHandler({
        swtchVal: 2,
        fileInpTxt: textFileCont,
        BGInpTxt: textBGDetail,
      });
      updateDwnlFile(response?.payload?.ppt_file || "");
      presentationMaker.updateImgListToPrev(
        response?.payload?.ppt_sildes_images
      );
      let x = setTimeout(() => {
        toast.success("PPT generate successfully !");
        clearTimeout(x);
      }, 1500);
    } else {
      console.log("ppt generation failed !");
    }
  }

  return (
    <>
      <div className="mainWrpprPptM">
        <Title />
        <InputTool
          optnsObj={{
            label: "Tell Us About Topic",
            plchldr: "eg: Best Practices for Remote Work",
          }}
          prMssn={permission}
          strm={stream}
          extTyp={".txt"}
          uplBtnLabel={"Upload Files"}
          updateFiles={updateTxtFilesList}
          multiple={true}
          key={"inputfilecontpptmaker"}
          textInp={textFileCont}
          updateTextInp={(v) => setTextFileCont(v)}
          updateVoiceInp={(v) => setVoiceInpFileCont(v)}
          disableAllInp={disableAllInp}
          label={"fileContent"}
        />
        <div className="filesDisplayWrpprPPTMaker1">
          {uploadFiles.map((e, i) => {
            console.log("upld files main UI ----->", e);
            return (
              <FilesPrevComp
                label={e.name}
                key={`FDWPPTMFileList${i}`}
                closeHandler={removeFilesFromFileList}
              >
                <FcDocument />
              </FilesPrevComp>
            );
          })}
        </div>
        <InputTool
          optnsObj={{
            label: "What kind of background you want ?",
            plchldr:
              "eg: Need royal background with clean white with some gradient",
          }}
          prMssn={permission}
          strm={stream}
          extTyp={".jpg,.jpeg"}
          uplBtnLabel={"Upload Image"}
          updateFiles={updateImgFilesList}
          multiple={false}
          key={"inputBGDetailspptmaker"}
          textInp={textBGDetail}
          updateTextInp={(v) => setTextBGDetail(v)}
          updateVoiceInp={(v) => setVoiceInpBGDetl(v)}
          disableAllInp={disableAllInp}
          label={"background"}
        />
        <div
          className="filesDisplayWrpprPPTMaker"
          style={{
            maxWidth: "972px",
            width: "100%",
            marginBottom: "15px",
          }}
        >
          {uploadImgs?.srcUrl && (
            <div className="imgWrpprUploadedImgFDWPM">
              <img
                src={uploadImgs?.srcUrl}
                alt="Preview"
                style={{ maxWidth: "275px", borderRadius: "10px" }}
              />
              <AiFillCloseCircle
                className="uploadImgFDWPMCloseBtn"
                onClick={() => {
                  if (disableAllInp) return;
                  setUploadImgs(null);
                }}
              />
            </div>
          )}
        </div>
        <div className="btnWrpprGenPPT">
          {isLoadingPPT ? (
            <div className="btnWrpprLoadingSpinnerPPTmaker">
              <LoadingComponent
                size={15}
                styl={{ position: "relative", right: "0px", bottom: "0px" }}
              />
            </div>
          ) : (
            <FileSyncButton
              src={"generatePptIcon.svg"}
              title={"Generate Presentation"}
              handleClick={() => {
                console.log("generating ppt --------->");
                generatePPTHandler();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

function Title() {
  return (
    <>
      <p className="paraTitlePptm boldTitlePptM marginTitleMain">
        AI Presentation Maker -<br /> The Effortless Way to Generate
        Presentations
      </p>
      <p className="paraTitlePptm" style={{ marginBottom: "39px" }}>
        Generate Professional and On-Brand Decks Without Spending Hours on
        Design.
      </p>
    </>
  );
}

function InputTool({
  optnsObj,
  prMssn,
  strm,
  extTyp = ".txt",
  uplBtnLabel = "",
  updateFiles,
  textInp,
  updateTextInp,
  updateVoiceInp,
  disableAllInp = false,
}) {
  const [inpValImgRef, setInpValImgRef] = useState("");

  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioState, setAudioState] = useState("");

  const { presentationMaker } = useContext(MyContext);

  const fileInputTag = useRef(null);

  function handleChange(e) {
    let val = e.target.value;
    console.log("taking input in ppt --------> ", val);
    updateTextInp(val);
  }

  function handleClickTextInput() {
    console.log("selecting input type as text....");
    updateFiles([]);
    updateVoiceInp(null);
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  }

  function handleStartRecording() {
    console.log("selecting input type as voice...");
    if (disableAllInp) return;
    
    updateFiles([]);
    updateTextInp("");
    if (prMssn) {
      if (recordingStatus === "inactive") {
        startRecording();
      } else if (recordingStatus === "recording") {
        stopRecording();
      }
    } else {
      toast.info("The MediaRecorder API is not supported in your browser.");
    }
  }

  // Start recording audio...........

  const startRecording = async () => {

    setRecordingStatus("recording");
    //create new Media recorder instance using the stream
    const media = new MediaRecorder(strm, { type: mimeType });
    //set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };

    // setting audio processing state to listening...

    setAudioState("Listening...");

    // setting audio processing state to listening...

    setAudioChunks(localAudioChunks);
  };

  // Start recording audio...........

  // Stop recording audio...........

  async function stopRecording() {
    setRecordingStatus("inactive");

    // setting audio processing state to processing...

    setAudioState("Processing...");

    // setting audio processing state to processing...

    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = async () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      //creates a playable URL from the blob file.
      const audioUrl = URL.createObjectURL(audioBlob);
      console.log(
        "recorded audio ---------->",
        audioUrl,
        audioBlob,
        audioChunks
      );

      // sending audio blob to server....

      const formData = new FormData();
      formData.append("topic_voice_data", audioBlob, "recorded_audio.wav");
      console.log("formated recorded audio ---------->", formData);

      updateVoiceInp(audioBlob);
      const resp_voiceToTextData = await presentationMaker.reqVoiceToText(
        formData
      );

      console.log(
        "response voiceToTextData audio ---------->",
        resp_voiceToTextData?.payload,
        resp_voiceToTextData
      );
      if (resp_voiceToTextData?.success) {
        console.log(
          "response voiceToText 1111 ------->",
          resp_voiceToTextData?.payload
        );
        updateTextInp(resp_voiceToTextData?.payload?.topic_text);
        
      }
      // setting audio processing state to '' ...

      setAudioState("");

      // setting audio processing state to '' ...

      setAudioChunks([]);
    };
  }

  // Stop recording audio...........

  // updating upload file list ..........

  async function updateUploadFileList(e) {
    let files = e.target.files;
    console.log("uploading files -------->", files);

    updateFiles(files);
  }

  // updating upload file list ..........

  // choosing files to upload ..........

  function chooseFilesToUpload() {
    if (disableAllInp) return;
    setInpValImgRef("");
    fileInputTag.current.click();
    updateTextInp("");
    updateVoiceInp(null);
  }

  // choosing files to upload ..........

  return (
    <div className="mainInpWrpprPptm">
      <p className="inputLabelPptm">{optnsObj?.label}</p>
      <div className="mainInputToolWrpprPptm">
        <input
          type="text"
          className="inputPptm"
          placeholder={optnsObj?.plchldr}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
          onChange={handleChange}
          disabled={disableAllInp}
          onClick={() => handleClickTextInput()}
          value={textInp}
        />
        <div className="inputToolBtnWrpprPptm">
          <div
            className="wrpprUpoadFileBtnPPTmaker"
            onClick={chooseFilesToUpload}
          >
            <FileSyncButton
              src={"uploadBtnIcon.png"}
              title={uplBtnLabel}
              disabled={disableAllInp}
            />
            <input
              ref={fileInputTag}
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                updateUploadFileList(e);
              }}
              accept={extTyp}
              value={inpValImgRef}
              disabled={disableAllInp}
            />
          </div>

          {audioState === "Processing..." ? (
            <div className="micLoadingWrapperPPTMScrn1Inpt">
              <LoadingComponent
                size={15}
                styl={{ position: "relative", right: "0px", bottom: "0px" }}
              />
            </div>
          ) : (
            <div
              className={`microPhoneWrpprPptm ${
                prMssn
                  ? recordingStatus === "recording"
                    ? "greenVoiceBtn"
                    : ""
                  : "greyVoiceBtn"
              }`}
              onClick={handleStartRecording}
            >
              <BsFillMicFill size={"18px"} />
              <Wave_Anim
                switchVar={prMssn && recordingStatus === "recording"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// *********************************** PRESENTATION PREVIEW COMPONENTS ***********************************

let ImgArrayForCarousel = [
  "https://images.unsplash.com/photo-1695056721216-dbf554a091a6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
  "https://images.unsplash.com/photo-1682685795557-976f03aca7b2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80",
  "https://images.unsplash.com/photo-1695048987437-488232574ac1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
  "https://plus.unsplash.com/premium_photo-1680127401632-dcc0f571d4b9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
  "https://plus.unsplash.com/premium_photo-1688821129698-eb8a2a514057?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
  "https://images.unsplash.com/photo-1694845482666-7bf0c05d40ac?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
  "https://images.unsplash.com/photo-1687360440731-d124aea41a3e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
  "https://images.unsplash.com/photo-1694845482539-373728496364?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
];

function PresentationPreviewCompPptm({
  stream,
  permission,
  switchHandler,
  dwnldFileURL,
  prevRetainVals,
  updateDwnlFile,
}) {
  const [openMenu, setOpenMenu] = useState(true);
  const [openMenuDelay, setOpenMenuDelay] = useState(true);
  const [textBGDetail, setTextBGDetail] = useState(
    prevRetainVals?.BGInpTxt || ""
  );
  const [voiceBGDetl, setVoiceBGDetl] = useState(null);
  const [textFileInp, setTextFileInp] = useState(
    prevRetainVals?.fileInpTxt || ""
  );
  const [voiceFileInp, setVoiceFileInp] = useState(null);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadImgs, setUploadImgs] = useState("");
  const [disableAllInp, setDisableAllInp] = useState(false);
  const [isLoadingPPT, setIsLoadingPPT] = useState(false);

  const [compPropImgList, setCompPropImgList] = useState([]);

  const { presentationMaker } = useContext(MyContext);

  function toggleSlideContiner() {
    if (openMenu) {
      setOpenMenu(!openMenu);
      setOpenMenuDelay(!openMenuDelay);
    } else {
      let x = setTimeout(() => {
        setOpenMenuDelay(!openMenuDelay);
        clearTimeout(x);
      }, [500]);
      setOpenMenu(!openMenu);
    }
  }

  function dwnldHandlerPPT() {
    console.log("downloading ppt file ---------->", dwnldFileURL);
    if (!dwnldFileURL) {
      console.log("early return download ppt");
      return;
    }
    const fileUrl = dwnldFileURL;
    let fileNameArr = fileUrl.split("/");
    let fileName = fileNameArr[fileNameArr.length - 1];

    // Create a hidden anchor element
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    document.body.appendChild(anchor);

    // Set the href attribute of the anchor element to the file URL
    anchor.href = dwnldFileURL;

    // Set the download attribute to specify the default file name
    anchor.download = fileName;

    // Trigger a click event on the anchor element
    anchor.click();

    // Remove the anchor element from the DOM
    document.body.removeChild(anchor);
  }
  async function updateTxtFilesList(files) {
    console.log("getting selected files in the parent cont....", files);
    if (!files[0]) {
      console.log("inside emply file list ------->");
      setUploadFiles([]);
      return;
    }
    const newFiles = [];
    for (let i = 0; i < files.length; i++) {
      newFiles.push(files[i]);
    }
    console.log("generating new files array parent cont ------->", newFiles);
    
    setUploadFiles([...uploadFiles, ...newFiles]);

    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("topic_file", files[i]);
    }
    let resp = await presentationMaker?.reqFileToText(formData);

    console.log("file to text response ------->", resp);
    if (resp?.success) {
      console.log("file to text response ------->", resp?.payload);
      setTextFileInp(resp?.payload?.topic_text);
    }
  }

  function updateImgFilesList(files) {
    console.log("getting selected images in the parent cont....", files);
    

    const newFiles = [];
    
    if (!files[0]) {
      setUploadImgs("");
      setCompPropImgList([]);
      return console.log("no files to render ---->");
    }
    for (let i = 0; i < files.length; i++) {
      newFiles.push(files[i]);
    }
    let reader = new FileReader();
    reader.onload = (event) => {
      console.log("inside onload image ------->", event.target.result);

      setUploadImgs(newFiles);
      setCompPropImgList(newFiles);
    };
    reader.readAsDataURL(files[0]);
    console.log("new files array ------>", newFiles);
  }

  async function generatePPTHandler() {
    let myGenPPTObj = {
      file: {
        txt: textFileInp,
        file: uploadFiles,
        voice: voiceFileInp,
      },
      BGDetl: {
        txt: textBGDetail,
        file: uploadImgs,
        voice: voiceBGDetl,
      },
    };

    const variablesToCheck = [
      textFileInp,
      uploadFiles[0],
      voiceFileInp,
      textBGDetail,
      uploadImgs,
      voiceBGDetl,
    ];

    if (variablesToCheck.every((variable) => !variable)) {
      return; // If none of the variables hold a value, return early
    }

    setDisableAllInp(true);

    let formData = new FormData();

    // appending to from data.....

    console.log("getting topic file", myGenPPTObj?.file?.file);

    formData.append("topic_text", myGenPPTObj?.file?.txt || "");

    for (let i = 0; i < myGenPPTObj?.file?.file?.length; i++) {
      formData.append("files", myGenPPTObj?.file?.file[i]);
    }

    formData.append("topic_file", myGenPPTObj?.file?.file || "");
    formData.append("topic_voice_data", myGenPPTObj?.file?.voice || "");

    if(myGenPPTObj?.BGDetl?.file?.length){
      for (let i = 0; i < myGenPPTObj?.BGDetl?.file?.length; i++) {
        formData.append("background_image", myGenPPTObj?.BGDetl?.file[i] || "");
      }
    }else{
      formData.append("background_image", "");
    }

    formData.append("background_text", myGenPPTObj?.BGDetl?.txt || "");
    formData.append("background_voice", myGenPPTObj?.BGDetl?.voice || "");

    console.log("generate ppt with following details -------->", myGenPPTObj);
    setIsLoadingPPT(true);
    let response = await presentationMaker.requestGeneratePPt(formData);
    setDisableAllInp(false);
    setIsLoadingPPT(false);

    console.log("response gen ppt.1111111111111 ----->", response);

    if (response.success) {
      
      console.log(
        "inside success rsponse gen ppt.1111111111111 ----->",
        response
      );
      toast.success("PPT generate successfully !");
      updateDwnlFile(response?.payload?.ppt_file || "");
      
      presentationMaker.updateImgListToPrev(
        response?.payload?.ppt_sildes_images
      );
    } else {
      console.log("ppt generation failed !");
    }
  }

  return (
    <>
      <div className="presntPrevwCompMainCont">
        <div className="presntPrevwButtonContMain">
          <div
            className="backBtnMainWrpprPresntPrevw"
            onClick={() =>
              switchHandler({ swtchVal: 1, fileInpTxt: "", BGInpTxt: "" })
            } 
          >
            <div className="backBtnWrpprPrestPrevw">
              <BiArrowBack size={34} />
            </div>
            Back
          </div>
          <FileSyncButton
            src={"downloadFileIcon11.svg"}
            title={"Download Presentation"}
            handleClick={dwnldHandlerPPT}
          />
        </div>
        <div className="presntPrevwActvCont">
          <div className="presntPrevwSlideCont">
            {openMenuDelay ? (
              <div className="slideMenuContentWRpprPPTM">
                <InputPresntPrevwComp
                  nameStr="textForPresentPrevwPPT"
                  spanStr="eg: Best Practices for Remote Work"
                  labelStr="Tell Us About Topic"
                  prMssn={permission}
                  strm={stream}
                  textInp={textFileInp}
                  updateTextInp={(v) => setTextFileInp(v)}
                  updateVoiceInp={(v) => setVoiceFileInp(v)}
                  updateFiles={updateTxtFilesList}
                  disableAllInp={disableAllInp}
                  extTyp=".txt"
                  files={uploadFiles}
                />
                <InputPresntPrevwComp
                  nameStr="textForPresentPrevwBckgrnd"
                  spanStr="eg: Need royal background with clean white with some gradient"
                  labelStr="What kind of background you want ?"
                  prMssn={permission}
                  strm={stream}
                  textInp={textBGDetail}
                  updateTextInp={(v) => setTextBGDetail(v)}
                  updateVoiceInp={(v) => setVoiceBGDetl(v)}
                  updateFiles={updateImgFilesList}
                  disableAllInp={disableAllInp}
                  extTyp=".jpg,.jpeg"
                  files={compPropImgList}
                />
                <div className="btnWrpprGenPPT genBtnSecndPGPPTM">
                  {isLoadingPPT ? (
                    <div className="btnWrpprLoadingSpinnerPPTmaker btnWrpprLdnSpnrSecScrnPPTM">
                      <LoadingComponent
                        size={15}
                        styl={{
                          position: "relative",
                          right: "0px",
                          bottom: "0px",
                        }}
                        
                      />
                    </div>
                  ) : (
                    <FileSyncButton
                      
                      title={"Generate Presentation"}
                      stylObj={{
                        color: "#00AFEF",
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        background: "white",
                      }}
                      handleClick={() => {
                        console.log("generating ppt --------->");
                        generatePPTHandler();
                      }}
                    >
                      <WiStars size={24} style={{ marginRight: "10px" }} />
                    </FileSyncButton>
                  )}
                </div>
              </div>
            ) : (
              <div className="presntPrevwEmptyCont"></div>
            )}
            <div
              className={`switchBtnContPresntPrevw ${
                openMenu ? "openMenuPPTM" : ""
              }`}
            >
              <div className="menuIconWrpprPPTM" onClick={toggleSlideContiner}>
                <img
                  src={`/assests/img/${
                    openMenu ? "menu-closePPTM.svg" : "menu-openPPTM.svg"
                  }`}
                  alt="menu"
                />
                
              </div>
            </div>
          </div>
          
          <CarouselCompPPTM
            ImgList={presentationMaker?.imageListToPrev}
          />
        </div>
      </div>
    </>
  );
}

function InputPresntPrevwComp({
  spanStr = "",
  labelStr = "",
  prMssn,
  strm,
  updateTextInp,
  updateVoiceInp,
  textInp,
  updateFiles,
  disableAllInp = false,
  extTyp = ".txt",
  files = [],
}) {

  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioState, setAudioState] = useState("");

  const { presentationMaker } = useContext(MyContext);

  const fileInputTag = useRef(null);

  function handleChange(e) {
    let value = e.target.value;
    updateTextInp(value);
  }

  // ***************** MICRO PHONE ****************************

  function handleStartRecording() {
    if (disableAllInp) return;
    updateFiles([]);
    updateTextInp("");
    if (prMssn) {
      if (recordingStatus === "inactive") {
        startRecording();
      } else if (recordingStatus === "recording") {
        stopRecording();
      }
    } else {
      toast.info("The MediaRecorder API is not supported in your browser.");
    }
  }

  const startRecording = async () => {
    

    setRecordingStatus("recording");
    //create new Media recorder instance using the stream
    const media = new MediaRecorder(strm, { type: mimeType });
    //set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };

    // setting audio processing state to listening...

    setAudioState("Listening...");

    // setting audio processing state to listening...

    setAudioChunks(localAudioChunks);
  };

  // Start recording audio...........

  // Stop recording audio...........

  async function stopRecording() {
    setRecordingStatus("inactive");

    // setting audio processing state to processing...

    setAudioState("Processing...");

    // setting audio processing state to processing...

    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = async () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      //creates a playable URL from the blob file.
      const audioUrl = URL.createObjectURL(audioBlob);
      console.log(
        "recorded audio ---------->",
        audioUrl,
        audioBlob,
        audioChunks
      );

      // sending audio blob to server....

      const formData = new FormData();
      formData.append("topic_voice_data", audioBlob, "recorded_audio.wav");
      console.log("formated recorded audio ---------->", formData);

      updateVoiceInp(audioBlob);
      const resp_voiceToTextData = await presentationMaker.reqVoiceToText(
        formData
      );

      console.log(
        "response voiceToTextData audio ---------->",
        resp_voiceToTextData?.payload,
        resp_voiceToTextData
      );
      if (resp_voiceToTextData?.success) {
        console.log(
          "response voiceToText 1111 ------->",
          resp_voiceToTextData?.payload
        );
        updateTextInp(resp_voiceToTextData?.payload?.topic_text);
      }

      // setting audio processing state to '' ...

      setAudioState("");

      setAudioChunks([]);
    };
  }

  // Stop recording audio...........

  // ***************** MICRO PHONE ****************************


  // updating upload file list ..........

  async function updateUploadFileList(e) {
    let files = e.target.files;
    console.log("uploading files -------->", files);
    
    updateFiles(files);
  }

  // updating upload file list ..........

  // choosing files to upload ..........

  function chooseFilesToUpload() {
    console.log("clicking upload file btn......");
    if (disableAllInp) return;
    fileInputTag.current.click();
    
    updateTextInp("");
    updateVoiceInp(null);
  }

  // choosing files to upload ..........

  function removeFilesFromFileList(name) {
    if (disableAllInp) return;
    updateTextInp("");
    let newFL = [];
    console.log("getting name remove files ------>", name);
    
    console.log("updated FL remove files ------>", newFL);
    updateFiles(newFL);
  }

  return (
    <>
      <div className="presntPrewInpCompMainWrppr">
        <p className="presntPrevwInpLabel">{labelStr}</p>
        <div className="presntPrewInpWrppr">
          <textarea
            value={textInp}
            onChange={handleChange}
            className="presntPrevwTextArea"
            autoFocus // Automatically focus on the textarea
            placeholder={spanStr}
            disabled={disableAllInp}
          />
          <div className="FDWPPTMFileListWrppr">
            {Array.from(files)?.map((e, i) => {
              console.log("mapping files screen 2");
              return (
                <FilesPrevComp
                  label={e.name}
                  key={`FDWPPTMFileList${i}`}
                  closeHandler={removeFilesFromFileList}
                >
                  <FcDocument />
                </FilesPrevComp>
              );
            })}
          </div>

          <div
            className="presntPrevwInpUpldBtn"
            onClick={() => {
              console.log("upld file send screen");
              chooseFilesToUpload();
            }}
          >
            <img src="/assests/img/uploadBtnIcon.png" alt="upld" />
          </div>
          <input
            ref={fileInputTag}
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              updateUploadFileList(e);
            }}
            accept={extTyp}
            disabled={disableAllInp}
          />
          {audioState === "Processing..." ? (
            <div className="micLoadingWrapperPPTMScrn1Inpt secondScreenPPTMicBtn scndScrnLdng">
              <LoadingComponent
                size={15}
                styl={{ position: "relative", right: "0px", bottom: "0px" }}
              />
            </div>
          ) : (
            <div
              className={`microPhoneWrpprPptm ${
                prMssn
                  ? recordingStatus === "recording"
                    ? "greenVoiceBtn"
                    : ""
                  : "greyVoiceBtn"
              }  secondScreenPPTMicBtn`}
              onClick={handleStartRecording}
            >
              <BsFillMicFill size={"18px"} />
              <Wave_Anim
                switchVar={prMssn && recordingStatus === "recording"}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

// *********************************** PRESENTATION PREVIEW COMPONENTS ***********************************

// *********************************** SELECTED FILE/IMAGE PREVIEW COMPONENT ***********************************

function FilesPrevComp({ label, children, closeHandler }) {
  return (
    <>
      <div className="filesPrevMainWrppr">
        <div className="imgWrpprFPMW">{children}</div>
        <p className="nameContFPMW">{label}</p>

        <AiFillCloseCircle
          size={"18px"}
          onClick={() => {
            console.log("close btn clicked !");
            closeHandler(label);
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
    </>
  );
}

// *********************************** SELECTED FILE/IMAGE PREVIEW COMPONENT ***********************************

// *********************************** CAROUSEL PREVIEW COMPONENT ***********************************

function CarouselCompPPTM({ ImgList = [] }) {
  const [currentSlide, setCurrentSlide] = useState(ImgList[0]);

  useEffect(() => {
    // When ImgList is provided and not empty
    if (ImgList && ImgList.length > 0) {
      setCurrentSlide(ImgList[0]);
    }
  }, [ImgList]);

  return (
    <>
      <div className="presntPrevwFilePrevwCont">
        <div className="currentSlideWrpprCarCompPPTM" >
          <img
            src={currentSlide}
            alt="current_slide"
            className="currImgCarCompPPTM"
          />
        </div>
        <div className="imageListWrpprCarCompPPTM">
          {Array.from(ImgList).map((e, i) => { 
            
            return (
              <div
                className="imgElemWrpprCarCompPPTM"
                key={`imgEWCCPPTM${i}`}
                onClick={() => setCurrentSlide(e || "")}
              >
                <span className="ImgSpanCarCompPPTM">{i + 1}.</span>
                <img
                  src={e || ""}
                  alt={`slide${i + 1}`}
                  className="ImgElemCarCompPPTM"
                />
              </div>
            );
          })}
        </div>
        
      </div>
    </>
  );
}

// *********************************** CAROUSEL PREVIEW COMPONENT ***********************************
