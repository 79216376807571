import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import './loadingSpinner.css';

const StyledLoader = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const LoadingComponent = ({ size = 40, color = "#57A0D3", styl={} }) => {
  return (
    <div className="myloaderspinner" style={{...styl}} >
      <StyledLoader>
        <CircularProgress size={size} style={{ color }} />
      </StyledLoader>
    </div>
  );
};

export default LoadingComponent;
