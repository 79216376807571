import React, { useState, useEffect, useRef, useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { IoMdClose } from "react-icons/io";
import { IoIosMore } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import { createNotes, updateNotes, deleteNotes } from "../../actions/action";

import "./notes.css";

import { formatDateForNotes } from "../utility/functions/helperFunc";

import { MyContext } from "../../context/context";
import { toast } from "react-toastify";

const InitialContentNote = `
<h2>Title</h2>
<p><br></p>
<p>Write Your Message Here </p>
`;

const NoteComponent = ({ notesDetl,fetchNoteList=()=>{} }) => {
  const [content, setContent] = useState(
    notesDetl?.content_html || InitialContentNote
  );
  const [backgroundColor, setBackgroundColor] = useState(
    notesDetl?.bg_color || "#fff"
  );
  const [colorPaletteOpen, setColorPaletteOpen] = useState(false);
  const [closeModal, setCloseModal] = useState(true);
  const [ovrFlwQlEditor, setOvrFlwQlEditor] = useState(false);

  const notesContainer = useRef(null),
    colOptnModal = useRef(null),
    myQuillComp = useRef(null),
    readMoreIcon = useRef(null);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"], // ['bold', 'italic', 'underline', 'strike', 'blockquote']
      // [{ header: [1, 2, false] }]
      // [{ color: [] }, { background: [] }]
      // [{ font: [] }]
      // ['link', 'image', 'video']
      // ['clean']
    ],
  };

  const { userDetails } = useContext(MyContext);

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "color",
    "background",
    "font",
    "link",
    "image",
    "video",
  ];

  const colorOptions = ["#fff", "#ffcccb", "#ffd700", "#98fb98", "#add8e6"]; // Add your color options

  const toggleColorPalette = () => {
    setColorPaletteOpen(!colorPaletteOpen);
  };

  const handleColorChange = async (color) => {
    setBackgroundColor(color);
    toggleColorPalette();

    const userType = userDetails?.user_type || "";

    console.log("changing notes background ===>", content, backgroundColor);
    let data = {
      content_html: content || InitialContentNote,
      bg_color: color || "white",
      id: notesDetl?.id,
    };
    let resp = await updateNotes([{ ...data }], userType);

    if (resp?.success) {
      console.log("notes updated successfully...", resp?.data);
      fetchNoteList();
    }
  };

  function handleChangeContent(value) {
    // console.log("changing content ===========>", value);
    setContent(value);
  }

  async function handleSaveContentOnBlur() {
    const userType = userDetails?.user_type || "";

    console.log("saving content on blur ===>", content, backgroundColor);
    let data = {
      content_html: content || InitialContentNote,
      bg_color: backgroundColor || "white",
      id: notesDetl?.id,
    };
    let resp = await updateNotes([{ ...data }], userType);

    if (resp?.success) {
      console.log("notes updated successfully...", resp?.data);
      fetchNoteList();
    }else{
      toast.error("enable to update note")
    }
  }

  async function handleDeleteNotes(x) {
    const userType = userDetails?.user_type || "";
    console.log("delte following note ======>", x);
    let data = {
      note_ids: [x?.id],
    };
    let resp = await deleteNotes(data,userType);

    if (resp?.success) {
      console.log("successfully deleted note");
      fetchNoteList();
      toast.success("note deleted")
    }else{
      toast.error("enable to delete note");
    }
  }

  useEffect(() => {
    function checkClickOutside(e) {
      // let notes = notesContainer?.current?.contains(e?.target),
      //   colModal = colOptnModal?.current?.contains(e?.target);

      let myQuillEditor = myQuillComp?.current?.editingArea?.__quill?.root;

      // console.log("notes", notes);
      // console.log("color modal", colModal);
      // console.log("qlEditor", qlEditor);
      // console.log("myQuill", myQuillEditor);

      if (
        notesContainer?.current &&
        !notesContainer?.current?.contains(e?.target) &&
        !colOptnModal?.current?.contains(e?.target) &&
        !readMoreIcon?.current?.contains(e?.target)
      ) {
        // ********* closing quill window ********

        myQuillEditor?.classList?.remove("show-ql-editor");

        // ********* closing quill window ********

        setCloseModal(true);
      } else {
        myQuillEditor?.classList?.add("show-ql-editor");
        setCloseModal(false);
      }
    }

    document.addEventListener("click", checkClickOutside);

    return () => {
      document?.removeEventListener("click", checkClickOutside);
    };
  }, []);

  useEffect(() => {
    function checkOverflowQlEditor() {
      let myQuillEditor = myQuillComp?.current?.editingArea?.__quill?.root,
        c = myQuillEditor?.clientHeight,
        s = myQuillEditor?.scrollHeight;
      // console.log(
      //   "myQuill client height & scroll height ===>",
      //   myQuillEditor?.clientHeight,
      //   myQuillEditor?.scrollHeight
      // );
      if (0 > c - s) {
        setOvrFlwQlEditor(true);
      } else {
        setOvrFlwQlEditor(false);
      }
    }
    checkOverflowQlEditor();
  }, [closeModal]);

  return (
    <>
      <div className="notesWrapper">
        <IoMdClose
          className="closeIconNotes"
          onClick={() => {
            // console.log("closing notes ===>");
            handleDeleteNotes(notesDetl);
            setCloseModal(true);
          }}
        />
        <div
          className={`note-container ${closeModal ? "close-modal-active" : ""}`}
          style={{ backgroundColor }}
          tabIndex="0"
          ref={notesContainer}
          onClick={() => {
            // console.log("click note cont");
            setCloseModal(false);
          }}
        >
          <ReactQuill
            ref={myQuillComp}
            theme="snow"
            value={content}
            onChange={(text) => handleChangeContent(text)}
            onBlur={() => {
              handleSaveContentOnBlur();
              // console.log("saving the content : ========> ", content);
            }}
            modules={modules}
            formats={formats}
            placeholder="Start typing..."
            style={{
              minHeight: "fit-content",
              borderRadius: "8px",
              // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              border: "none",
              paddingBottom: "58px",
            }}
          />
          <div
            className={`engTutNotesReadMoreIcon ${
              ovrFlwQlEditor && closeModal ? "showReadMore" : ""
            }`}
            ref={readMoreIcon}
          >
            <IoIosMore size={24} />
          </div>

          <div className="color-options">
            <button onClick={toggleColorPalette} className="color-palette-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M8.47578 2.47559L7.42549 3.52441L8.77461 4.875L3.60078 10.0503C2.70078 10.9503 2.70078 12.3744 3.60078 13.2744L3.67549 13.3506L8.39961 18.0747C9.29961 18.9747 10.7252 18.9747 11.6252 18.0747L17.3249 12.375L17.8508 11.8506L10.5749 4.57471L9.97578 3.97559L9.8249 3.82471L8.47578 2.47559ZM9.8249 5.92529L15.7502 11.8506L14.1008 13.5H5.92402L4.64961 12.2256C4.34961 11.9256 4.34961 11.4006 4.64961 11.1006L9.8249 5.92529ZM18.7502 14.4756L18.1496 15.375C18.1496 15.375 17.7749 15.9744 17.3249 16.6494C17.0999 17.0244 16.9505 17.3247 16.8005 17.6997C16.6505 18.0747 16.5002 18.3 16.5002 18.75C16.5002 19.95 17.5502 21 18.7502 21C19.9502 21 21.0002 19.95 21.0002 18.75C21.0002 18.3 20.8499 18 20.6999 17.625C20.5499 17.25 20.3255 16.8747 20.1755 16.5747C19.8005 15.8997 19.3508 15.3003 19.3508 15.3003L18.7502 14.4756Z"
                  fill="#3F4041"
                />
              </svg>
            </button>

            <div
              className={`color-palette-modal ${
                colorPaletteOpen ? "" : "hideModal"
              }`}
              ref={colOptnModal}
            >
              {colorOptions.map((color) => (
                <div
                  key={color}
                  className="color-option"
                  style={{ backgroundColor: color }}
                  onClick={() => handleColorChange(color)}
                />
              ))}
            </div>
          </div>
          {/* <div className="delete-btn-notes">
          <RiDeleteBinLine/>
          </div> */}
          <span className="lastModificationStrNotes">
            Last Modified: {formatDateForNotes(notesDetl?.created_at) || ""}
          </span>
        </div>
      </div>
    </>
  );
};

export default NoteComponent;

// export default NoteComponent;
