import { useState, useContext, useEffect } from "react";
import "./adminAddUser.css";
import Header from "../../header/header";
import { HeadingContAdmin, HeadingCont } from "../../headingCont/headingCont";
import {
  FormInp,
  FormSelectInp,
  CustomSelProdLabel,
} from "../adminSignup/adminSignUp";
import { MyContext } from "../../../context/context";
import {
  createUserByAdmin,
  fetchAllProducts,
  deleteMultiProducts,
  editMultiProducts,
} from "../../../actions/action";
import { Checkbox } from "@mui/material";
import { RiEdit2Fill } from "react-icons/ri";
import { MdDeleteForever } from "react-icons/md";
import {
  validateEmail,
  validateMobile,
} from "../../utility/functions/helperFunc";
import { FileSyncButton } from "../../utility/button/button";
import { toast } from "react-toastify";
import LoadingComponent from "../../loadingSpinner/loadingSpinner";

function AdminAddUser() {
  const [compStatus, setCompStatus] = useState("formComp");
  const [editableUsrData, setEditableUsrData] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const { admin } = useContext(MyContext);

  function handleCompSwitch() {
    if (compStatus === "formComp") {
      setEditableUsrData(null);
      setIsEditable(false);
      setCompStatus("dataTableComp");
    } else {
      setCompStatus("formComp");
    }
  }

  function handleDataForEditUserDetl(d) {
    console.log("got edit user deatails in parent----->", d);
    const data = {
      id: d?.id || "",
      email: d?.email || "",
      mobile: d?.mobile || "",
      first_name: d?.first_name || "",
      last_name: d?.last_name || "",
      products: d?.product_access || [],
    };
    setEditableUsrData({ ...data });
    setIsEditable(true);
    setCompStatus("formComp");
  }

  function handleIsEditable(v) {
    setIsEditable(v);
  }

  useEffect(() => {
    admin.getCrtdUser();
  }, []);
  return (
    <>
      <Header userStatus={"admin"} />
      <HeadingCont />
      <div className="addAdminUserWrpprCont">
        <div className="addUserForAdminBtnWrpprMain">
          <button
            className="addUserForAdminBtn"
            style={{ marginBottom: "25px" }}
            onClick={handleCompSwitch}
          >
            {compStatus === "formComp"
              ? "View All Users"
              : "Add Users in Database"}
          </button>
        </div>
        {compStatus === "formComp" ? (
          <AddUserFormAdmin
            initialDataFeed={editableUsrData}
            isEdit={isEditable}
            handleIsEdit={(v) => handleIsEditable(v)}
          />
        ) : (
          <AddedUserTable
            handlEditUsrDetl={(v) => handleDataForEditUserDetl(v)}
          />
        )}
      </div>
    </>
  );
}

export default AdminAddUser;

function AddUserFormAdmin({ initialDataFeed, isEdit, handleIsEdit }) {
  const [addUserVal, setAddUserVal] = useState({
    email: initialDataFeed?.email || "",
    mobile: initialDataFeed?.mobile || "",
    first_name: initialDataFeed?.first_name || "",
    last_name: initialDataFeed?.last_name || "",
  });
  const [errObj, setErrObj] = useState(0);
  const { admin } = useContext(MyContext);
  const [allProdArr, setAllProdArr] = useState([]);
  const [selProdArr, setSelProdArr] = useState([]);
  const [isload, setIsLoad] = useState(false);


  console.log("is edit------------------------->",isEdit)

  function handleFormValues(e) {
    let name = e.target.name,
      value = e.target.value || "";

    setAddUserVal({ ...addUserVal, [`${name}`]: value });
  }
  async function handleSubmit(e) {
    e.preventDefault();
    console.log("sending form data...", addUserVal);
    console.log("selected products for access ------->", selProdArr);

    // validation...

    let errorObj = {};
    if (selProdArr?.length === 0) {
      errorObj.products = "Please select alteast one product";
    }
    if (validateEmail(addUserVal["email"])) {
      errorObj.email = validateEmail(addUserVal["email"]);
    }
    if (validateMobile(addUserVal["mobile"])) {
      errorObj.mobileNumber = validateMobile(addUserVal["mobile"]);
    }
    if (!addUserVal["first_name"])
      errorObj.firstName = "First name is required.";
    // if (!addUserVal["last_name"]) errorObj.lastName = "Last name is required."; // temporarily omitted ...
    setErrObj({ ...errorObj });
    console.log("getting error obj", errorObj);
    if (Object.keys(errorObj).length !== 0) return;
    // validation...

    let newArr = selProdArr.map((e) => e.id);
    console.log("new arr sel products....");
    let formDataToSend = { ...addUserVal, ["products"]: [...newArr] };

    let resp;
    setIsLoad(true);
    if (isEdit) {
      resp = await editMultiProducts([
        { id: initialDataFeed?.id || "", ...formDataToSend },
      ]);
    } else {
      resp = await createUserByAdmin(formDataToSend);
    }
    setIsLoad(false);
    if (resp.success) {
      isEdit && toast.success("user details updated");
      handleIsEdit(false);
      console.log("inside success scope...");
      setAddUserVal({ first_name: "", last_name: "", email: "", mobile: "" });
      setSelProdArr([]);
      admin.getCrtdUser();
    }
    console.log("adding user by admin response", resp);
  }

  async function getAllProducts() {
    let resp = await fetchAllProducts();
    console.log("getting resp from get all product", resp);

    if (resp?.success) {
      setAllProdArr([...resp?.payload?.data]);
    }
  }

  function handleSelProdList(v) {
    setSelProdArr([...v]);
  }

  useEffect(() => {
    getAllProducts();
    console.log("running useEffect...initial value", initialDataFeed);
    if (initialDataFeed?.products?.length > 0) {
      setSelProdArr([...initialDataFeed?.products]);
    }
  }, []);
  return (
    <>
      <form action="" className="addUserForAdminForm">
        <div className="aUFPsswrdFieldWrppr">
          <div
            className="aUFPsswrdFieldSubWrppr"
            style={{ marginRight: "10px" }}
          >
            <FormInp
              title={"First Name"}
              nameStr={"first_name"}
              error={errObj?.firstName}
              handlChange={(e) => {
                handleFormValues(e);
              }}
              placeHldr={"Enter First Name"}
              value={addUserVal["first_name"]}
            />
          </div>
          <div className="aUFPsswrdFieldSubWrppr">
            <FormInp
              title={"Last Name"}
              nameStr={"last_name"}
              error={errObj?.lastName}
              handlChange={(e) => {
                handleFormValues(e);
              }}
              placeHldr={"Enter Last Name"}
              value={addUserVal["last_name"]}
            />
          </div>
        </div>
        <FormInp
          title={"Email address"}
          nameStr={"email"}
          error={errObj?.email}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your email address"}
          value={addUserVal["email"]}
          isDisable={isEdit}
        />
        <FormInp
          title={"Mobile Number"}
          nameStr={"mobile"}
          error={errObj?.mobileNumber}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your mobile number"}
          value={addUserVal["mobile"]}
        />
        <FormSelectInp
          title={"Select Product Access"}
          placeHldr={"Select Product"}
          optionList={allProdArr}
          handlChange={(v) => {
            handleSelProdList(v);
          }}
          error={errObj?.products}
          selProdList={selProdArr}
        />
        <button className="addUserForAdminBtn" onClick={handleSubmit}>
          {isload ? (
            <div style={{ position: "relative", top: "0px" }}>
              <LoadingComponent
                styl={{ bottom: "0px", right: "0px", position: "relative" }}
                size={20}
                color="white"
              />
            </div>
          ) : (
            "Add User"
          )}
        </button>
      </form>
    </>
  );
}

function AddedUserTable({ handlEditUsrDetl }) {
  const { admin } = useContext(MyContext);

  const [deleteNotif, setDeleteNotif] = useState({ display: false, data: [] });

  const [selectedUserList, setSelectedUserList] = useState([]);

  function handleSelectAllUsers() {
    // console.log("select all users ---->", admin?.crtdUsers);
    if (admin?.crtdUsers?.length === selectedUserList?.length) {
      setSelectedUserList([]);
    } else {
      let newArr = admin?.crtdUsers?.map((e) => e.id);
      setSelectedUserList([...newArr]);
    }
  }

  function handleSingleSelectUser(e) {
    if (selectedUserList?.includes(e?.id)) {
      let newArr = selectedUserList?.filter((x) => x !== e?.id);
      setSelectedUserList([...newArr]);
    } else {
      setSelectedUserList([...selectedUserList, e?.id]);
    }
  }

  async function handleMultiDelUser() {
    if (selectedUserList?.length === 0) {
      toast.info("Please select atleast one entry");
      return;
    }
    setDeleteNotif({ display: true, data: [...selectedUserList] });
  }

  async function handleSingleDelete(id = "") {
    if (!id) {
      toast.error("problem fetching id ");
      return;
    }
    setDeleteNotif({ display: true, data: [id] });
  }

  async function handleDeleteUser(arr = []) {
    let resp = await deleteMultiProducts([...arr]);

    console.log("response delete user profile", resp);

    if (resp.success) {
      console.log("success delete user profiles");
      toast.success("user deleted successfully");
      admin.getCrtdUser();
      setDeleteNotif({ display: false, data: [] });
    }
  }
  return (
    <>
      {deleteNotif?.display && (
        <DeleteNotifComp
          handleDelete={() => handleDeleteUser(deleteNotif?.data)}
          handleCancel={() => {
            setDeleteNotif({ display: false, data: [] });
          }}
        />
      )}
      <table className="myTableAUT">
        <thead className="mtHeadAUT">
          <tr className="mtHeadRowAUT">
            <td className="mthRulesAUT checkBoxContUserTable">
              <Checkbox
                checked={admin?.crtdUsers?.length === selectedUserList?.length}
                onChange={handleSelectAllUsers}
                sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
              />
            </td>
            <td className="mthRulesAUT">Name</td>
            <td className="mthEditSaveAUT">E-Mail</td>
            <td className="mthDeleteAUT mobileNumContUserTable" style={{ paddingRight: "16px" }}>
              Mobile Number
            </td>
            <td className="mthEditSaveAUT">Product Access</td>
            <td className="mthEditSaveAUT funcContUserTable">Edit</td>
            <td className="mthEditSaveAUT funcContDelUserTable">
              Delete{" | "}
              <MdDeleteForever
                size={21}
                color={"white"}
                style={{ cursor: "pointer", position: "relative", top: "4px" }}
                onClick={() => {
                  handleMultiDelUser();
                }}
              />
            </td>
            {/* deleteMultiProducts  */}
          </tr>
        </thead>
        {/* <div className="tableBodyWrpprAUT">
        </div> */}
          <tbody className="mtBodyAUT">
            {admin?.crtdUsers?.map((e, i) => {
              if (i === 0) console.log("promptData element:", e);
              /*
              date_joined: "2023-06-16T05:53:38.257045Z"
              email: "fff@marktine.com"
              first_name: ""
              id: 16
              is_active: true
              is_staff: false
              last_login: null
              last_name: ""
              mobile: "7788778474"
              user_type: 2
              */
              return (
                <tr key={`userDetailsTableUDT${e.id}`} className="mtRowAUT">
                  <td className="checkBoxContUserTable">
                    <Checkbox
                      checked={selectedUserList?.includes(e.id)}
                      onChange={() => {
                        handleSingleSelectUser(e);
                      }}
                      sx={{
                        color: "#00AFEF",
                        "&.Mui-checked": { color: "#00AFEF" },
                      }}
                    />
                  </td>
                  {/* <td className="mthCheckBoxRuleNoAUT">{e.id}</td> */}
                  <td
                    className="mthCheckBoxRuleNoAUT"
                    onInput={(e) => {
                      console.log(
                        "changing the content...",
                        e.target.textContent
                      );
                    }}
                  >
                    {e.first_name + " " + e.last_name}
                  </td>
                  <td className="mthCheckBoxRuleNoAUT">{e.email}</td>
                  <td className="mthCheckBoxRuleNoAUT mobileNumContUserTable">{e.mobile}</td>
                  <td className="mthCheckBoxRuleNoAUT">
                    <div className="custSelectedProdLableListWrpprUserTable">
                      {e?.product_access?.map((x) => {
                        return (
                          <>
                            <CustomSelProdLabel
                              title={x?.name || ""}
                              onlyLabel={true}
                              key={"myLableUserTableProdAcc" + x?.id}
                            />
                          </>
                        );
                      })}
                    </div>
                  </td>
                  <td className="mthCheckBoxRuleNoAUT funcContUserTable">
                    <RiEdit2Fill
                      size={18}
                      color={"#00AFEF"}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handlEditUsrDetl(e);
                      }}
                    />
                  </td>
                  <td className="mthCheckBoxRuleNoAUT funcContDelUserTable">
                    <MdDeleteForever
                      size={21}
                      color={"red"}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleSingleDelete(e?.id);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
      </table>
    </>
  );
}

function DeleteNotifComp({ handleCancel, handleDelete }) {
  const fontFamily = {
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "11.826px",
    fontStyle: "normal",
    fontWeight: "500",
  };

  const [load, setLoad] = useState(false);
  async function handleDeleteClick() {
    setLoad(true);
    await handleDelete();
    setLoad(false);
  }
  return (
    <>
      <div className="userDetailsBlockScreen">
        <div className="deleteNotifUserDetails">
          <h3 className="deleteNotifHeadUsrDetl">Delete user</h3>
          <div className="deleteNotifOtherWrpprUsrDetl">
            {load ? (
              <div style={{ position: "relative", top: "0px" }}>
                <LoadingComponent
                  styl={{ bottom: "0px", right: "0px", position: "relative" }}
                />
              </div>
            ) : (
              <>
                <h2 className="deleteNotifOtherHeadUsrDetl">Are you sure?</h2>
                <p className="deleteNotifOtherParaUsrDetl">
                  You want to Delete this user
                </p>
                <div className="deleteNotifOtherBtnWrpprUsrDetl">
                  <FileSyncButton
                    title={"NO"}
                    stylObj={{
                      backgroundColor: "white",
                      border:
                        "0.739px solid var(--border-and-light-bg-color, #DDD)",
                      ...fontFamily,
                    }}
                    handleClick={handleCancel}
                  />
                  <FileSyncButton
                    title={"YES"}
                    stylObj={{
                      backgroundColor: "#FA5774",
                      marginLeft: "8px",
                      ...fontFamily,
                      color: "white",
                    }}
                    handleClick={handleDeleteClick}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
