import { useState, useContext } from "react";
import "./adminPanel.css";
import { FileSyncButton, AdminPanelBtn } from "../utility/button/button.js";
import DataTable from "../dataGrid/dataGrid";
import { MyContext } from "../../context/context";

function AdminPanel() {
  const { admin } = useContext(MyContext);
  console.log("context admin", admin);

  function handleDwnldRules(){
    let text='';

    console.log("admin prompt values...",admin.promptData);
    // return
    admin.promptData.forEach((e,i)=>{

      text+= `Rule ${i+1}:  ${e.content}.\n`;
      // console.log("creating text file......",text)
    });
    console.log("whole text string",text);
    // return
    const element = document.createElement('a');
    const file = new Blob([text], { type: 'text/plain' });
  
    element.href = URL.createObjectURL(file);
    element.download = 'AllRulesFile.txt';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  return (
    <div className="adminPanelMainCont">
      <DataTable />
      <div className="dwnldBtnWrppr">
        <FileSyncButton
          title={"Download Defined Rules"}
          src={"downloadBtnIcon.png"}
          bkg={"makeGreenBtn"}
          handleClick={() => {
            console.log("initiating download...");
            handleDwnldRules();
          }}
        />
      </div>
    </div>
  );
}

export default AdminPanel;
