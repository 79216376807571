import "./recordingComp.css";

import { useRef, useEffect, useState, useContext } from "react";
import { IoIosMic } from "react-icons/io";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";
import {
  voiceToTextConvtrEngTut,
  sendMessageEngTut,
} from "../../../actions/action";
import LoadingComponent from "../../loadingSpinner/loadingSpinner";
import Wave_Anim from "../../utility/wave_animation/wave_anim";
import {
  playAudioOnce,
  waitingFunction,
} from "../../utility/functions/helperFunc";
import { toast } from "react-toastify";
import { MyContext } from "../../../context/context";
import { BsFillMicFill } from "react-icons/bs";
import { FileSyncButton } from "../../utility/button/button";

import SiriRecMicTestComp from "../../waveSurferDemo/siri"; // temp for testing onyl...
import { SpeechToTextComponentMicroSoftAzure } from "../../waveSurferDemo/voiceToText"; // temp for testing only...
// import { MyContext } from "../../../context/context";

function RecordingCompEngTut({ handleUpdateUsrConv }) {
  const containerRef = useRef(null);
  // const recordingsRef = useRef(null);
  const wavesurferRef = useRef(null);
  const recordButtonRef = useRef(null);

  const { userDetails } = useContext(MyContext);

  const [loading, SetLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [disblRecBtn, setDisblRecBtn] = useState(false);

  // const { engTutor } = useContext(MyContext);

  useEffect(() => {
    // Create an instance of WaveSurfer
    console.log("wavesurferRef.current value ------>", wavesurferRef?.current);
    if (wavesurferRef.current) return;
    wavesurferRef.current = WaveSurfer.create({
      container: containerRef.current,
      waveColor: "white",
      progressColor: "white",
    });

    // Initialize the Record plugin
    const record = wavesurferRef.current.registerPlugin(RecordPlugin.create());

    // Handle the record-end event
    record.on("record-end", async (blob) => {
      const recordedUrl = URL.createObjectURL(blob); // recorder audio url to use

      console.log("recorded audio --------->", recordedUrl);

      let dataVoiceToText = new FormData();

      dataVoiceToText.append("topic_voice_data", blob, "recorded_audio.wav");

      SetLoading(true);
      const userType = userDetails?.user_type || "";
      let resVoiceToText = await voiceToTextConvtrEngTut(
        dataVoiceToText,
        userType
      );
      console.log("response of recorded audio --------->", resVoiceToText);

      if (resVoiceToText?.success) {
        let msg = resVoiceToText?.payload?.topic_text;
        let respSendMsgEngTut = await sendMessageEngTut(
          { message: msg },
          userType
        );
        console.log("response send message eng tutor---->", respSendMsgEngTut);
        if (respSendMsgEngTut?.success) {
          let replyMsg = respSendMsgEngTut?.payload?.data?.reply;
          let date = respSendMsgEngTut?.payload?.data?.created_at;
          let voiceFileUrl =
            respSendMsgEngTut?.payload?.data?.voice_file_path || "";

          if (!voiceFileUrl) {
            toast.error("error processing audio url");
          }

          console.log(
            "extracting audio from server response---->",
            voiceFileUrl
          );

          // date = formatChatDate(date);
          let convObject = {
            message: msg,
            reply: replyMsg,
            created_at: date,
            audio: voiceFileUrl,
          };

          handleUpdateUsrConv(convObject);

          let respPlayAudio = await playAudio(convObject?.audio);

          console.log("resp play audio function...", respPlayAudio);
          // if(respPlayAudio){ // use this statement to stop the playing audio
          //   respPlayAudio.stopAudio();
          // }
        }
      }

      SetLoading(false);

      // Create wavesurfer from the recorded audio
      // const recordedWaveSurfer = WaveSurfer.create({
      //   container: recordingsRef.current,
      //   waveColor: "rgb(200, 100, 0)",
      //   progressColor: "rgb(100, 50, 0)",
      //   url: recordedUrl,
      // });

      // Play button
      //   const button = document.createElement("button");
      //   button.textContent = "Play";
      //   button.onclick = () => recordedWaveSurfer.playPause();
      //   recordedWaveSurfer.on("pause", () => (button.textContent = "Play"));
      //   recordedWaveSurfer.on("play", () => (button.textContent = "Pause"));
      //   recordingsRef.current.appendChild(button);

      // Download link
      //   const link = document.createElement("a");
      //   Object.assign(link, {
      //     href: recordedUrl, // recording to be used
      //     download:
      //       "recording." + blob.type.split(";")[0].split("/")[1] || "webm",
      //     textContent: "Download recording",
      //   });
      //   recordingsRef.current.appendChild(link);
    });

    // Record button...
    const recButton = recordButtonRef.current;

    recButton.onclick = () => {
      console.log("recording button clicked...");
      if (record.isRecording()) {
        record.stopRecording();
        setIsRecording(false);
        // recButton.textContent = "Record";
        return;
      }

      recButton.disabled = true;

      record.startRecording().then(() => {
        // recButton.textContent = "Stop";
        recButton.disabled = false;
        setIsRecording(true);
      });
    };
  }, []);

  //   **************** Playing previously recorder audio ****************

  // function createWaveSurferToPlayRecAudio(audioUrl = "") {
  //   if (audioUrl === "") return; // early return, no audio
  //   const recordedWaveSurfer = WaveSurfer.create({
  //     container: recordingsRef.current,
  //     waveColor: "rgb(200, 100, 0)",
  //     progressColor: "rgb(100, 50, 0)",
  //     url: audioUrl,
  //   });

  //   recordedWaveSurfer.on("ready",()=>{
  //     if (engTutor.playPauseRecAudio === "play") {
  //       recordedWaveSurfer.play();
  //     }
  //   });

  //   if (engTutor.playPauseRecAudio === "pause") {
  //     recordedWaveSurfer.pause();
  //   }

  // }

  // useEffect(() => {
  //   createWaveSurferToPlayRecAudio(engTutor.audioToPlay);
  // }, [engTutor.audioToPlay]);

  //   **************** Playing previously recorder audio ****************

  async function playAudio(audioFilePath = "") {
    try {
      // Disable the record button
      setDisblRecBtn(() => {
        console.log("loading and disblRecBtn", loading || true);
        return true;
      });
      console.log("inside play audio.......", audioFilePath);
      const respPlayAudioOnce = await playAudioOnce(audioFilePath);
      console.log("play audio once response.......", respPlayAudioOnce);
      if (!respPlayAudioOnce.status) return;

      // { stopAudio, audioDuration }

      // Enable the record button after audioDuration milliseconds
      setTimeout(() => {
        setDisblRecBtn(false);
      }, respPlayAudioOnce?.audioDuration || 0);

      // For example, stop the audio after 3 seconds
      // setTimeout(() => {
      //   stopAudio();
      // }, 3000); // Adjust the duration as needed
    } catch (error) {
      console.log("error occured while playing the audio----->", error);
      setDisblRecBtn(false);
    }
  }

  return (
    <>
      {/* <div
        ref={recordingsRef}
        style={{ margin: "1rem 0", display: "none" }}
      ></div> */}
      <div className="footerMicAndWaveWrpprEngTut">
        <div
          ref={containerRef}
          style={{
            position: "relative",
            bottom: "-58px",
            background: "transparent",
            width: "50vw",
            left: "25%",
          }}
        ></div>
        <footer className="footerMicCompEngTutor">
          <button
            className="footerMicBtnEngTutor"
            ref={recordButtonRef}
            disabled={loading || disblRecBtn}
          >
            {loading ? (
              <div className="loadingSpnrWrpprRecComp">
                <LoadingComponent
                  styl={{ bottom: "0px", right: "0px", position: "relative" }}
                  size={"24px"}
                />
              </div>
            ) : (
              <>
                <IoIosMic />
                <Wave_Anim switchVar={isRecording} size={5} />
              </>
            )}
          </button>
        </footer>
      </div>
    </>
  );
}

const mimeType = "audio/webm";

export function InputRecCompEngTut({
  handleUpdateUsrConv,
  disableAllInputs = false,
  disableInpList = [],
}) {
  const [inpVal, setInpVal] = useState("");

  const [permission, setPermission] = useState(false);
  const [stream, setStream] = useState(null);

  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioState, setAudioState] = useState("");
  const [disblRecBtn, setDisblRecBtn] = useState(false);
  const [disblInpComp, setDisblInpComp] = useState(false);

  const [isRecMicActive, setIsRecMicActive] = useState(false);

  const { userDetails, engTutor } = useContext(MyContext);

  function handleChange(e) {
    let val = e.target.value;

    setInpVal(val);
    console.log("input text val eng tut: ---->", val);
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();

      console.log("sending input text 11111111111------------>", inpVal);
      if (inpVal) {
        sendInputText();
      } else {
        toast.info("some Input text needed");
      }
    }
  }

  function handleStartRecording() {
    if (permission) {
      if (recordingStatus === "inactive") {
        startRecording();
      } else if (recordingStatus === "recording") {
        stopRecording();
      }
    } else {
      toast.info("The MediaRecorder API is not supported in your browser.");
    }
  }

  // getting permission to record audio...........

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        // toast.info("checking mic, please wait ...");
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        engTutor?.handleRecPermission(true);
        // console.log("Stream Data -------> ", streamData);
        setStream(streamData);
        engTutor?.handleMediaStream(true);
        // toast.success("Mic ready to use !");
        return 1;
      } catch (err) {
        engTutor?.handleRecPermission(false);
        engTutor?.handleMediaStream(false);
        toast.error(err.message);
        return 0;
      }
    } else {
      engTutor?.handleRecPermission(false);
      engTutor?.handleMediaStream(false);
      toast.info("The MediaRecorder API is not supported in your browser.");
      return 0;
    }
  };

  // getting permission to record audio...........

  // checking permission and stream and handlingSession ...

  async function checkEssentialsForMic() {
    //   let isUserConvEmpty = usrConv[0] ? false : true,
    //   sessionState = engTutor?.isStartSessEngTut;

    // console.log("session state toggleChatSession ====>", sessionState);

    // engTutor?.toggleSessionEngTut(!sessionState, isUserConvEmpty);

    if (!permission) {
      toast.info("recording permission not allowed");
      engTutor?.handleIsStartSession(false);
      return 0;
    }

    if (!stream) {
      toast.info("checking mic ...");
      let resp = await getMicrophonePermission();

      if (resp) {
        toast.success("mic ready to use");
        engTutor?.handleIsStartSession(true);
        return 1;
      } else {
        toast.error("enable to activate mic");
        engTutor?.handleIsStartSession(false);
        return 0;
      }
    }
  }

  // checking permission and stream and handlingSession ...

  // Start recording audio...........

  const startRecording = async () => {
    setRecordingStatus("recording");
    let isMicReady = await checkEssentialsForMic();

    if (!isMicReady) {
      return;
    }
    //create new Media recorder instance using the stream
    const media = new MediaRecorder(stream, { type: mimeType });
    //set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };

    // setting audio processing state to listening...

    setAudioState("Listening...");

    // setting audio processing state to listening...

    setAudioChunks(localAudioChunks);
  };

  // Start recording audio...........

  // Stop recording audio...........

  async function stopRecording() {
    return new Promise((resolve, reject) => {
      setRecordingStatus("inactive");

      // setting audio processing state to processing...

      setAudioState("Processing...");
      setDisblInpComp(true);

      // setting audio processing state to processing...

      //stops the recording instance
      mediaRecorder?.current?.stop();
      if(!mediaRecorder?.current)return;
      mediaRecorder.current.onstop = async () => {
        //creates a blob file from the audiochunks data
        const audioBlob = new Blob(audioChunks, { type: mimeType });
        //creates a playable URL from the blob file.
        const audioUrl = URL.createObjectURL(audioBlob);
        console.log(
          "recorded audio ---------->",
          audioUrl,
          audioBlob,
          audioChunks
        );

        // sending audio blob to server....

        let dataVoiceToText = new FormData();

        dataVoiceToText.append(
          "topic_voice_data",
          audioBlob,
          "recorded_audio.wav"
        );
        console.log("formated recorded audio ---------->", dataVoiceToText);
        const userType = userDetails?.user_type || "";

        let resVoiceToText = await voiceToTextConvtrEngTut(
          dataVoiceToText,
          userType
        );
        console.log("response of recorded audio --------->", resVoiceToText);

        // setting audio processing state to '' ...

        // setting audio processing state to '' ...

        // sending audio blob to server....
        if (resVoiceToText?.success) {
          let msg = resVoiceToText?.payload?.topic_text;
          let respSendMsgEngTut = await sendMessageEngTut(
            { message: msg },
            userType
          );
          console.log(
            "response send message eng tutor---->",
            respSendMsgEngTut
          );
          if (respSendMsgEngTut?.success) {
            let replyMsg = respSendMsgEngTut?.payload?.data?.reply;
            let date = respSendMsgEngTut?.payload?.data?.created_at;
            let voiceFileUrl =
              respSendMsgEngTut?.payload?.data?.voice_file_path || "";

            if (!voiceFileUrl) {
              toast.error("error processing audio url");
            }

            console.log(
              "extracting audio from server response---->",
              voiceFileUrl
            );

            let convObject = {
              message: msg,
              reply: replyMsg,
              created_at: date,
              audio: [
                voiceFileUrl?.english || "",
                voiceFileUrl?.other_lang || "",
              ],
            };

            handleUpdateUsrConv(convObject);
            console.log(
              "waiting ------- to --------- finish ------- playaudio"
            );

            let respPlayAudio = await playAudio(convObject?.audio[0]);

            console.log("resp play audio function...", respPlayAudio);

            if (respPlayAudio?.status) {
              let waitDuration = respPlayAudio?.audioDuration + 500;
              console.log("waiting for first audio to finish --->");
              await waitingFunction(waitDuration || 0); // wait for T second
              console.log("first audio finished --->");
            }

            let otherLangAudioResp = await playAudio(convObject?.audio[1]);

            if (otherLangAudioResp?.status) {
              console.log("waiting for second audio to finish --->");
              await waitingFunction(otherLangAudioResp?.audioDuration || 0);
              console.log("second audio finished --->");
              setAudioState("");
              setDisblInpComp(false);
              resolve(true);
              // }
              // let x = setTimeout(async () => {
              // clearTimeout(x);
              // }, totalAudioDuration);
            } else {
              setDisblInpComp(false);
              resolve(false);
              setAudioState("");
            }
          } else {
            setDisblInpComp(false);
            setAudioState("");
            console.log("resp false send message eng tut...");
            resolve(false);
          }
        } else {
          setDisblInpComp(false);
          setAudioState("");
          console.log("resp false send message eng tut...");
          resolve(false);
        }
        // setAudioState("");
        // setAudio(resp_sendVoiceData?.payload?.output_file_path);
        setAudioChunks([]);
      };
    });
  }

  // Stop recording audio...........

  async function playAudio(audioFilePath = "") {
    try {
      // Disable the record button
      setDisblRecBtn(() => {
        // console.log("loading and disblRecBtn", loading || true);
        return true;
      });
      console.log("inside play audio.......", audioFilePath);
      const respPlayAudioOnce = await playAudioOnce(audioFilePath);
      console.log("play audio once response.......", respPlayAudioOnce);
      // if (!respPlayAudioOnce.status) {
      //   setDisblRecBtn(false);
      //   return
      // };

      // Enable the record button after audioDuration milliseconds
      setTimeout(() => {
        setDisblRecBtn(false);
      }, respPlayAudioOnce?.audioDuration || 0);

      return respPlayAudioOnce;
    } catch (error) {
      console.log("error occured while playing the audio----->", error);
      setDisblRecBtn(false);
    }
  }

  async function sendInputText() {
    const userType = userDetails?.user_type || "";
    setDisblInpComp(true);
    let respSendMsgEngTut = await sendMessageEngTut(
      { message: inpVal },
      userType
    );
    console.log("response send message eng tutor---->", respSendMsgEngTut);
    setDisblInpComp(false);
    if (respSendMsgEngTut?.success) {
      let replyMsg = respSendMsgEngTut?.payload?.data?.reply;
      let date = respSendMsgEngTut?.payload?.data?.created_at;
      let voiceFileUrl =
        respSendMsgEngTut?.payload?.data?.voice_file_path || "";

      setInpVal("");

      if (!voiceFileUrl) {
        toast.error("error processing audio url");
      }

      console.log("extracting audio from server response---->", voiceFileUrl);

      let convObject = {
        message: inpVal,
        reply: replyMsg,
        created_at: date,
        audio: [voiceFileUrl?.english || "", voiceFileUrl?.other_lang || ""],
      };

      handleUpdateUsrConv(convObject);
      console.log("waiting ------- to --------- finish ------- playaudio");

      let respPlayAudio = await playAudio(convObject?.audio[0]);

      console.log("resp play audio function...", respPlayAudio);

      if (respPlayAudio?.status) {
        let waitDuration = respPlayAudio?.audioDuration + 500;
        console.log("waiting for first audio to finish --->");
        await waitingFunction(waitDuration || 0); // wait for T second
        console.log("first audio finished --->");
      }

      let otherLangAudioResp = await playAudio(convObject?.audio[1]);

      if (otherLangAudioResp?.status) {
        console.log("waiting for second audio to finish --->");
        await waitingFunction(otherLangAudioResp?.audioDuration || 0);
        console.log("second audio finished --->");
        setAudioState("");
        // resolve(true);
        // }
        // let x = setTimeout(async () => {
        // clearTimeout(x);
        // }, totalAudioDuration);
      } else {
        // resolve(false);
        setAudioState("");
      }
    } else {
      setAudioState("");
      toast.error("error sending text");
      console.log("resp false send message eng tut...");
      // resolve(false);
    }
  }

  useEffect(() => {
    getMicrophonePermission();
  }, []);

  useEffect(() => {
    let isStartSession = engTutor?.isStartSessEngTut,
      isEmptyChat = engTutor?.isEmptyEngUsrChat;

    async function sendStartSessionMsg() {
      console.log(
        "sending message to start session ====>",
        isStartSession,
        isEmptyChat
      );

      const userType = userDetails?.user_type || "";

      if (isStartSession && isEmptyChat) {
        let respSendMsgEngTut = await sendMessageEngTut(
          { message: "" },
          userType
        );
        console.log("response send message eng tutor---->", respSendMsgEngTut);
        if (respSendMsgEngTut?.success) {
          let replyMsg = respSendMsgEngTut?.payload?.data?.reply;
          let date = respSendMsgEngTut?.payload?.data?.created_at;
          let voiceFileUrl =
            respSendMsgEngTut?.payload?.data?.voice_file_path || "";

          if (!voiceFileUrl) {
            toast.error("error processing audio url");
          }

          console.log(
            "extracting audio from server response---->",
            voiceFileUrl
          );

          let convObject = {
            message: "",
            reply: replyMsg,
            created_at: date,
            audio: [
              voiceFileUrl?.english || "",
              voiceFileUrl?.other_lang || "",
            ],
          };

          handleUpdateUsrConv(convObject);
          console.log("waiting ------- to --------- finish ------- playaudio");

          let respPlayAudio = await playAudio(convObject?.audio[0]);

          console.log("resp play audio function...", respPlayAudio);

          if (respPlayAudio?.status) {
            let waitDuration = respPlayAudio?.audioDuration + 500;
            console.log("waiting for first audio to finish --->");
            await waitingFunction(waitDuration || 0); // wait for T second
            console.log("first audio finished --->");
          }

          let otherLangAudioResp = await playAudio(convObject?.audio[1]);

          if (otherLangAudioResp?.status) {
            console.log("waiting for second audio to finish --->");
            await waitingFunction(otherLangAudioResp?.audioDuration || 0);
            console.log("second audio finished --->");
            setAudioState("");
            setDisblInpComp(false);
            // resolve(true);
            // }
            // let x = setTimeout(async () => {
            // clearTimeout(x);
            // }, totalAudioDuration);
          } else {
            setDisblInpComp(false);
            // resolve(false);
            setAudioState("");
          }
        } else {
          setDisblInpComp(false);
          setAudioState("");
          console.log("resp false send message eng tut...");
          // resolve(false);
        }
      }
    }
    sendStartSessionMsg();
  }, [engTutor?.isStartSessEngTut]);

  return (
    <>
      <div className="mainWrpprEngTutNewInput">
        <div className="apInputWrppr newInputWrpprEngTut">
          <input
            type="text"
            value={inpVal}
            onChange={handleChange}
            className="apInput"
            placeholder="write here..."
            onKeyDown={(e) => {
              handleKeyDown(e);
            }}
            disabled={
              disblRecBtn ||
              audioState === "Processing..." ||
              disableAllInputs ||
              disblInpComp
            }
          />

          {audioState === "Processing..." ? (
            <div className="newInpLoadingWrpprEngTut">
              <LoadingComponent
                size={15}
                styl={{ position: "relative", right: "0px", bottom: "0px" }}
              />
            </div>
          ) : (
            <>
              {/* <button className="engtutSpeakrBtnNewInp">
                <div
                  className={`microPhoneWrpprPptm newInpMicroPhnWrpprEngTut ${
                    permission
                      ? recordingStatus === "recording"
                        ? "greenVoiceBtn"
                        : ""
                      : "greyVoiceBtn"
                  }`}
                  onClick={handleStartRecording}
                  disabled={disblRecBtn}
                >
                  <BsFillMicFill size={"18px"} />
                  <Wave_Anim
                    switchVar={permission && recordingStatus === "recording"}
                  />
                </div>
              </button> */}

              <SiriRecMicTestComp
                isDisable={disblRecBtn || disableAllInputs || disblInpComp}
                startListenToUser={startRecording}
                stopListenToUser={stopRecording}
                updateRecMicStatus={(v) => {
                  setIsRecMicActive(v);
                }}
                isRecMicActive={isRecMicActive}
              />
              {/* <SpeechToTextComponentMicroSoftAzure isDisable={disblRecBtn || disableAllInputs} startListenToUser={startRecording} stopListenToUser={stopRecording} updateRecMicStatus={(v)=>{setIsRecMicActive(v)}} /> */}
            </>
          )}
          <FileSyncButton
            stylObj={{ padding: "11px", "margin-left": "14px" }}
            disabled={disableAllInputs || disblInpComp}
            handleClick={() => {
              console.log("sending input text 111111----->", inpVal);
              if (inpVal) {
                sendInputText();
              } else {
                toast.info("some input text needed");
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M13.8438 1.31128L4.92154 4.27647C-1.07606 6.28291 -1.07606 9.5545 4.92154 11.5511L7.56958 12.4307L8.44897 15.0796C10.4449 21.0792 13.7253 21.0792 15.7212 15.0796L18.6953 6.1643C20.0193 2.1613 17.8455 -0.0230588 13.8438 1.31128ZM14.16 6.62885L10.4054 10.4045C10.2571 10.5528 10.0694 10.622 9.88167 10.622C9.69394 10.622 9.50621 10.5528 9.35799 10.4045C9.22018 10.265 9.14289 10.0768 9.14289 9.88067C9.14289 9.68454 9.22018 9.49632 9.35799 9.35682L13.1127 5.58115C13.3992 5.29451 13.8735 5.29451 14.16 5.58115C14.4466 5.86778 14.4466 6.34221 14.16 6.62885Z"
                fill="white"
              />
            </svg>
          </FileSyncButton>
        </div>
      </div>
    </>
  );
}

export default RecordingCompEngTut;
