import React, { useState, useContext, useEffect } from "react";
import "./dataGrid.css";
import { Checkbox } from "@mui/material";
import { AdminPanelBtn } from "../utility/button/button.js";
import { MyContext } from "../../context/context";
import { toast } from "react-toastify";

const DataTable = () => {
  const { admin } = useContext(MyContext);
  const [selELems, setSelELems] = useState([]);
  const [editELems, setEditELems] = useState([]);
  const btnStyleObj = {
    padding: "5px 10px",
    // background: "#1F8B24",
    boxShadow: "0px 6.625px 28.3929px rgba(31, 139, 36, 0.25)",
    borderRadius: "10px",
    border: "none",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    color: "white",
  };

  function handleSelect(e) {
    if (selELems.includes(e.id)) {
      let nArr = [];
      nArr = selELems.filter((id) => id !== e.id);
      // console.log('inside filter',nArr);
      setSelELems(nArr);
    } else {
      console.log("inside adding");
      setSelELems((p) => [...p, e.id]);
    }
  }

  function handleSelectAll() {
    let prompDataLength = admin?.promptData?.length;
    if (selELems.length === prompDataLength) {
      setSelELems([]);
    } else {
      let nArr = [];
      nArr = admin?.promptData?.map((e) => e.id);
      setSelELems(nArr);
    }
  }

  function handleSingleEdit(e) {
    if (admin?.promptData?.length === selELems.length) {
      toast.info(
        "you are using multi-select,use global edit button to edit this rule"
      );
      return;
    }

    setEditELems((p) => [...p, e.id]);
  }

  async function handleSingleSave(e) {
    let nArr = [];

    let txCont = document.getElementById("artcfd" + e.id).value;

    await admin.updatePromptData([{ id: e.id, rules: txCont }]);

    nArr = editELems?.filter((el) => el !== e.id);
    setEditELems(nArr);
  }

  function handleMultEdit() {
    let nArr = [];
    nArr = selELems.map((e) => {
      return e;
    });
    if (nArr.length === 0) {
      // alert("please select prompts to edit");
      return 0;
    }
    setEditELems(nArr);
    return true;
  }

  async function handleMultSave() {
    let valArr = [];
    editELems?.forEach((id) => {
      let element = document.getElementById("artcfd" + id),
        text = element.value,
        obj = { id, rules: text };
      valArr.push(obj);
    });
    await admin.updatePromptData(valArr);

    setEditELems([]);
    setSelELems([]);
  }

  async function handleSingleDelete(e) {
    console.log("handleSingleDelete single delete elem", e);
    let arr = [];
    arr.push(e.id);
    admin.deletePromptData([...arr]);
    // console.log("getting single del response in data grid...",resp);
    // admin.updatePromptData(resp.payload);
  }

  async function handleMultiDelete() {
    let arr = selELems.map((e) => {
      return e;
    });
    // console.log("delete id's:",arr);
    if (arr.length === 0) {
      console.log("select rules to delete!");
      return;
    }
    console.log("sending selected elements arr to deleted",arr);
    // return;
    let resp = await admin.deletePromptData([...arr]);
    if(resp.success){
      setSelELems([]);
    }
  }

  async function addPromptToData(val) {
    await admin.addPromptData({ rules: val, ruleNo: "", id: "" });
  }

  useEffect(() => {
    console.log("fetching data in useEffect...");
    admin.fetchPromptData();
  }, []);
  return (
    <>
      <InputComp handlAdd={addPromptToData} />
      <GlobalBtnComp
        handlEdit={handleMultEdit}
        handlSave={handleMultSave}
        handlDel={handleMultiDelete}
      />
      <div className="dataTableWrppr">
        <table className="myTable">
          <thead className="mtHead">
            <tr className="mtHeadRow">
              <td className="mthCheckBox">
                <Checkbox
                  checked={admin?.promptData?.length ? (admin?.promptData?.length === selELems.length):""}
                  onChange={handleSelectAll}
                  sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
                />
              </td>
              <td className="mthCheckBoxRuleNo">Rule No.</td>
              <td className="mthRules">Rules</td>
              <td className="mthEditSave">Edit/Save</td>
              <td className="mthDelete" style={{ paddingRight: "16px" }}>
                Delete
              </td>
            </tr>
          </thead>
          <div className="tableBodyWrppr">
            <tbody className="mtBody">
              {admin?.promptData?.map((e, i) => {
                (i===0)&&console.log("promptData:", admin?.promptData);
                // console.log("elemment:", e.content);

                return (
                  <tr className="mtRow" key={"tblRowtblr" + i}>
                    <td className="mthCheckBox">
                      <Checkbox
                        checked={selELems.includes(e.id)}
                        onChange={() => {
                          handleSelect(e);
                        }}
                        sx={{
                          color: "#00AFEF",
                          "&.Mui-checked": { color: "#0574EA" },
                        }}
                      />
                    </td>
                    <td className="mthCheckBoxRuleNo">{i + 1}</td>
                    <td className="mthRules">
                      <textarea
                        className="adminRuleText"
                        id={"artcfd" + e.id}
                        readOnly={!editELems.includes(e.id)}
                        placeholder="Enter Rules Here..."
                      >
                        {e.content}
                      </textarea>
                    </td>
                    <td
                      className="mthEditSave"
                      style={{ paddingLeft: "19px", boxSizing: "border-box" }}
                    >
                      {editELems.includes(e.id) ? (
                        <div
                          style={{
                            ...btnStyleObj,
                            background: "#00AFEF",
                            width: "59.19px",
                            textAlign: "center",
                            boxSizing: "border-box",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            console.log("clicking save btn");
                            handleSingleSave(e);
                          }}
                        >
                          Save
                        </div>
                      ) : (
                        <AdminPanelBtn
                          title={"Edit"}
                          src={"editBtnIconAdmin.svg"}
                          bgCol="#1F8B24"
                          handleClick={() => {
                            handleSingleEdit(e);
                          }}
                          stylObj={btnStyleObj}
                          iconStyl={{ maxWidth: "10px" }}
                        />
                      )}
                    </td>
                    <td
                      className="mthDelete"
                      style={{ paddingTop: "6px", boxSizing: "border-box" }}
                    >
                      <img
                        src="./assests/img/delIconAdminRow.svg"
                        alt="delete"
                        className="delIconAdminRow"
                        onClick={() => {
                          console.log("clicking row delete...");
                          handleSingleDelete(e);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </div>
        </table>
      </div>
    </>
  );
};

export default DataTable;

function GlobalBtnComp({ handlEdit, handlSave, handlDel }) {
  const [isSave, setIsSave] = useState(false);
  function handleDelete() {
    console.log("deleting multiple rules...");
    handlDel();
  }
  function handleEdit() {
    console.log("editing multiple rules...");
    let x = handlEdit();
    x && setIsSave(true);
  }
  function handleSave() {
    handlSave();
    setIsSave(false);
  }
  const btnStyleObj = {
    padding: "8px 18px",
    // background: "#1F8B24",
    boxShadow: "0px 6.625px 28.3929px rgba(31, 139, 36, 0.25)",
    borderRadius: "10px",
    border: "none",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15.1429px",
    color: "white",
  };
  return (
    <>
      <div className="glblBtnWrppr">
        <div style={{ marginRight: "13px" }}>
          {isSave ? (
            <div
              className="glbSaveBtnAdmin"
              style={{
                ...btnStyleObj,
                background: "#0574EA",
                width: "85px",
                textAlign: "center",
                boxSizing: "border-box",
                cursor: "pointer",
              }}
              onClick={() => {
                console.log("clicking multi save btn");
                handleSave();
              }}
            >
              Save
            </div>
          ) : (
            <AdminPanelBtn
              title={"Edit"}
              src={"editBtnIconAdmin.svg"}
              bgCol="#1F8B24"
              handleClick={handleEdit}
              stylObj={btnStyleObj}
            />
          )}
        </div>
        <AdminPanelBtn
          title={"Delete"}
          src={"deleteIconAdmin.svg"}
          bgCol="#DA100B"
          handleClick={handleDelete}
          stylObj={btnStyleObj}
        />
      </div>
    </>
  );
}

function InputComp({ handlAdd }) {
  const [inpVal, setInpVal] = useState("");
  // const { admin } = useContext(MyContext);

  function handleChange(e) {
    let val = e.target.value;
    setInpVal(val);
  }

  async function addPromptRule() {
    console.log("adding prompt rule...");
    if (!inpVal) {
      toast.info("can't add empty prompt");
      return;
    }
    await handlAdd(inpVal);
    setInpVal("");
    // await admin.addPromptData({rules:inpVal,ruleNo:'',id:''});
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      addPromptRule();
    }
  }
  return (
    <>
      <div className="apInputWrppr">
        <input
          type="text"
          value={inpVal}
          onChange={handleChange}
          className="apInput"
          placeholder="Define Your Rules Here........"
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
        />
        <img
          src="./assests/img/sendIconAdmin.svg"
          alt="send"
          className="apImageIcon"
          onClick={addPromptRule}
        />
      </div>
    </>
  );
}
