// import React from 'react'
import "./eng_tutor.css";
// import { HiMiniSpeakerWave } from "react-icons/hi2";
import RecordingCompEngTut, {
  InputRecCompEngTut,
} from "./recordingComp/recordingComp";
// import RecordingCompEngTut1 from "../waveSurferDemo/waveSurface";

import { useContext, useEffect, useRef, useState } from "react";

// import AudioUrlWS from "./Kalimba.mp3";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { MyContext } from "../../context/context";
import { toast } from "react-toastify";
import { formatChatDate } from "../utility/functions/helperFunc";
import LoadingComponent from "../loadingSpinner/loadingSpinner";
import { FormSelectInp } from "../utility/select_dropDown/selectDropDown";
import { FileSyncButton } from "../utility/button/button";
import { PiNotepadFill } from "react-icons/pi";
import { IoMdAddCircleOutline } from "react-icons/io";
import NoteComponent from "../notesComponent/notes";

import {
  fetchUsrConversationEngTut,
  getAllSuppLanguagesEngTut,
  setLangToLearnEngTut,
  deleteLangTutChatEngTut,
  fetchNotesData,
  createNotes,
} from "../../actions/action";

let chatPageNumber = 1;

function Eng_tutor() {
  const [usrConv, setUsrConv] = useState([]);
  const [loadFetchConv, setLoadFetchConv] = useState(false);
  const [prevChatLoader, setPrevChatLoader] = useState(false);
  const [newMesasge, setNewMesasge] = useState(false);
  const [allLangList, setAllLangList] = useState([]);
  const [selectedLangList, setSelectedLangList] = useState([]);
  const [selLabelSelDrpDwn, setSelLabelSelDrpDwn] = useState(
    "Select Your Native Language"
  );
  const [disableInpList, setDisableInpList] = useState([]);
  const [disableAllInp, setDisableAllInp] = useState(false);

  const [notesList, setNotesList] = useState([]);

  const [openMenu, setOpenMenu] = useState(true);
  const [openNotesDelay, setOpenNotesDelay] = useState(true);

  const chatContainerRef = useRef(null);

  const { userDetails, engTutor } = useContext(MyContext);

  function toggleSlideContiner() {
    if (openMenu) {
      setOpenMenu(!openMenu);
      setOpenNotesDelay(!openNotesDelay);
    } else {
      let x = setTimeout(() => {
        setOpenNotesDelay(!openNotesDelay);
        clearTimeout(x);
      }, [500]);
      setOpenMenu(!openMenu);
    }
  }

  async function fetchUserConversation(page = 1) {
    setLoadFetchConv(true);
    const userType = userDetails?.user_type || "";
    let resp = await fetchUsrConversationEngTut(page, userType);
    let x = setTimeout(() => {
      console.log("resp fetch user conversation eng tutor....", resp);
      if (resp.success) {
        let newArr = resp?.payload?.data || [];
        newArr = newArr.reverse();
        console.log("prev user conversation --------->", usrConv);
        console.log("newly fetched conversation --------->", newArr);
        setUsrConv((prevconv) => {
          console.log(
            "previous conversation check before fetching new conv ------->",
            prevconv
          );
          if (page > 1) {
            return [...prevconv, ...newArr];
          } else {
            return [...newArr];
          }
        });
        let x = setTimeout(() => {
          scrollToBottom();
          clearTimeout(x);
        }, 100);
      } else {
        setUsrConv([]);
        toast.error("error fetching conversation");
      }
      setLoadFetchConv(false);
      clearTimeout(x);
    }, 1500);
  }

  function handleUpdateConversationForNewMsgForNewMsg(v) {
    console.log("conversation object");
    setUsrConv((prev) => {
      console.log("my prev conv-------->", prev);

      let updatedArrConv = [...prev, { ...v }];
      console.log("updatedArrConversation-------->", updatedArrConv);
      return updatedArrConv;
    });
    // let x = setTimeout(()=>{
    setNewMesasge(true);
    //   clearTimeout(x);
    // },100)
  }

  async function handleLoadPrevChat(e) {
    console.log("on scroll event fired!");
    let chatWindow = e.target;
    if (chatWindow.scrollTop === 0) {
      chatPageNumber++;
      setPrevChatLoader(true);
      const userType = userDetails?.user_type || "";
      let resp = await fetchUsrConversationEngTut(chatPageNumber, userType);
      let x = setTimeout(() => {
        setPrevChatLoader(false);
        chatContainerRef.current.scrollTop = 90;
        if (resp.success) {
          let newArr = resp?.payload?.data || [];
          newArr = newArr?.reverse();
          if (newArr?.length === 0) {
            chatPageNumber--;
            toast.info("no more messages to load !");
          }
          setUsrConv([...newArr, ...usrConv]);
        } else {
          // setUsrConv([]);
          toast.error("error fetching conversation");
        }

        clearTimeout(x);
      }, 1500);
    }
  }

  async function fetchSupportedLanguages() {
    const userType = userDetails?.user_type || "";
    let response = await getAllSuppLanguagesEngTut(userType);
    console.log(
      "get all supp lang response comp ---->",
      response?.payload?.data
    );
    if (!response?.success) return;
    let responseArr = response?.payload?.data?.map((e) => {
      return { name: e?.name, id: e?.id };
    });
    console.log("modified list all lang ---->", responseArr);
    // let responseArr = response?.payload?.data;
    setAllLangList([...responseArr]);
    checkPrevSelLanguage(responseArr);
  }

  async function handleSelectedLangList(v) {
    const userType = userDetails?.user_type || "";
    setSelectedLangList([...v]);
    console.log("getting v in handle sele ------>", v, "v.name", v[0]?.name);
    setSelLabelSelDrpDwn("changing Language ...");
    setDisableAllInp(true);
    let resp = await setLangToLearnEngTut({ language_id: v[0]?.id }, userType);
    setDisableAllInp(false);
    console.log("response set lang to learn ----->", resp);
    if (resp?.success) {
      setSelLabelSelDrpDwn(v[0]?.name);
      await fetchUserConversation();
    } else {
      setSelectedLangList([]);
      setSelLabelSelDrpDwn("Select Your Native Language");
    }
  }

  async function clearLangTutHistory() {
    const userType = userDetails?.user_type || "";
    let empArr = [];
    setUsrConv([...empArr]);
    setDisableAllInp(true);
    let resp = await deleteLangTutChatEngTut(userType);
    console.log("response clear lang tut history ---->", resp);
    setDisableAllInp(false);
    if (resp?.success) {
      await fetchUserConversation();
      toast.success("Chat History Cleared !");
    }
  }

  function checkPrevSelLanguage(langList = []) {
    console.log(
      "prev lang user details ------>",
      userDetails?.language,
      "       langList ---->",
      langList
    );
    langList?.forEach((e) => {
      if (e?.id === userDetails?.language) {
        setSelLabelSelDrpDwn(e?.name);
      }
    });
  }

  async function getNotesData() {
    const userType = userDetails?.user_type || "";
    console.log("my user type111 =====>",userType);
    let resp = await fetchNotesData(userType);
    console.log("fetch notes response data =====>", resp);
    if (resp?.success) {
      console.log(
        "fetch notes success response data =====>",
        resp?.payload?.data
      );
      let notesArr = resp?.payload?.data || [];
      setNotesList([...notesArr]);
    }
  }

  async function handleCreateNewNotes() {
    const userType = userDetails?.user_type || "";
    const InitialContentNote = `
    <h2>Title</h2>
    <p><br></p>
    <p>Write Your Message Here </p>
    `;

    let data = {
      content_html: InitialContentNote,
      bg_color: "#fff",
    };

    let resp = await createNotes(data,userType);

    if (resp?.success) {
      console.log("created notes successfully ====>", resp?.payload);
      getNotesData();
    }else{
      toast?.error("enable to create note, try again")
    }
  }

  useEffect(() => {
    console.log("fetching user conversation......");
    fetchUserConversation();
    fetchSupportedLanguages();
    checkPrevSelLanguage();
    getNotesData();
  }, []);

  useEffect(() => {
    if (newMesasge) {
      scrollToBottom();
      setNewMesasge(false);
    }
  }, [newMesasge]);

  function scrollToBottom() {
    console.log("inside scroll to bottom.......");
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }

  function toggleChatSession() {
    console.log("clicking start session message ====>");

    if(!engTutor?.isRecordPermission || !engTutor?.isMediaStreamReady){
      toast?.info("Mic not ready, check for permissions and try again")
    }

    let isUserConvEmpty = usrConv[0] ? false : true,
      sessionState = engTutor?.isStartSessEngTut;

    console.log("session state toggleChatSession ====>", sessionState);

    engTutor?.toggleSessionEngTut(!sessionState, isUserConvEmpty);
  }

  return (
    <>
      <div className="engTutorMainSectionETMS">
        {/* 
        
        */}
        <div className="notesSectionEngTutor">
          <div className="engTutorNotesSlideCont">
            {openNotesDelay ? (
              <div className="engTutorNotesEmptyCont">
                <FileSyncButton
                  title="Click Here to Create Your Notes..."
                  stylObj={{
                    backgroundColor: "white",
                    color: "#00AFEF",
                    justifyContent: "center",
                    marginBottom: "1rem",
                  }}
                  handleClick={() =>
                    // console.log("adding new notes element ===>")
                    handleCreateNewNotes()
                  }
                >
                  <IoMdAddCircleOutline
                    size={24}
                    style={{
                      marginRight: "10px",
                      fontFamily: "Poppins",
                      fontSize: "14.124px",
                      fontStyle: "normal",
                      fontWeight: "600",
                    }}
                  />
                </FileSyncButton>
                <div className="engTutorSideNotesScrollWrapper">
                  <div className="engTutorInnerWrpprSideNotes">
                    {notesList?.toReversed().map((e) => (
                      <NoteComponent notesDetl={e} fetchNoteList={getNotesData} key={`myengtutnotesMETN${e?.id}`} />
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="engTutorNotesEmptyCont"></div>
            )}
            <div
              className={`switchBtnContEngTutNotes ${
                openMenu ? "openMenuEngTutNotes" : ""
              }`}
            >
              <PiNotepadFill
                color="white"
                className="menuIconWrpprEngTutNotes"
                onClick={toggleSlideContiner}
                size={"24px"}
              />
            </div>
          </div>
        </div>
        <div className="chatSectionEngTutor">
          <div className="ChatSectionEngTutor">
            <div className="chatOptnsWrpprEngTut">
              <FormSelectInp
                initialLabelDropDown={selLabelSelDrpDwn}
                optionList={allLangList}
                handlChange={(v) => {
                  handleSelectedLangList(v);
                }}
                multiSel={false}
                selProdList={selectedLangList}
                disabled={disableInpList?.includes("SelectLang")}
              />
              <FileSyncButton
                title={"Clear Chat History"}
                stylObj={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "white",
                  margin: "0px 0px 14px 15px",
                }}
                disabled={disableInpList?.includes("ClearChatBtn")}
                handleClick={clearLangTutHistory}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  style={{ marginRight: "5px" }}
                >
                  <path
                    d="M18.9808 8.69713C19.1802 8.69713 19.3611 8.78413 19.504 8.93113C19.6373 9.08813 19.7044 9.28313 19.6849 9.48913C19.6849 9.55712 19.152 16.2971 18.8476 19.134C18.657 20.875 17.5346 21.932 15.8511 21.961C14.5567 21.99 13.2914 22 12.0455 22C10.7229 22 9.42938 21.99 8.17381 21.961C6.54673 21.922 5.42343 20.846 5.24254 19.134C4.92937 16.2871 4.40614 9.55712 4.39642 9.48913C4.38669 9.28313 4.45282 9.08813 4.58704 8.93113C4.7193 8.78413 4.90992 8.69713 5.11027 8.69713H18.9808ZM14.1065 2C14.9905 2 15.7802 2.61699 16.0088 3.49699L16.1722 4.22698C16.3044 4.82197 16.8199 5.24297 17.4132 5.24297H20.3289C20.7179 5.24297 21.0417 5.56596 21.0417 5.97696V6.35696C21.0417 6.75795 20.7179 7.09095 20.3289 7.09095H3.7556C3.36561 7.09095 3.04175 6.75795 3.04175 6.35696V5.97696C3.04175 5.56596 3.36561 5.24297 3.7556 5.24297H6.67131C7.2636 5.24297 7.77905 4.82197 7.91229 4.22798L8.06498 3.54598C8.30228 2.61699 9.08324 2 9.97702 2H14.1065Z"
                    fill="white"
                  />
                </svg>
              </FileSyncButton>
            </div>
            <div
              className="chatWindowEngTutor"
              ref={chatContainerRef}
              onScroll={handleLoadPrevChat}
            >
              {loadFetchConv ? (
                <MsgCardSkeleton count={5} />
              ) : (
                <>
                  {prevChatLoader && (
                    <div className="loadingCompWrpprPrevChat">
                      <LoadingComponent
                        styl={{
                          bottom: "0px",
                          right: "0px",
                          position: "relative",
                        }}
                        size={"24px"}
                      />
                    </div>
                  )}
                  {usrConv[0] ? (
                    usrConv.map((e, i) => {
                      i === 0 && console.log("conversation element ------>", e);
                      let dateStr = formatChatDate(e?.created_at || "");
                      return (
                        <>
                          {e?.message && (
                            <RequestMsgBoxEngTut
                              text={e?.message}
                              date={dateStr || ""}
                              key={`RequestMsgBoxEngTutRMBET${i}`}
                            />
                          )}
                          <ResponseMsgBoxEngTut
                            text={e?.reply}
                            date={dateStr || ""}
                            key={`ResponseMsgBoxEngTutRMBET${i}`}
                          />
                        </>
                      );
                    })
                  ) : (
                    <p className="engTutParaStartChat">
                      {" "}
                      click record button to start a conversation...
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          {/* <RecordingCompEngTut
        handleUpdateUsrConv={handleUpdateConversationForNewMsgForNewMsg}
      /> */}
          <SessionBtn clickHandler={toggleChatSession} />
          <InputRecCompEngTut
            handleUpdateUsrConv={handleUpdateConversationForNewMsgForNewMsg}
            disabledAll={disableAllInp} // disableAllInp disableInpList?.includes("InputRecComp")
          />
        </div>
      </div>
    </>
  );
}

export default Eng_tutor;

function ResponseMsgBoxEngTut({ text = "", date = "" }) {
  const { engTutor } = useContext(MyContext);

  const audioRef = useRef(null);

  // function handlePlayPauseAudio() {
  //   // console.log("imported audio file -------->", AudioUrlWS);

  //   if(!engTutor?.audioToPlay){
  //     console.error("problem loading the audio");
  //     return
  //   };

  //   if (audioRef?.current?.paused) {
  //     audioRef?.current?.play();
  //   } else {
  //     audioRef?.current?.pause();
  //   }

  //   // console.log("pre recorder sound in url form -------->", recorderAudioUrl);
  //   // engTutor.updateAudioToPlayWS(recorderAudioUrl);
  // }

  return (
    <div className="responseMsgBoxMainWrpprEngTut">
      <img
        src="/assests/img/AI_Eng_Tutor_Resp_Logo.svg"
        alt="AI"
        className="AIImgLogoResMsgBoxEngTut"
      />
      <div className="actvIndResMsgBoxEngTut"></div>
      <div className="contDetlWrpprResMsgBoxEngTut">
        <p className="labelSecResMsgBoxEngTut">
          <span className="msgByLblResMsgBoxEngTut">AI English Tutor</span>
          <span className="dateTimeLblResMsgBoxEngTut">{date}</span>
        </p>
        <div className="msgContentWrpprResMsgBoxEngTut">
          <p className="msgContentResMsgBoxEngTut">{text}</p>
          {/* <HiMiniSpeakerWave className="spkrIconResMsgBoxEngTut"  onClick={handlePlayPauseAudio} /> */}
        </div>
      </div>
      <audio
        ref={audioRef}
        src={engTutor?.audioToPlay || ""} // replace with audio from backend
        controls
        style={{ display: "none" }}
        // autoPlay
      ></audio>
    </div>
  );
}

function RequestMsgBoxEngTut({ text = "", date = "" }) {
  const { engTutor } = useContext(MyContext);

  const audioRef = useRef(null);

  // function handlePlayPauseAudio() {
  //   // console.log("imported audio file -------->", AudioUrlWS);

  //   if(!engTutor?.audioToPlay){
  //     console.error("problem loading the audio");
  //     return
  //   };

  //   if (audioRef?.current?.paused) {
  //     audioRef?.current?.play();
  //   } else {
  //     audioRef?.current?.pause();
  //   }

  //   // console.log("pre recorder sound in url form -------->", recorderAudioUrl);
  //   // engTutor.updateAudioToPlayWS(recorderAudioUrl);
  // }

  // let recorderAudioUrl = "";

  // useEffect(() => {

  //   // temp function for testing the audio ..........

  //   fetch("/static/media/Kalimba.07e98a7a623054ba787c.mp3")
  //     .then((resp) => resp.blob())
  //     .then((blob) => {
  //       recorderAudioUrl = URL.createObjectURL(blob);
  //       engTutor?.updateAudioToPlayWS(recorderAudioUrl); // remove state from english tutor and related unused ones too....
  //       // console.log("getting responseUrl from audio file ------->",recorderAudioUrl);
  //       // console.log("playPause state eng tutor ------->",engTutor.playPauseRecAudio);
  //       // if (engTutor.playPauseRecAudio === "play") {
  //       //   engTutor.updateAudioPlayingStateWS("pause");
  //       //   // console.log("inside play section");
  //       // } else if (engTutor.playPauseRecAudio === "pause") {
  //       //   // console.log("inside pause section");
  //       //   engTutor.updateAudioPlayingStateWS("play");
  //       // }
  //       return true;
  //     })
  //     .catch((err) => {
  //       console.error("err fetching audio file------->", err);
  //     });
  // }, []);

  return (
    <div className="requestMsgBoxMainWrpprEngTut">
      {/* <img src="/assests/img/AI_Eng_Tutor_Resp_Logo.svg" alt="AI" className="AIImgLogoResMsgBoxEngTut" /> */}
      {/* <div className="actvIndResMsgBoxEngTut"></div> */}
      <div className="contDetlWrpprResMsgBoxEngTut">
        <p className="labelSecResMsgBoxEngTut">
          <span className="dateTimeLblResMsgBoxEngTut">{date}</span>
          <span className="msgByLblResMsgBoxEngTut">You</span>
        </p>
        <div className="msgContentWrpprResMsgBoxEngTut msgContentWrpprReqMsgBoxEngTut">
          <p className="msgContentResMsgBoxEngTut">{text}</p>
          {/* <HiMiniSpeakerWave
            className="spkrIconResMsgBoxEngTut spkrIconReqMsgBoxEngTut"
            onClick={handlePlayPauseAudio}
          /> */}
        </div>
      </div>
      <audio
        ref={audioRef}
        src={engTutor?.audioToPlay || ""} // replace with audio from backend
        controls
        style={{ display: "none" }}
        // autoPlay
      ></audio>
    </div>
  );
}

function MsgCardSkeleton({ count = 1 }) {
  return (
    <>
      <SkeletonTheme baseColor="#ececec" highlightColor="#f5f5f5">
        {Array(count)
          .fill(0)
          .map((e, i) => {
            let align = "left";
            if (i % 2 == 0) align = "right";
            return (
              <div
                className={`msgCardSkelWrppr ${
                  align === "right" && "alignRightSkel"
                }`}
                key={`msgCardSkelWrppr${i}MCSW`}
              >
                <div className="labelContMsgCardSkel">
                  <Skeleton width={343} height={17} />
                </div>
                <div className="mgcontMsgCardSkel">
                  <Skeleton width={343} height={61} />
                </div>
              </div>
            );
          })}
      </SkeletonTheme>
    </>
  );
}

function SessionBtn({ clickHandler = () => {} }) {
  const [metaData, setMetaData] = useState({
    title: "Start Session",
    background: "#00AFEF",
  });
  const { engTutor } = useContext(MyContext);

  useEffect(() => {
    console.log(
      "eng tutor session button value =====>",
      engTutor?.isStartSessEngTut
    );
    if (engTutor?.isStartSessEngTut === null) return;
    if (engTutor?.isStartSessEngTut) {
      setMetaData({
        title: "End Session",
        background: "red",
      });
    } else {
      setMetaData({
        title: "Start Session",
        background: "#00AFEF",
      });
    }
  }, [engTutor?.isStartSessEngTut]);
  return (
    <div className="engTutorChatSessionBtnMainWrppr">
      <div className="engTutorChatSessionBtnInnerWrppr">
        <FileSyncButton
          title={metaData?.title} // Start Session End Session
          handleClick={clickHandler}
          stylObj={{
            padding: "0.2rem 0.8rem",
            fontSize: "12px",
            color: "white",
            fontWeight: "400",
            borderRadius: "7px",
            fontFamily: "Poppins",
            background: metaData?.background,
          }}
        />
      </div>
    </div>
  );
}
