import "./uploadCont.css";
import React, { useState, useRef, useEffect, useContext } from "react";
import { FileSyncButton } from "../utility/button/button.js";
import { sendUploadedData,fileTextExtraction } from "../../actions/action.js";
import { MyContext } from "../../context/context.js";
import RefreshIcon from "@mui/icons-material/Refresh";
import { FaFileLines } from "react-icons/fa6";
import { AiOutlineCloseCircle } from "react-icons/ai";

function UploadCont({ totalWords = 0 }) {
  const [isTyping, setIsTyping] = useState(false);
  // const [files, setFiles] = useState([]);
  // const [text, setText] = useState("");
  const {
    updateFiles,
    updateText,
    files,
    text,
    updateResponse,
    updateWordLimit,
  } = useContext(MyContext);

  function handleTyping() {
    setIsTyping(true);
  }

  function refreshUploadWindow() {
    updateFiles([]);
    updateText("");
    setIsTyping(false);
    updateResponse("");
    updateWordLimit("");
  }
  //   async function handleUploadedData() {
  //     // Create a new FormData object
  //     const formData = new FormData();

  // (async()=>{
  //   // Append each file to the FormData object
  //   let newFilesArr=[];
  //   for (let i = 0; i < files.length; i++) {
  //     // let convertedRes = await convertFileToString(files[i])
  //     // .then((v)=>{
  //     // console.log("reading file success.",convertedRes);
  //     // let newObj = {
  //     //   name: files[i].name,
  //     //   content: convertedRes.data,
  //     // }
  //     // newFilesArr.push(newObj);
  //     formData.append("files", files[i]);
  //     // })
  //     // .catch((er)=>{console.log("error reading file",er)});
  //   }
  //   console.log("newFilesArray======>",newFilesArr);
  //   formData.append("word_limit", 250);
  //   formData.append("doc_layout", "Paragraph");
  //   formData.append("text_string", text || "");
  //   // Append each file to the FormData object

  //   let response = await sendUploadedData(formData);
  //   updateResponse(response);
  //   console.log("getting response in component", response);
  // })()

  //   }

  const fileListRef = useRef(null);

  function chooseFileToAdd() {
    fileListRef.current.click();
  }

  function updateFileList(ev) {
    let fl = ev.target.files,
      newFLArr = [...files, ...fl];
    updateFiles(newFLArr);
  }

  /*
  const handleChooseFile = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    // setFiles(selectedFiles);
    filesHandler(selectedFiles);
    // Do something with the selected files
  };
  */

  useEffect(() => {
    if (!isTyping) {
      updateText("");
    }
  }, [isTyping]);

  return (
    <>
      <div className="uploadContWrppr">
        <div className="topUploadCont">
          {isTyping ? (
            <TextContainer
              handlTyping={() => {
                setIsTyping(true);
              }}
              typing={isTyping}
              isReadOnly={false}
              handleTextInp={(v) => {
                updateText(v);
              }}
              textInput={text}
            />
          ) : (
            <>
              {!files.length && (
                <span
                  style={{
                    color: "#8E95AA",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    marginLeft: "22px",
                  }}
                  onClick={handleTyping}
                >
                  Start Typing or paste your Content Here...
                </span>
              )}
              <DragAndDrop
                filesHandler={(v) => {
                  console.log("my files to serve", v);
                  updateFiles(v);
                }}
                filesVal={files}
              />
            </>
          )}
        </div>
        <div className="bottomUploadCont">
          <RefreshIcon
            color="primary"
            style={{ cursor: "pointer", color: "#00AFEF" }}
            onClick={refreshUploadWindow}
          />
        </div>
        <div className="uplBttonWrppr">
          <FileSyncButton
            title={"Upload Documents"}
            src={"uploadBtnIcon.png"}
            bkg={"makeBlueBtn"}
            handleClick={() => {
              setIsTyping(false);
              chooseFileToAdd();
            }}
          />
          <input
            ref={fileListRef}
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              updateFileList(e);
            }}
            multiple
          />
        </div>
      </div>
    </>
  );
}

export default UploadCont;

function DragAndDrop({ filesHandler, filesVal }) {
  const [dragging, setDragging] = useState(false);

  console.log("dragging", dragging);

  function handleDrop(e) {
    e.preventDefault();
    setDragging(false);
    const newFiles = [...filesVal];
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      newFiles.push(e.dataTransfer.files[i]);
    }
    // setFiles(newFiles);
    filesHandler(newFiles);
  }

  function handleDragOver(e) {
    e.preventDefault();
    setDragging(true);
  }

  function handleDragLeave(e) {
    e.preventDefault();
    setDragging(false);
  }

  // file choosing functions
  const fileInputRef = useRef(null);

  const handleChooseFile = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    // setFiles(selectedFiles);
    filesHandler(selectedFiles);
    // Do something with the selected files
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      style={{
        width: "100%",
        height: "100%",
        // border: `2px dashed ${dragging ? "blue" : "gray"}`,
        padding: "1rem",
        boxSizing: "border-box",
      }}
    >
      {!filesVal.length ? (
        <div className="emptyFileWrpprDnD">
          {/* <h3>Files:</h3>
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul> */}
          <img
            src="/assests/img/Paper-Upload.png"
            alt="paper-upload-icon"
            className="uploadIconDnD"
          />
          <p className="instructionsDnD">
            Drag & Drop your files here or{" "}
            <span className="chooseFileOptnDnD" onClick={handleChooseFile}>
              choose a file
            </span>
          </p>
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple
          />
          <span className="fileLimitDnD">100MB max file size.</span>
        </div>
      ) : filesVal.length > 1 ? (
        <MultiFileComp fileDet={filesVal} filesHandler={filesHandler} />
      ) : (
        <SingleFileComp fileDet={filesVal} />
      )}
    </div>
  );
}

export function TextContainer({
  handlTyping,
  typing,
  isReadOnly,
  handleTextInp,
  textInput,
}) {
  const {
    updateFiles,
    updateText,
    updateLoadingState,
    updateResponse,
    files,
    text,
  } = useContext(MyContext);

  function handleChange(event) {
    // setText(event.target.value);
    handleTextInp(event.target.value);
  }

  async function handleUploadedData() {
    // Create a new FormData object
    const formData = new FormData();

    console.log(
      "sending data...   files",
      files,
      "  word_limit",
      250,
      "   doc_layout",
      "paragraph",
      "   text_string",
      text
    );
    // Append each file to the FormData object
    let newFilesArr = [];
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    console.log("newFilesArray======>", newFilesArr);
    formData.append("word_limit", 0);
    formData.append("doc_layout", "empty");
    formData.append("text_string", text || "");
    // Append each file to the FormData object

    updateLoadingState(true);
    let response = await sendUploadedData(formData);
    updateLoadingState(false);
    updateResponse(response);
    console.log("getting response in component", response);
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleUploadedData();
    }
  }

  return (
    <div
      style={{
        width: "100%",
        //  height: "100%",
        flex: 1,
      }}
    >
      <textarea
        className="textAreaInp"
        value={textInput}
        onChange={handleChange}
        onBlur={() => handlTyping()}
        autoFocus
        // onClick={handleTyping}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          resize: "none",
          boxSizing: "border-box",
          overflowY: "scroll", //`${typing ? "scroll" : "none"}`
          // maxHeight: "100%",
        }}
        readOnly={isReadOnly}
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
        // placeholder="Start Typing or paste your Content Here..."
      />
    </div>
  );
}

function SingleFileComp({ fileDet = "got a single file" }) {
  // const [response, setResponse] = useState("");
  const [myContent, setMyContent] = useState("");
  // const [pdfUrl, setPdfUrl] = useState(null);
  // const [isPdfFile, setIsPdfFile] = useState(false);

  const { updateResponse, updateLoadingState,userDetails } = useContext(MyContext);

  // async function handleSendRequest() {
  //   try {
  //     const formData = new FormData();
  //     let convFile = await convertFileToString(fileDet[0]);
  //     console.log("converted file data", convFile.data);
  //     setMyContent(convFile.data);
  //     // Append each file to the FormData object
  //     formData.append("files", fileDet[0]);
  //     formData.append("word_limit", 0);
  //     formData.append("doc_layout", "empty");
  //     formData.append("text_string", "");
  //     // Append each file to the FormData object

  //     updateLoadingState(true);
  //     let resp = await sendUploadedData(formData);
  //     updateLoadingState(false);
  //     updateResponse(resp);

  //     console.log("singlefile comp sending request to python..", resp);
  //     // setResponse(resp);
  //   } catch (error) {
  //     console.log("singlefile comp sending request to python..", error);
  //   }
  // }

  async function handleMyContent() {
    console.log("file before text extraction --------->", fileDet[0]?.type);

    const userType = userDetails?.user_type || "";
    const formData = new FormData();
    formData.append("file", fileDet[0] || "");
    let response = await fileTextExtraction(formData,userType);

    console.log("file extraction response -------->",response);

    if(response?.success){
      let fileContent = response?.payload?.text;
      setMyContent(fileContent);
    }else{
      setMyContent("");
    }

    // if (fileDet[0]?.type === "application/pdf") {
      
    //   setIsPdfFile(true);
    //     const url = URL.createObjectURL(fileDet[0]);
    //     setPdfUrl(url);
      

    //   // console.log("showing pdf file ------>");
    // } else {
    // }
      // setIsPdfFile(false);
      // let convFile = await convertFileToString(fileDet[0]);
      // console.log("converted file data", convFile.data);
      // setMyContent(convFile.data);
  }

  useEffect(() => {
    console.log("calling file request");
    // handleSendRequest();
    handleMyContent();
  }, [fileDet]);
  return (
    <div className="singleFileDispBlock">
      {
      // isPdfFile?
      // pdfUrl && (
      //   <iframe
      //     src={pdfUrl}
      //     type="application/pdf"
      //     width="100%"
      //     height="600px"
      //     title="PDF Viewer"
      //     // sandbox="allow-scripts"
      //     frameborder="0"
      //     style={{width:"590px",height:"367px"}}
      //   />
      // )
      // :
      myContent || "loading Single file content..."
      }
    </div>
  ); //response ||
}

function MultiFileComp({ fileDet, filesHandler }) {
  const { updateLoadingState } = useContext(MyContext);
  console.log(
    "file detail multifile component",
    fileDet,
    "length",
    fileDet.length
  );
  // const [localFileCount, setLocalFileCount] = useState(0);
  const { updatePrimary, updateResponse, files, wordLimitCont } =
    useContext(MyContext);
  // function filterFiles(identifier){
  //   let filterArray = Array.from(fileDet)?.filter((e)=>e.name !== identifier );
  //   filesHandler(filterArray);
  // }
  function handleLocalCount(selFile, fileArray, index) {
    console.log("selected file data.....", selFile);
    console.log("selected file array.....", fileArray);
    console.log("selected file index.....", index);

    // ****************** REMOVING FILE FROM FILE-LIST ******************

    let tempFileArray = Array.from(fileArray);
    // console.log("tempfiles array before slice",tempFileArray);
    tempFileArray.splice(index, 1);
    // console.log("calling files handler with array ....",tempFileArray);
    filesHandler([...tempFileArray]);

    // ****************** REMOVING FILE FROM FILE-LIST ******************

    // setLocalFileCount((v) => {
    //   if (v + 1 < fileDet.length) {
    //     return v + 1;
    //   } else {
    //     filesHandler([]);
    //     updatePrimary(false);
    //     return 0;
    //   }
    // });
  }

  async function handleSummAllBtn() {
    try {
      console.log("clicking submit...");
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }

      formData.append("word_limit", wordLimitCont || 0);
      formData.append("doc_layout", "empty");
      formData.append("text_string", "");
      // Append each file to the FormData object
      updateLoadingState(true);
      let resp = await sendUploadedData(formData);
      updateLoadingState(false);
      updateResponse(resp);

      console.log("singlefile comp sending request to python..", resp);
      // setResponse(resp);
    } catch (error) {
      console.log("singlefile comp sending request to python..", error);
    }
  }

  return (
    <>
      <div
        style={{ height: "100%", width: "100%" }}
        className="multiFileCompWrppr"
      >
        <div className="filesListWrppr">
          {Array.from(fileDet)?.map((e, i) => (
            <FileListComp
              detl={fileDet}
              ind={i}
              key={"myfileList" + i}
              countHandler={handleLocalCount}
              elemFD={e}
            />
          ))}
          {/* <FileListComp detl={'this is detail'} key={'myfileList'+1}/> */}
        </div>
        <button className="summMultFileBtn" onClick={handleSummAllBtn}>
          Summarize All
        </button>
      </div>
    </>
  );
}

function FileListComp({ detl, countHandler, ind, elemFD }) {
  // const [hide, setHide] = useState(false);
  const { updateResponse, updatePrimary, wordLimitCont, updateLoadingState } =
    useContext(MyContext);
  console.log("intializing detl for file-list-comp", detl);
  function handleClose() {
    // setHide(true);
    console.log("calling close...");
    countHandler(elemFD, detl, ind);
  }
  async function handleSummBtn() {
    try {
      console.log("clicking submit...");
      const formData = new FormData();
      // let convFile = await convertFileToString(fileDet[0]);
      // console.log("converted file data",convFile.data);
      // setMyContent(convFile.data);
      // Append each file to the FormData object
      console.log(
        "sending single summary ......",
        detl[ind],
        "indexing .......",
        ind
      );
      formData.append("files", detl[ind]);
      formData.append("word_limit", wordLimitCont || 0);
      formData.append("doc_layout", "empty");
      formData.append("text_string", "");
      // Append each file to the FormData object
      updateLoadingState(true);
      let resp = await sendUploadedData(formData);
      updateLoadingState(false);
      updateResponse(resp);
      updatePrimary(detl[ind]);

      console.log("singlefile comp sending request to python..", resp);
      // setResponse(resp);
    } catch (error) {
      console.log("singlefile comp sending request to python..", error);
    }
  }
  return (
    <>
      <div
        className="fileListRow"
        // style={{ display: `${hide ? "none" : "flex"}` }}
      >
        <div className="leftSectionRow">
          {/* <img
            src="/assests/img/fileIconRow.png"
            alt="file"
            className="fileImgRow"
          /> */}
          <FaFileLines color="#00AFEF" className="fileImgRow" />
          <h4 className="fileTitleRow">{detl[ind].name}</h4>
        </div>
        <div className="rightSectionRow">
          <div className="fileBtnWrpprRow">
            <button className="fileSummBtnRow" onClick={handleSummBtn}>
              Summary
            </button>
            <div
              style={{
                borderLeft: "1px solid #C5DCFA",
                paddingLeft: "7px",
                marginLeft: "7px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="fileDisBtnRow"
                onClick={() => {
                  handleClose();
                }}
              >
                Dismiss
              </button>
            </div>
          </div>
          {/* <img
            src="/assests/img/closeFileRow.svg"
            alt="file"
            className="fileCloseRow"
            onClick={() => {
              handleClose();
            }}
          /> */}
          <AiOutlineCloseCircle
            className="fileCloseRow"
            onClick={() => {
              handleClose();
            }}
            color="#00AFEF"
            size={"22px"}
          />
        </div>
      </div>
    </>
  );
}

// converting to string format.
const convertFileToString = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // reading as text...
    console.log("file reader -------->1111:  ", reader);

    reader.onload = () => {
      const fileString = reader.result;
      resolve({ success: true, data: fileString });
    };

    reader.onerror = () => {
      reader.abort();
      reject({ success: false, data: new Error("Failed to read file.") });
    };

    reader.readAsText(file);

    // reading as text...
  });
};
// converting to string format.
