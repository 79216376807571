import "./wave_anim.css";

function Wave_Anim({ switchVar,size=0 }) {
  return (
    <div className="main_wave_animation_wrapper">
      <div className={`${switchVar && "wave_dot1"} wave_dot`} style={size?{width:`${size}px`,height:`${size}px`}:{}}></div>
      <div className={`${switchVar && "wave_dot2"} wave_dot`} style={size?{width:`${size}px`,height:`${size}px`}:{}}></div>
      <div className={`${switchVar && "wave_dot3"} wave_dot`} style={size?{width:`${size}px`,height:`${size}px`}:{}}></div>
    </div>
  );
}

export default Wave_Anim;
