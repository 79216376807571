import { BsFillMicFill } from "react-icons/bs";
import Wave_Anim from "../utility/wave_animation/wave_anim";
import React, { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import LoadingComponent from "../loadingSpinner/loadingSpinner";

import "./siri.css";
import { toast } from "react-toastify";
import { useContext } from "react";

import { MyContext } from "../../context/context";

const SiriRecMicTestComp = ({
  isDisable = false,
  startListenToUser,
  stopListenToUser,
  updateRecMicStatus,
  isRecMicActive,
}) => {
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const [isLoading, setIsLoading] = useState(false);

  const { engTutor,userDetails } = useContext(MyContext);

  function handleManualBtnSwitch() {
    if (listening) {
      SpeechRecognition.stopListening();
      updateRecMicStatus(false);
      // stopListenToUser();
    } else {
      SpeechRecognition.startListening({ continuous: true });
      startListenToUser();
      updateRecMicStatus(true);
    }
  }

  useEffect(() => {
    let timeoutId, micsetofftimeout;

    // Function to handle the automatic split of text content
    async function handleAutomaticSplit() {
      if (transcript.trim() !== "") {
        console.log("User stopped speaking. Content:", transcript);
        setIsLoading(true);
        let resp = await stopListenToUser();
        console.log("response from stop istening ---->", resp);
        setIsLoading(false);
        console.log("waited for opertions...");
        // Perform any action with the transcript (e.g., send it to a server)
      }

      // Reset the transcript for the next speech segment
      resetTranscript();

      // Start listening again
      console.log("start listening again to user ----->");
      startListenToUser();
      // SpeechRecognition.startListening({ continuous: true });
    }

    if (listening) {
      // Set a timeout to detect pauses in speech
      timeoutId = setTimeout(handleAutomaticSplit, 3000); // Adjust the timeout duration as needed
      micsetofftimeout = setTimeout(() => {
        console.log("shutting down recording mic");
        toast.info('shuting down mic..')
        SpeechRecognition.stopListening();
        updateRecMicStatus(false);
      }, 30000);
    }

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(micsetofftimeout);
    };
  }, [
    transcript,
    resetTranscript,
    listening,
    SpeechRecognition.startListening,
  ]);

  /*
  
  Important Note: The useEffect hook returns a cleanup function that clears the timeout (timeoutId) 
  when the component unmounts or when dependencies (transcript, resetTranscript, listening, SpeechRecognition.startListening) change.

  */

  useEffect(()=>{
    // console.log("changing siri mic stage =====>");

    if (engTutor?.isStartSessEngTut === null) return;
    
    if (engTutor?.isStartSessEngTut) {
      SpeechRecognition.startListening({ continuous: true });
      startListenToUser();
      updateRecMicStatus(true);
    } else {
      SpeechRecognition.stopListening();
      updateRecMicStatus(false);
    }

  },[engTutor?.isStartSessEngTut,listening])

  return (
    <>
      {isLoading ? (
        <div className="newrecCompSiriLangTut">
          <LoadingComponent
            size={15}
            styl={{ position: "relative", right: "0px", bottom: "0px" }}
          />
        </div>
      ) : (
        <button
          className={`${listening ? "active_siri" : ""} newRecCompSiriLangTut`}
          disabled={isDisable}
          onClick={handleManualBtnSwitch}
        >
          <BsFillMicFill size={"18px"} />
          <Wave_Anim switchVar={listening} />
        </button>
      )}
    </>
  );
};

export default SiriRecMicTestComp;
