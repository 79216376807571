import "./adminSignup.css";
import React, { useEffect, useRef, useState } from "react";
import {
  signUpAdminPanel,
  signUpUserPanel,
  resetUserPassword,
  loginAdminPanel,
  forgotPassAdminPanel,
  frgtPssVryfUsrAdminPanel,
} from "../../../actions/action.js";
import {
  reDirectTo,
  validateEmail,
  validateMobile,
} from "../../utility/functions/helperFunc";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Checkbox } from "@mui/material";
import {
  AiFillCaretDown,
  AiFillEyeInvisible,
  AiFillEye,
  AiOutlineClose,
  AiFillPlusCircle,
  AiFillMinusCircle,
} from "react-icons/ai";

function AdminSignUp() {
  return (
    <div className="adminSignUpMain">
      <div className="adminSignupPosterSec">
        {/* makeMeStrech */}
        <img
          src="/assests/img/adminSignUpPoster.svg"
          alt="poster"
          className="adminSignupPosterImg"
        />
      </div>
      <div className="adminSignupFormSec ">
        {/* makeMeStrech */}
        <SignUpFormCont title={"Sign Up"}>
          <OrgFormSignupComp />
        </SignUpFormCont>
      </div>
    </div>
  );
}

export default AdminSignUp;

export function ResetPasswrdScreen() {
  return (
    <div className="adminSignUpMain">
      <div className="adminSignupPosterSec ">
        {/* makeMeStrech */}
        <img
          src="/assests/img/adminSignUpPoster.svg"
          alt="poster"
          className="adminSignupPosterImg"
        />
      </div>
      <div className="adminSignupFormSec ">
        {/* makeMeStrech */}
        <SignUpFormCont
          title={"Reset Password"}
          labelStr="Enter Password to Reset"
        >
          <ResetPasswordForm />
        </SignUpFormCont>
      </div>
    </div>
  );
}

export function UserSignUp() {
  return (
    <div className="adminSignUpMain">
      <div className="adminSignupPosterSec ">
        {/* makeMeStrech */}
        <img
          src="/assests/img/adminSignUpPoster.svg"
          alt="poster"
          className="adminSignupPosterImg"
        />
      </div>
      <div className="adminSignupFormSec ">
        {/* makeMeStrech */}
        <SignUpFormCont title={"Sign Up"}>
          <UserFormSignupComp />
        </SignUpFormCont>
      </div>
    </div>
  );
}

export function OrgLogin() {
  return (
    <div className="adminSignUpMain">
      <div className="adminSignupPosterSec">
        {/* makeMeStrech */}
        <img
          src="/assests/img/adminSignUpPoster.svg"
          alt="poster"
          className="adminSignupPosterImg"
        />
      </div>
      <div className="adminSignupFormSec ">
        {/* makeMeStrech */}
        <SignUpFormCont title={"Login"} labelStr="Enter Your Login Details">
          <UserLoginForm />
        </SignUpFormCont>
      </div>
    </div>
  );
}

export function ForgotPsswrdScrn() {
  return (
    <div className="adminSignUpMain">
      <div className="adminSignupPosterSec ">
      {/* makeMeStrech */}
        <img
          src="/assests/img/adminSignUpPoster.svg"
          alt="poster"
          className="adminSignupPosterImg"
        />
      </div>
      <div className="adminSignupFormSec ">
        {/* makeMeStrech */}
        <SignUpFormCont title={"Forgot Password"} labelStr="Enter Your mail id">
          <ForgotPasswordForm />
        </SignUpFormCont>
      </div>
    </div>
  );
}

export function FrgtPssVerifyUsrScrn() {
  const { token } = useParams();
  console.log("getting token from url...", token);
  return (
    <div className="adminSignUpMain">
      <div className="adminSignupPosterSec ">
        {/* makeMeStrech */}
        <img
          src="/assests/img/adminSignUpPoster.svg"
          alt="poster"
          className="adminSignupPosterImg"
        />
      </div>
      <div className="adminSignupFormSec ">
        {/* makeMeStrech */}
        <SignUpFormCont
          title={"Forgot Password"}
          labelStr="Enter Your Password"
        >
          <FrgtPsswrdVrfyUsrForm />
        </SignUpFormCont>
      </div>
    </div>
  );
}

function SignUpFormCont({ children, title, labelStr = "" }) {
  return (
    <div className="adminSignupFormCont">
      <img
        src="/assests/img/logoSvgMarktine.svg"
        alt="logo"
        className="adminSignupFormContLogo"
      />
      {/* <h1 className="adminSignupFormContHead1">{title}</h1> */}
      <h2 className="adminSignupFormContHead2">
        Welcome to GPT Tools
      </h2>
      <p className="adminSignupFormContPara">
        {labelStr || "Enter Your Organization Details"}
      </p>
      <div className="adminSignupFormWrppr">{children}</div>
    </div>
  );
}

function OrgFormSignupComp() {
  const [signUpVal, setSignUpVal] = useState({
    email: "",
    password: "",
    confirm_password: "",
    mobile: "",
    organization: "",
  });
  const [errVal, setErrVal] = useState({
    email: "",
    password: "",
    confirm_password: "",
    mobile: "",
    organization: "",
  });
  function handleFormValues(e) {
    let name = e.target.name,
      value = e.target.value;

    setSignUpVal({ ...signUpVal, [`${name}`]: value });
  }

  async function handleSubmitForm(e) {
    e.preventDefault();
    console.log("submitting form values:...", signUpVal);
    // validation ....
    let errObj = {};
    if (validateEmail(signUpVal["email"]))
      errObj.email = validateEmail(signUpVal["email"]);
    if (validateMobile(signUpVal["mobile"]))
      errObj.mobile = validateMobile(signUpVal["mobile"]);
    if (!signUpVal.organization)
      errObj.organization = "organization name is required!";
    if (!signUpVal.password) errObj.password = "password is required!";
    if (!signUpVal.confirm_password)
      errObj.confirm_password = "confirm_password is required!";

    setErrVal({ ...errObj });
    if (Object.keys(errObj).length !== 0) return;
    // validation ....
    let resp = await signUpAdminPanel(signUpVal);
    console.log("getting response from backend...", resp);
    if (resp.success) {
      setSignUpVal({
        organization: "",
        email: "",
        mobile: "",
        password: "",
        confirm_password: "",
      });
    }
  }

  return (
    <>
      <form action="" className="adminSignupFormElem">
        <FormInp
          title={"Organization Name"}
          nameStr={"organization"}
          error={errVal.organization}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your organization name"}
          value={signUpVal["organization"]}
        />
        <FormInp
          title={"Email address"}
          nameStr={"email"}
          error={errVal.email}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your email address"}
          value={signUpVal["email"]}
        />
        <FormInp
          title={"Mobile Number"}
          nameStr={"mobile"}
          error={errVal.mobile}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your mobile number"}
          value={signUpVal["mobile"]}
        />

        {/* <div className="aSFPsswrdFieldWrppr">
          <div
            className="aSFPsswrdFieldSubWrppr"
            style={{ marginRight: "10px" }}
          > */}
        <FormPassInp
          title={"Password"}
          nameStr={"password"}
          error={errVal.password}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your password"}
          typ={"password"}
          value={signUpVal["password"]}
        />
        {/* </div>
          <div className="aSFPsswrdFieldSubWrppr"> */}
        <FormPassInp
          title={"Confirm Password"}
          nameStr={"confirm_password"}
          error={errVal.confirm_password}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Re-enter your password"}
          typ={"password"}
          value={signUpVal["confirm_password"]}
        />
        {/* </div> */}
        {/* </div> */}
        <button className="adminSignupFormRegBtn" onClick={handleSubmitForm}>
          Register
        </button>
        <span
          className="linkToSignUp loginLinkOrgForm"
          onClick={() => reDirectTo("/login")}
        >
          Already have an account? Log in
        </span>
      </form>
    </>
  );
}

function UserFormSignupComp() {
  const [signUpVal, setSignUpVal] = useState({});

  function handleFormValues(e) {
    let name = e.target.name,
      value = e.target.value;

    setSignUpVal({ ...signUpVal, [`${name}`]: value });
  }

  function handleSubmitForm(e) {
    e.preventDefault();
    console.log("submitting form values:...", signUpVal);
    let resp = signUpUserPanel(signUpVal);
    console.log("getting response from backend...", resp);
  }

  return (
    <>
      <form action="" className="adminSignupFormElem">
        <div className="aSFPsswrdFieldWrppr">
          <div
            className="aSFPsswrdFieldSubWrppr"
            style={{ marginRight: "10px" }}
          >
            <FormInp
              title={"First Name"}
              nameStr={"firstName"}
              error={""}
              handlChange={(e) => {
                handleFormValues(e);
              }}
              placeHldr={"Enter First Name"}
            />
          </div>
          <div className="aSFPsswrdFieldSubWrppr">
            <FormInp
              title={"Last Name"}
              nameStr={"lastName"}
              error={""}
              handlChange={(e) => {
                handleFormValues(e);
              }}
              placeHldr={"Enter Last Name"}
            />
          </div>
        </div>
        <FormInp
          title={"Organization Name"}
          nameStr={"Organization"}
          error={""}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your organization name"}
        />
        <FormInp
          title={"Email address"}
          nameStr={"Email"}
          error={""}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your email address"}
        />
        <FormInp
          title={"Mobile Number"}
          nameStr={"Mobile"}
          error={""}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your mobile number"}
        />

        <div className="aSFPsswrdFieldWrppr">
          <div
            className="aSFPsswrdFieldSubWrppr"
            style={{ marginRight: "10px" }}
          >
            <FormInp
              title={"Password"}
              nameStr={"Password"}
              error={""}
              handlChange={(e) => {
                handleFormValues(e);
              }}
              placeHldr={"Enter your password"}
              typ={"password"}
            />
          </div>
          <div className="aSFPsswrdFieldSubWrppr">
            <FormInp
              title={"Confirm Password"}
              nameStr={"ConfirmPassword"}
              error={""}
              handlChange={(e) => {
                handleFormValues(e);
              }}
              placeHldr={"Re-enter your password"}
              typ={"password"}
            />
          </div>
        </div>
        <button className="adminSignupFormRegBtn" onClick={handleSubmitForm}>
          Register
        </button>
      </form>
    </>
  );
}

function UserLoginForm() {
  const [signUpVal, setSignUpVal] = useState({});
  const [errVal, setErrVal] = useState({});

  function handleFormValues(e) {
    let name = e.target.name,
      value = e.target.value;

    setSignUpVal({ ...signUpVal, [`${name}`]: value });
  }

  async function handleSubmitForm(e) {
    e.preventDefault();
    // console.log("submitting form values:...", signUpVal);
    // validation...
    let errorObj = {};
    if (validateEmail(signUpVal["email"])) {
      errorObj.email = validateEmail(signUpVal["email"]);
    }
    if (!signUpVal["password"]) errorObj.password = "password is required.";
    setErrVal({ ...errorObj });
    if (Object.keys(errorObj).length !== 0) return;
    // validation...
    let resp = await loginAdminPanel(signUpVal);
    console.log("getting response from backend User login form ...", resp);
    if (resp.success) {
      setSignUpVal({
        email: "",
        password: "",
      });
    }
    let usrTyp = resp?.payload?.data?.user_type,
      isReset = resp?.payload?.data?.password_reset;
    // return
    if (usrTyp === "admin") {
      console.log("user is :   ", usrTyp);
      reDirectTo("/admin");
    } else if (usrTyp === "user") {
      console.log("user is    ", usrTyp);
      console.log("login api resp in comp...", resp);

      if (isReset) {
        reDirectTo("/");
      } else {
        reDirectTo("/reset_password");
      }
    }
  }

  return (
    <>
      <form action="" className="adminSignupFormElem">
        <FormInp
          title={"Email address"}
          nameStr={"email"}
          error={errVal.email}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your email address"}
          value={signUpVal["email"]}
        />

        <FormPassInp
          title={"Password"}
          nameStr={"password"}
          error={errVal.password}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your password"}
          typ={"password"}
          value={signUpVal["password"]}
        />

        <button className="adminSignupFormRegBtn" onClick={handleSubmitForm}>
          Login
        </button>
        <span
          className="linkToSignUp"
          onClick={() => reDirectTo("/admin/signUp")}
        >
          New here? Register
        </span>
        <span
          className="linkToResetPass"
          onClick={() => reDirectTo("/forgot_password")}
        >
          Forgot Password?
        </span>
      </form>
    </>
  );
}

function FrgtPsswrdVrfyUsrForm() {
  const [signUpVal, setSignUpVal] = useState({});
  const [errVal, setErrVal] = useState({});
  const { token } = useParams();

  function handleFormValues(e) {
    let name = e.target.name,
      value = e.target.value;

    setSignUpVal({ ...signUpVal, [`${name}`]: value });
  }

  async function handleSubmitForm(e) {
    e.preventDefault();
    console.log("submitting form values:...", signUpVal);
    // validation...
    let errorObj = {};

    if (!signUpVal["password"]) errorObj.password = "password is required.";
    if (!signUpVal["confirm_password"])
      errorObj.confirm_password = "confirm password is required.";
    setErrVal({ ...errorObj });
    if (Object.keys(errorObj).length !== 0) return;
    // validation...
    let resp = await frgtPssVryfUsrAdminPanel(signUpVal, token); // replcae with forgot password api....
    console.log("getting response from backend User login form ...", resp);
    if (resp.success) {
      setSignUpVal({
        email: "",
        password: "",
      });
      toast.success(resp?.payload?.message);

      let x = setTimeout(() => {
        console.log("inside set time out...");
        reDirectTo("/login");
        clearTimeout(x);
      }, 1200);
    } else {
      toast.error(resp?.payload?.message);
      setErrVal({ ...errVal, ["confirm_password"]: resp?.payload?.message });
    }
  }
  return (
    <>
      <form action="" className="adminSignupFormElem">
        <FormInp
          title={"Password"}
          nameStr={"password"}
          error={errVal.password}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your password"}
          typ={"password"}
          value={signUpVal["password"]}
        />

        <FormInp
          title={"Confirm Password"}
          nameStr={"confirm_password"}
          error={errVal.confirm_password}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter confirm password"}
          typ={"password"}
          value={signUpVal["confirm_password"]}
        />

        <button className="adminSignupFormRegBtn" onClick={handleSubmitForm}>
          Reset
        </button>
        <p className="linkToSignUp resetPasswordLinkRPLAS" onClick={() => reDirectTo("/login")}>
          Login
        </p>
      </form>
    </>
  );
}

function ForgotPasswordForm() {
  const [signUpVal, setSignUpVal] = useState({});
  const [errVal, setErrVal] = useState({});

  function handleFormValues(e) {
    let name = e.target.name,
      value = e.target.value;

    setSignUpVal({ ...signUpVal, [`${name}`]: value });
  }

  async function handleSubmitForm(e) {
    e.preventDefault();
    console.log("submitting form values:...", signUpVal);
    // validation...
    // let errorObj = {};
    if (validateEmail(signUpVal["email"])) {
      let errMail = validateEmail(signUpVal["email"]);
      setErrVal({ ...errVal, ["email"]: errMail });
    }
    // if (!signUpVal["password"]) errorObj.password = "password is required.";
    // if (!signUpVal["confirm_password"])
    //   errorObj.confirm_password = "confirm password is required.";
    // setErrVal({ ...errorObj });
    if (Object.keys(errVal).length !== 0) return;
    // validation...
    let resp = await forgotPassAdminPanel(signUpVal); // replcae with forgot password api....
    console.log("getting response from backend User login form ...", resp);
    if (resp?.success) {
      setSignUpVal({
        email: "",
        password: "",
      });
      toast.success(resp?.payload?.message);
    }
  }

  return (
    <>
      <form action="" className="adminSignupFormElem">
        <FormInp
          title={"Email address"}
          nameStr={"email"}
          error={errVal.email}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your email address"}
          value={signUpVal["email"]}
        />

        <button className="adminSignupFormRegBtn" onClick={handleSubmitForm}>
          Send
        </button>
        {/* <p className="linkToSignUp" onClick={() => reDirectTo("/admin/signUp")}>
        New here? Register
        </p> */}
        <p
          className="linkToResetPass loginLinkNCUI"
          onClick={() => reDirectTo("/login")}
        >
          Login
        </p>
      </form>
    </>
  );
}

function ResetPasswordForm() {
  const [signUpVal, setSignUpVal] = useState({});
  const [errVal, setErrVal] = useState({});

  function handleFormValues(e) {
    let name = e.target.name,
      value = e.target.value;

    setSignUpVal({ ...signUpVal, [`${name}`]: value });
  }

  async function handleSubmitForm(e) {
    e.preventDefault();
    console.log("submitting form values:...", signUpVal);
    // validation...
    let errorObj = {};
    // if (validateEmail(signUpVal["email"])) {
    //   errorObj.email = validateEmail(signUpVal["email"]);
    // }
    if (!signUpVal["password"]) errorObj.password = "password is required.";
    if (!signUpVal["confirm_password"])
      errorObj.confirm_password = "confirm password is required.";
    setErrVal({ ...errorObj });
    if (Object.keys(errorObj).length !== 0) return;
    // validation...
    let resp = await resetUserPassword(signUpVal); // replcae with forgot password api....
    console.log("getting response from backend User login form ...", resp);
    if (resp.success) {
      setSignUpVal({
        email: "",
        password: "",
      });
    }
    let usrTyp = resp?.payload?.data?.user_type,
      statusResp = resp?.payload?.status;
    // return
    if (statusResp) {
      console.log("user is :   ", usrTyp);
      toast.success("Password Reset successfully!..");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1500);
    } else {
      toast.error(resp?.payload?.message);
      setErrVal({ ...errVal, ["confirm_password"]: resp?.payload?.message });
    }
  }

  return (
    <>
      <form action="" className="adminSignupFormElem">
        {/* <FormInp
          title={"Email address"}
          nameStr={"email"}
          error={errVal.email}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your email address"}
          value={signUpVal["email"]}
        /> */}

        <FormPassInp
          title={"Password"}
          nameStr={"password"}
          error={errVal.password}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter your password"}
          typ={"password"}
          value={signUpVal["password"]}
        />

        <FormPassInp
          title={"Confirm Password"}
          nameStr={"confirm_password"}
          error={errVal.confirm_password}
          handlChange={(e) => {
            handleFormValues(e);
          }}
          placeHldr={"Enter confirm password"}
          typ={"password"}
          value={signUpVal["confirm_password"]}
        />

        <button className="adminSignupFormRegBtn" onClick={handleSubmitForm}>
          Reset
        </button>
        {/* <p className="linkToSignUp" onClick={() => reDirectTo("/admin/signUp")}>
          new here ?, Sign Up
        </p> */}
      </form>
    </>
  );
}

export function FormInp({
  title,
  error,
  nameStr,
  handlChange,
  placeHldr,
  typ = "text",
  value = "",
  isDisable = false,
}) {
  return (
    <div className="adminSignupFormInpWrppr">
      <p className="adminSignupFormInpLable">{title}</p>
      <div className="adminSignupFormInpElemWrppr">
        <input
          type={typ}
          className="adminSignupFormInpElem"
          name={nameStr}
          onChange={(e) => handlChange(e)}
          placeholder={placeHldr}
          value={value}
          disabled={isDisable}
        />
      </div>
      {error && <p className="adminSignupFormInpErrLable">{error}</p>}
    </div>
  );
}

export function FormPassInp({ title, error, nameStr, handlChange, placeHldr }) {
  const [visiblty, setVisiblty] = useState(false);
  return (
    <div className="adminSignupFormInpWrppr">
      <p className="adminSignupFormInpLable">{title}</p>
      <div className="adminSignupFormPassInpWrppr">
        <input
          type={visiblty ? "text" : "password"}
          className="adminSignupFormInpElem"
          name={nameStr}
          onChange={(e) => handlChange(e)}
          placeholder={placeHldr}
        />
        {/* <img
          src="./assets/img/visibilityIcon.svg"
          className="adminSignupFormVisbIcon"
          alt="eye"
          onClick={() => setVisiblty(!visiblty)}
        /> */}
        <div
          className="adminSignupFormVisbIcon"
          onClick={() => setVisiblty(!visiblty)}
        >
          {visiblty ? <AiFillEyeInvisible /> : <AiFillEye />}
        </div>
      </div>
      {error && <p className="adminSignupFormInpErrLable">{error}</p>}
    </div>
  );
}

export function FormSelectInp({
  title,
  error,
  nameStr,
  handlChange,
  placeHldr,
  idStr = "",
  optionList = [],
  selProdList = [],
}) {
  // const [selOptions, setSelOptions] = useState([]);

  function handleSelectedOptions(value) {
    console.log("getting value in select arr list------->", value);
    console.log("getting in select arr product list------->", selProdList);
    if (selProdList?.some((x) => x?.id === value?.id)) {
      let newArr = selProdList?.filter((e) => value?.id !== e?.id);
      handlChange(newArr);
      // setSelOptions([...newArr]);
    } else {
      handlChange([...selProdList, value]);
      // setSelOptions([...selOptions, value]);
    }
  }

  function handleRemoveSelOpt(value) {
    let newArr = selProdList?.filter((e) => value?.id !== e?.id);
    handlChange(newArr);
    // setSelOptions([...newArr]);
  }
  return (
    <div className="adminSignupFormInpWrppr">
      <p className="adminSignupFormInpLable">{title}</p>
      <div className="adminSignupFormInpElemWrppr adminSignupFormSelectElemWrppr">
        <CustomSelectDropDown
          optList={optionList}
          handleSelOptions={(v) => {
            handleSelectedOptions(v);
          }}
          multi={true}
          selectedArrList={selProdList}
        />
        {selProdList?.length > 0 && (
          <div className="custSelectedProdLableListWrppr">
            {selProdList?.map((e) => (
              <CustomSelProdLabel
                title={e?.name}
                handleRemSelOpt={() => {
                  handleRemoveSelOpt(e);
                }}
              />
            ))}
          </div>
        )}
      </div>
      {error && <p className="adminSignupFormInpErrLable">{error}</p>}
    </div>
  );
}

export function CustomSelectDropDown({
  optList = [],
  handleSelOptions,
  multi = false,
  selectedArrList = [],
}) {
  const [drpDwnMenu, setDrpDwnMenu] = useState(false);

  const selDrpDwnCont = useRef(null);

  console.log("selected arr list -------->", selectedArrList);

  function handleSelect(value) {
    // console.log("selected value ---->", value);
    handleSelOptions(value);
    !multi && setDrpDwnMenu(false);
  }

  function handleClickOutSide(e) {
    let target = e.target;
    if (selDrpDwnCont?.current && !selDrpDwnCont?.current.contains(target)) {
      // console.log("clicked outside ------->");
      setDrpDwnMenu(false);
    }
  }

  function handleClickSelectBtn(e) {
    e.preventDefault();
    // console.log("button clicked------->");
    if (!(optList?.length > 0)) {
      toast.warn("no list to select from");
      return;
    }
    setDrpDwnMenu(!drpDwnMenu);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);


    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, []);

  return (
    <>
      <div className="customSelDrpDwnWrppr" ref={selDrpDwnCont}>
        <button className="customSelDrpDwnBtn" onClick={handleClickSelectBtn}>
          Select Products <AiFillCaretDown />
        </button>
        {drpDwnMenu && optList?.length > 0 ? (
          <ul className={"customSelDrpDwnMenu"}>
            {optList?.map((e, i) => {
              return (
                <li
                  className="customSelDrpDwnOpt"
                  onClick={() => handleSelect(e)}
                  key={`customSelDrpDwnOptDNDK${i}`}
                >
                  {e?.name}
                  {/* {selectedArrList?.some(x=>x?.id === e?.id) ? (
                    <AiFillMinusCircle color="#00AFEF" />
                  ) : (
                    <AiFillPlusCircle color="#00AFEF" />
                  )} */}
                  {multi && (
                    <Checkbox
                      checked={selectedArrList?.some((x) => x?.id === e?.id)}
                      sx={{
                        color: "#00AFEF",
                        "&.Mui-checked": { color: "#00AFEF" },
                      }}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export function CustomSelProdLabel({
  title,
  handleRemSelOpt,
  onlyLabel = false,
}) {
  function handleClose(e) {
    e.preventDefault();
    console.log("remove this product...", title);
    handleRemSelOpt();
  }
  return (
    <button className="customSelProdLabel">
      {title}{" "}
      {!onlyLabel && (
        <AiOutlineClose
          onClick={handleClose}
          className="closeBtnCustomSelProdLabel"
        />
      )}
    </button>
  );
}
