import { useState, useEffect, useContext } from "react";
import { getProfile } from "../../actions/action";
import Cookies from "js-cookie";
import { reDirectTo } from "../utility/functions/helperFunc";
import LoadingComponent from "../loadingSpinner/loadingSpinner";
import { MyContext } from "../../context/context";

const ADMIN_COOKIE = "MRKTNADMNACCTKN";
const USER_COOKIE = "MRKTNUSERACCTKN";

function ProtectedRoute({ children, status = "", redirection = "" }) {
  const [renStatus, setRenStatus] = useState("loading");
  const { updateUserDetails } = useContext(MyContext);
  useEffect(() => {
    checkProfile();
  }, []);

  async function checkProfile() {
    try {
      let myCookie = "";

      if (status === "admin") {
        // myCookie = Cookies.get(ADMIN_COOKIE);
        myCookie = localStorage.getItem(ADMIN_COOKIE);
        console.log("cookies get response admin", myCookie);
      }
      if (status === "user") {
        // myCookie = Cookies.get(USER_COOKIE);
        myCookie = localStorage.getItem(USER_COOKIE);
        console.log("cookies get response user", myCookie);
      }
      if (!myCookie) {
        reDirectTo(redirection);
        return;
      }
      let myResp = await getProfile(myCookie);
      if (myResp.success) {
        const usrData = myResp?.payload;
        updateUserDetails({ ...usrData });
        setRenStatus("success");
      } else {
        setRenStatus("failed");
      }
      console.log("protected route profile response:===>", myResp);
    } catch (error) {
      setRenStatus("failed");
      console.log("error while checking admin", error);
    }
  }

  if (renStatus === "loading")
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingComponent
          styl={{
            position: "relative",
            top: "0px",
            bottom: "0px",
            right: "0px",
          }}
        />
      </div>
    );
  if (renStatus === "success") return <div>{children}</div>;
  if (renStatus === "failed") {
    console.log("ren status", renStatus);

    reDirectTo(redirection);
    return null;
  }
}

export default ProtectedRoute;
