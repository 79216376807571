import "./button.css";

export function FileSyncButton({
  title='',
  src,
  bkg = "makeBlueBtn",
  handleClick,
  disabled,
  stylObj=false,
  children,
}) {
  return (
    <>
      <button
        className={`fileSyncBtnWrppr ${bkg}`}
        onClick={handleClick}
        disabled={disabled}
        style={{...stylObj}}
      >
        {children ? (
          children
        ) : (
          src&&<img className="fileSyncBtnIcon" src={"/assests/img/" + src} alt="" />
        )}

        <p className={!stylObj?"fileSyncBtnTitle":""}>{title}</p>
      </button>
    </>
  );
}

export function AdminPanelBtn({
  title,
  src,
  handleClick,
  bgCol = "#0574EA",
  stylObj = {},
  iconStyl = {},
  disabled,
}) {
  return (
    <>
      <button
        className={`adminPromptBtnWrppr`}
        onClick={handleClick}
        style={{ ...stylObj, background: bgCol }}
        disabled={disabled}
      >
        <img
          className="adminPromptBtnIcon"
          src={"/assests/img/" + src}
          alt="icon"
          style={{ ...iconStyl }}
        />
        <p className="adminPromptBtnTitle">{title}</p>
      </button>
    </>
  );
}