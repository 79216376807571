import { useEffect,useState,useRef } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";




import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import LoadingComponent from "../loadingSpinner/loadingSpinner";
import { BsFillMicFill } from "react-icons/bs";
import Wave_Anim from "../utility/wave_animation/wave_anim";

import "./siri.css";

const Dictaphone = () => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();


const [stopRec,setStopRec] = useState(false);

  useEffect(() => {
    console.log("transcript value ------>", transcript);

    if (transcript && stopRec) {
      console.log("got the transcript file----->");
    }
  }, [transcript]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  function startListening() {
    SpeechRecognition.startListening({continuous:true});
    setStopRec(false);
  }

  function stopListening() {
    SpeechRecognition.stopListening();
    resetTranscript();
    setStopRec(true);
  }

  function resetTranscriptFunc() {
    resetTranscript();
  }

  return (
    <div>
      <p>Microphone: {listening ? "on" : "off"}</p>
      <button onClick={startListening}>Start</button>
      <button onClick={stopListening}>Stop</button>
      <button onClick={resetTranscriptFunc}>Reset</button>
      <p>{transcript}</p>
    </div>
  );
};
export default Dictaphone;




// *************************** WEB RTC WEB AUDIO API *******************

export const SpeechRecognitionComponent = () => {
  const [transcript, setTranscript] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  let recognition;

  useEffect(() => {
    recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.lang = 'en-US';

    recognition.onstart = () => {
      console.log('Speech recognition started');
    };

    recognition.onresult = (event) => {
      const lastResult = event.results[event.results.length - 1];
      const { transcript: newTranscript } = lastResult[0];

      console.log('Received transcript:', newTranscript);

      // Handle automatic split logic
      if (newTranscript.trim() !== '') {
        setIsLoading(true);

        // Perform any action with the transcript (e.g., send it to a server)

        setIsLoading(false);
      }

      // Update the state with the new transcript
      setTranscript((prevTranscript) => prevTranscript + newTranscript + ' ');
    };

    recognition.onerror = (event) => {
      console.error('Speech recognition error:', event.error);
    };

    recognition.onend = () => {
      console.log('Speech recognition ended');
    };

    // Start continuous speech recognition
    recognition.start();

    // Cleanup
    return () => {
      if (recognition) {
        recognition.stop();
      }
    };
  }, []);

  return (
    <div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <p>Transcript: {transcript}</p>
        </div>
      )}
    </div>
  );
};


// *************************** WEB RTC WEB AUDIO API *******************




// *************************** MICROSOFT COGNITIVE SERVICES SDK *******************

const SPEECH_KEY = '2bbff2b83f3f4b26870aca03da6db304';
const SPEECH_REGION = 'eastus';

export function SpeechToTextComponentMicroSoftAzure(
  {
    isDisable = false,
  startListenToUser,
  stopListenToUser,
  updateRecMicStatus
  }
) {

  const [isListening, setIsListening] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const speechConfig = useRef(null);
  const audioConfig = useRef(null);
  const recognizer = useRef(null);

  // const [myTranscript, setMyTranscript] = useState("");
  // const [recognizingTranscript, setRecTranscript] = useState("");
  const [messageStr,setMessageStr] = useState('');
  let initiation = 0;

  // const getMicrophonePermission = async () => {
  //   if ("MediaRecorder" in window) {
  //     try {
  //       const streamData = await navigator.mediaDevices.getUserMedia({
  //         audio: true,
  //         video: false,
  //       });
  //       setPermission(true);
  //       // console.log("Stream Data -------> ", streamData);
  //       setStream(streamData);
  //     } catch (err) {
  //       toast.error(err.message);
  //     }
  //   } else {
  //     toast.info("The MediaRecorder API is not supported in your browser.");
  //   }
  // };


  useEffect(() => {
    
    speechConfig.current = sdk.SpeechConfig.fromSubscription(
      SPEECH_KEY,
      SPEECH_REGION
    );
    speechConfig.current.speechRecognitionLanguage = 'en-US';

    audioConfig.current = sdk.AudioConfig.fromDefaultMicrophoneInput();
    recognizer.current = new sdk.SpeechRecognizer(
      speechConfig.current,
      audioConfig.current
    );

    const processRecognizedTranscript = (event) => {
      const result = event.result;
      // console.log('Recognition result:', result);

      if (result.reason === sdk.ResultReason.RecognizedSpeech) {
        const transcript = result.text;
        console.log('Transcript: -->', transcript);
        // Call a function to process the transcript as needed
        // prevMessageString = prevMessageString + transcript;
        setMessageStr((prev)=>{
          console.log("current payload to send -------->",prev+transcript);
          return  prev+transcript;
        });
        // setMyTranscript(transcript);
      }
    };

    const processRecognizingTranscript = (event) =>{
        const result = event.result;
        // console.log('Recognition result:', result);
        if (result.reason === sdk.ResultReason.RecognizingSpeech) {
            const transcript = result.text;
            console.log('Transcript: -->', transcript);
            // Call a function to process the transcript as needed
    
            // setRecTranscript(transcript);
        }
    }

    recognizer.current.recognized = (s, e) => processRecognizedTranscript(e);
    recognizer.current.recognizing = (s, e) => processRecognizingTranscript(e);

    // console.log("need calling startContinuous Recognition ----->",manualSwitch);
    // console.log("inside manual switch condition ----->");

      recognizer.current.startContinuousRecognitionAsync(() => {
        console.log('Speech recognition started.');
        
        setIsListening(true);
        
      });
    
      

    return () => {
      recognizer.current.stopContinuousRecognitionAsync(() => {
        setIsListening(false);
      });
    };
  }, []);

  //  ************************* Interactive Functions *************************

  const pauseListening = () => {
    setIsListening(false);
    recognizer.current.stopContinuousRecognitionAsync();
    console.log('Paused listening.');
  };

  const resumeListening = () => {
    console.log("Continuing listening process------->",isListening);
    // if (!isListening) {
      console.log("inside resume listening conditional ------>",isListening);
      recognizer.current.startContinuousRecognitionAsync(() => {
        console.log('Resumed listening...');
        setIsListening(true);
      });
    // }
  };

  const stopListening = () => {
    setIsListening(false);
    recognizer.current.stopContinuousRecognitionAsync(() => {
      console.log('Speech recognition stopped.');
    });
  };

  function handleSwitchBtnManually(){
    
    if(isListening){
      stopListening();
      updateRecMicStatus(false);
    }else{
      // if(initiation){
        resumeListening();
      // }else{
      //   InitializeMicroSoftAzureSpeechRecog();
      //   initiation = 1;
      // }
      startListenToUser();
      updateRecMicStatus(true);

    }
  }

  //  ************************* Interactive Functions *************************
 
  useEffect(()=>{
    console.log("checking isListening autoRecComp--->",isListening)
    if(!isListening)return;
    let x = setTimeout(()=>{
      if(!messageStr)return;
      // console.log("my message string: ------------>",messageStr);
      handleAutomaticSplit();
      setMessageStr('');
    },[4000]);


    async function handleAutomaticSplit (){
      
        console.log("User stopped speaking. ----------->");
        setIsLoading(true);
        
        pauseListening();
        let resp = await stopListenToUser();
        console.log("response from stop istening ---->",resp);
        
        setIsLoading(false);
        console.log("waited for opertions...");
        // Perform any action with the transcript (e.g., send it to a server)

      // Reset the transcript for the next speech segment

      // Start listening again
      console.log("start listening again to user ----->");
      startListenToUser();
      resumeListening();
      
    };

    return ()=>{clearTimeout(x)};
  },[messageStr,isListening])

  return (
    // <div>
    //   <button onClick={pauseListening}>Pause Listening</button>
    //   <button onClick={resumeListening}>Resume Listening</button>
    //   <button onClick={stopListening}>Stop Listening</button>

    //   <div>
    //     <div>
    //         Recognizing Transcript : {recognizingTranscript}
    //     </div>

    //     <div>
    //         RecognizedTranscript : {myTranscript}
    //     </div>
    //   </div>
    // </div>

    <>
    {isLoading ? (
        <div className="newrecCompSiriLangTut">
          <LoadingComponent
            size={15}
            styl={{ position: "relative", right: "0px", bottom: "0px" }}
          />
        </div>
      ) : (
        <button
          className={`${isListening ? "active_siri" : ""} newRecCompSiriLangTut`}
          disabled={isDisable}
          onClick={handleSwitchBtnManually}
        >
          <BsFillMicFill size={"18px"} />
          <Wave_Anim switchVar={isListening} />
        </button>
      )}
    </>
  );
};



export function SpeechToTextComponent() {

  const [isListening, setIsListening] = useState(false);
  const speechConfig = useRef(null);
  const audioConfig = useRef(null);
  const recognizer = useRef(null);

  const [myTranscript, setMyTranscript] = useState("");
  const [recognizingTranscript, setRecTranscript] = useState("");

  useEffect(() => {
    speechConfig.current = sdk.SpeechConfig.fromSubscription(
      SPEECH_KEY,
      SPEECH_REGION
    );
    speechConfig.current.speechRecognitionLanguage = 'en-US';

    audioConfig.current = sdk.AudioConfig.fromDefaultMicrophoneInput();
    recognizer.current = new sdk.SpeechRecognizer(
      speechConfig.current,
      audioConfig.current
    );

    const processRecognizedTranscript = (event) => {
      const result = event.result;
      console.log('Recognition result:', result);

      if (result.reason === sdk.ResultReason.RecognizedSpeech) {
        const transcript = result.text;
        console.log('Transcript: -->', transcript);
        // Call a function to process the transcript as needed

        setMyTranscript(transcript);
      }
    };

    const processRecognizingTranscript = (event) =>{
        const result = event.result;
        console.log('Recognition result:', result);
        if (result.reason === sdk.ResultReason.RecognizingSpeech) {
            const transcript = result.text;
            console.log('Transcript: -->', transcript);
            // Call a function to process the transcript as needed
    
            setRecTranscript(transcript);
        }
    }

    recognizer.current.recognized = (s, e) => processRecognizedTranscript(e);
    recognizer.current.recognizing = (s, e) => processRecognizingTranscript(e);


    recognizer.current.startContinuousRecognitionAsync(() => {
      console.log('Speech recognition started.');
      setIsListening(true);
    });

    return () => {
      recognizer.current.stopContinuousRecognitionAsync(() => {
        setIsListening(false);
      });
    };
  }, []);

  const pauseListening = () => {
    setIsListening(false);
    recognizer.current.stopContinuousRecognitionAsync();
    console.log('Paused listening.');
  };

  const resumeListening = () => {
    if (!isListening) {
      setIsListening(true);
      recognizer.current.startContinuousRecognitionAsync(() => {
        console.log('Resumed listening...');
      });
    }
  };

  const stopListening = () => {
    setIsListening(false);
    recognizer.current.stopContinuousRecognitionAsync(() => {
      console.log('Speech recognition stopped.');
    });
  };

  return (
    <div>
      <button onClick={pauseListening}>Pause Listening</button>
      <button onClick={resumeListening}>Resume Listening</button>
      <button onClick={stopListening}>Stop Listening</button>

      <div>
        <div>
            Recognizing Transcript : {recognizingTranscript}
        </div>

        <div>
            RecognizedTranscript : {myTranscript}
        </div>
      </div>
    </div>
  );
};

// *************************** MICROSOFT COGNITIVE SERVICES SDK *******************