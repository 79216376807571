import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminSignUp, {
  OrgLogin,
  ResetPasswrdScreen,
  ForgotPsswrdScrn,
  FrgtPssVerifyUsrScrn,
} from "./components/adminPanel/adminSignup/adminSignUp";
import {
  AdminAddUserPR,
  HeadingContAdminRoutesPR,
  // HeadingContRoutesPR,
  // AdminAppRoutesPR,
  // AdminAdvSearchRoutesPR,
  // PPTMakerRoutesPR,
  // EngTutorRoutesPR,
  HomePageRoutesPR,
  HomePageRoutesAdminPR,
  ProductProtectionPR,
  ProductProtectionAdminPR,
} from "./components/Routes/protectionWrppr";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// import AudioRecorder from "./components/waveSurferDemo/waveSurfer"; // testing component , remove after testing...
// import RecordingCompEngTut1 from "./components/waveSurferDemo/waveSurface"; // testing component , remove after testing...
// import Dictaphone, {
//   SpeechRecognitionComponent,
//   SpeechToTextComponentMicroSoftAzure,
// } from "./components/waveSurferDemo/voiceToText"; // testing component , remove after testing...
// import SiriRecMicTestComp from "./components/waveSurferDemo/siri";
import InputPageFD from "./components/faceDetection/inputPage/inputPageFD";


import { MyContextProvider } from "./context/context.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useState } from "react";

function App() {
  return (
    <BrowserRouter>
      <MyContextProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePageRoutesPR />} />
            <Route path="/admin" element={<HomePageRoutesAdminPR />} />

            <Route
              path="/product/:productId"
              element={<ProductProtectionPR />}
            />
            <Route
              path="/admin/product/:productId"
              element={<ProductProtectionAdminPR />}
            />

            <Route path="/rules" element={<HeadingContAdminRoutesPR />} />
            <Route path="/admin/signUp" element={<AdminSignUp />} />
            <Route path="/admin/addUser" element={<AdminAddUserPR />} />
            <Route path="/login" element={<OrgLogin />} />
            <Route path="/reset_password" element={<ResetPasswrdScreen />} />
            <Route path="/forgot_password" element={<ForgotPsswrdScrn />} />
            <Route
              path="/verify_user/:token"
              element={<FrgtPssVerifyUsrScrn />}
            />
            {/*
            <Route path="/testing1" element={<SpeechToTextComponentMicroSoftAzure />} />
            <Route path="/siri" element={<SiriRecMicTestComp/>}/> 
          */}
            <Route path="/pdftest" element={<PdfViewer11 />} />
            {/* <Route path="/fdInputPage" element={<InputPageFD/>}/> */}
          </Routes>
          <ToastContainer />
        </div>
      </MyContextProvider>
    </BrowserRouter>
  );
}

export default App;

function PdfViewer11() {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.js`;
  const [pdfUrl, setPdfUrl] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type === "application/pdf") {
      console.log("pdf file format ----->");
      setPdfUrl(file);
    } else {
      console.error("Invalid file type. Please upload a PDF file.");
    }
  };

  return (
    <>
      <input type="file" accept=".pdf" onChange={handleFileChange} />
      <div>
        <Document
          file={pdfUrl}
          onLoadSuccess={() => console.log("document loaded successfully")}
          style={{ border: "1px solid red", width: "590px", height: "367px" }}
        >
          <Page pageNumber={1} />
        </Document>
      </div>
    </>
  );
}
