import "./activityArea.css";
import { useContext } from "react";
import { MyContext } from "../../context/context";
import UploadCont from "../uploadCont/uploadCont";
import DownloadCont from "../downloadCont/downloadCont";
import { sendUploadedData } from "../../actions/action";
import { FaArrowRight } from "react-icons/fa";

function ActivityArea() {
  const { updateResponse, updateLoadingState, wordLimitCont, files } =
    useContext(MyContext);

  async function handleSendRequest() {
    try {
      console.log("my selected files...", files, wordLimitCont);
      if (!files[0] || files.length > 1) {
        console.log("returning from sending request...");
        return;
      }
      const formData = new FormData();

      // Append each file to the FormData object
      formData.append("files", files[0]);
      formData.append("word_limit", wordLimitCont || 0);
      formData.append("doc_layout", "empty");
      formData.append("text_string", "");
      // Append each file to the FormData object

      updateLoadingState(true);
      let resp = await sendUploadedData(formData);
      updateLoadingState(false);
      updateResponse(resp);

      console.log("singlefile comp sending request to python..", resp);
      // setResponse(resp);
    } catch (error) {
      console.log("singlefile comp sending request to python..", error);
    }
  }

  return (
    <>
      <div className="activityWrapper">
        <UploadCont />
        {/* <img
          src="./assests/img/Arrow---Right-Square.png"
          className="arrowRightIcon"
          alt="move"
          onClick={() => {
            console.log("clicking arrow btn..");
            handleSendRequest();
          }}
        /> */}
        <div className="rightArrWrppr arrowRightIcon" onClick={() => {
            console.log("clicking arrow btn..");
            handleSendRequest();
          }}>
          <FaArrowRight size={"10px"} />
        </div>
        <DownloadCont />
      </div>
    </>
  );
}

export default ActivityArea;
