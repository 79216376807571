import React, { createContext, useState } from "react";
import {
  fetchPromptForAdmin,
  addPromptToAdmin,
  updatePromptInAdmin,
  deletePromptInAdmin,
  fetchCrtdUsers,
  generatePPtFile,
  voiceToTextPPt,
  fileToTextPPt,
} from "../actions/action.js";

const MyContext = createContext();

const MyContextProvider = ({ children }) => {
  // const [count, setCount] = useState(0);
  const [files, setFiles] = useState([]);
  const [text, setText] = useState("");
  const [wordLimitCont, setWordLimitCont] = useState("");
  const [primary, setPrimary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [crtdUsers, setCrtdUsers] = useState([]);
  const [hdrPg, setHdrPg] = useState("Rules");
  const [voiceResp, setVoiceResp] = useState({ text: [], audio: "" });
  const [searchConv, setSearchConv] = useState([]);
  const [srchByTextInp, setSrchByTextInp] = useState("");

  const [userDetails, setUserDetails] = useState(null);

  const [responseCont, setResponseCont] = useState("");

  // ***************** User Details Management *****************

  function handleUserDetails(v) {
    if (!v) {
      console.log("invalid user details");
      return;
    }
    setUserDetails({ ...v });
  }

  // ***************** User Details Management *****************

  // ***************** presentation maker states *****************
  // const [txtInpSecndPagePPTM,setTxtInpSecndPagePPTM] = useState({file:"",BG:""});

  const [pptContent, setPPTcontent] = useState({
    text: "",
    files: [],
    voiceInput: null,
  });
  const [pptBgDetails, setPPTBgDetails] = useState({
    text: "",
    files: [],
    voiceInput: null,
  });

  const [imageListToPrev, setImageListToPrev] = useState([]);

  // ***************** presentation maker states *****************

  // ***************** english tutor states *****************

  const [audioToPlay, setAudioToPlay] = useState("");
  const [userConversationEngTut, setUserConversationEngTut] = useState(null);
  const [playPauseRecAudio, setPlayPauseRecAudio] = useState("pause");

  const [isStartSessEngTut, setIsStartSessEngTut] = useState(null);
  const [isEmptyEngUsrChat, setIsEmptyEngUsrChat] = useState(false);
  const [isRecordPermission, setIsRecordPermission] = useState(false);
  const [isMediaStreamReady,setIsMediaStreamReady] = useState(false);

  // ***************** english tutor states *****************

  // ***************** ADMIN DATA *****************
  const [promptData, setPromptData] = useState([]);
  // ***************** ADMIN DATA *****************

  const updateLoadingState = (v) => {
    setIsLoading(v);
  };

  const updatePrimary = (p) => {
    setPrimary(p);
  };

  const updateFiles = (v) => {
    setFiles(v);
  };

  const updateWordLimit = (w) => {
    setWordLimitCont(w);
  };

  const updateText = (t) => {
    setText(t);
  };

  const updateResponse = (r) => {
    setResponseCont(r);
  };

  const updateHeaderState = (r) => {
    setHdrPg(r);
  };

  const updateVoiceResp = (r) => {
    setVoiceResp({ ...voiceResp, ...r });
  };

  const updateSrchConv = (r) => {
    // console.log("search conversation---->",r);
    setSearchConv([...searchConv, r]);
  };

  const resetSrchConv = () => {
    setSearchConv([]);
  };

  const upateSrchInpByText = (r) => {
    setSrchByTextInp(r); //srchByTextInp
  };

  // ****************** presentation maker functions ******************

  const updatePPTFileContent_Text = (v) => {
    console.log("context api ppt file cont text:---->", v);
    setPPTcontent({ ...pptContent, ["text"]: v });
  };

  const updatePPTFileContent_File = (v) => {
    console.log("context api ppt file cont file:---->", v);
    setPPTcontent({ ...pptContent, ["files"]: v });
  };

  const updatePPTFileContent_Voice = (v) => {
    console.log("context api ppt file cont voice:---->", v);
    setPPTcontent({ ...pptContent, ["voiceInput"]: v });
  };

  const updatePPTBGContent_Text = (v) => {
    setPPTBgDetails({ ...pptBgDetails, ["text"]: v });
  };

  const updatePPTBGContent_File = (v) => {
    setPPTBgDetails({ ...pptBgDetails, ["files"]: v });
  };

  const updatePPTBGContent_Voice = (v) => {
    setPPTcontent({ ...pptBgDetails, ["voiceInput"]: v });
  };

  const requestGeneratePPt = async (formData) => {
    const userType = userDetails?.user_type || "";
    let response = await generatePPtFile(formData, userType);
    console.log("generate ppt file rseponse in context...", response);
    return response;
  };

  const reqVoiceToText = async (formData) => {
    const userType = userDetails?.user_type || "";
    let response = await voiceToTextPPt(formData, userType);
    return response;
  };

  const reqFileToText = async (formData) => {
    const userType = userDetails?.user_type || "";
    let response = await fileToTextPPt(formData, userType);
    return response;
  };

  const updateImgListToPrev = (v) => {
    setImageListToPrev(v);
  };
  // ****************** presentation maker functions ******************

  // ***************** english tutor functions *****************

  function updateAudioToPlayWS(audioUrl = "") {
    if (audioUrl === "") return; // early return, no audio
    setAudioToPlay(audioUrl);
  }

  function updateAudioPlayingStateWS(audioState = "pause") {
    setPlayPauseRecAudio(audioState);
  }

  // function updateUserConversationEngTut(){

  // }

  function toggleSessionEngTut(isStartSess, isChatEmpty) {
    console.log("setting isStart property ===>", isStartSess);
    console.log("setting isChatEmpty property ===>", isChatEmpty);
    setIsStartSessEngTut(isStartSess);
    setIsEmptyEngUsrChat(isChatEmpty);
  }

  function handleIsStartSession(x) {
    setIsStartSessEngTut(x);
  }

  function handleRecPermission(x){
    setIsRecordPermission(x);
  }

  function handleMediaStream(x){
    setIsMediaStreamReady(x);
  }

  // ***************** english tutor functions *****************

  const contextValue = {
    updateFiles,
    updateText,
    updateResponse,
    updatePrimary,
    updateWordLimit,
    updateLoadingState,
    updateUserDetails: handleUserDetails,
    files,
    text,
    responseCont,
    wordLimitCont,
    primary,
    isLoading,
    userDetails,
    admin: {
      updateHeaderState,
      fetchPromptData,
      updatePromptData,
      deletePromptData,
      addPromptData,
      getCrtdUser,
      updateVoiceResp,
      updateSrchConv,
      resetSrchConv,
      upateSrchInpByText,
      promptData,
      crtdUsers,
      hdrPg,
      voiceResp,
      searchConv,
      srchByTextInp,
    },
    presentationMaker: {
      updatePPTFileContent_Text,
      updatePPTFileContent_File,
      updatePPTFileContent_Voice,
      updatePPTBGContent_Text,
      updatePPTBGContent_File,
      updatePPTBGContent_Voice,
      requestGeneratePPt,
      reqVoiceToText,
      reqFileToText,
      updateImgListToPrev,
      pptBgDetails,
      pptContent,
      imageListToPrev,
    },
    engTutor: {
      updateAudioToPlayWS,
      updateAudioPlayingStateWS,
      toggleSessionEngTut,
      handleIsStartSession,
      handleRecPermission,
      handleMediaStream,
      audioToPlay,
      playPauseRecAudio,
      isStartSessEngTut,
      isEmptyEngUsrChat,
      isRecordPermission,
      isMediaStreamReady
    },
  };

  // **************************************** ADMIN STATE HANDLERS ****************************************

  async function fetchPromptData() {
    let resp = await fetchPromptForAdmin();
    if (resp.success) {
      // let arr = sortArrayByNum(resp.payload);
      console.log("getting resp in contx...", resp);
      setPromptData([...resp?.payload]);
    }
  }

  async function updatePromptData(d = []) {
    let resp = await updatePromptInAdmin(d);
    console.log("my response update in context..", resp);
    if (resp.success) {
      console.log("succes true update..");
      // let arr = sortArrayByNum(resp.payload);
      setPromptData([...resp.payload]);
    }
  }

  async function addPromptData(d = {}) {
    let resp = await addPromptToAdmin(d);
    console.log("setting add promp resp in context...", resp);
    if (resp.success) {
      // let arr = sortArrayByNum(resp.payload);
      setPromptData([...resp.payload]);
    }
  }

  async function deletePromptData(d = []) {
    let resp = await deletePromptInAdmin(d);
    console.log("setting delete promp resp in context...", resp);
    // if (resp.success) {
    //   let arr = sortArrayByNum([...resp.payload]);
    setPromptData([]);
    let x = setTimeout(() => {
      if (resp.payload.length !== 0) {
        // toast.info("Updating prompt, please wait...");
        setPromptData([...resp.payload]);
        // toast.success("Prompt Updated...");
      }
      clearTimeout(x);
    }, [0]);
    // }
    return resp;
  }

  // function sortArrayByNum(arr){
  //   arr.sort((a, b) => a.ruleNo - b.ruleNo);
  //   return arr;
  // }

  async function getCrtdUser() {
    let resp = await fetchCrtdUsers();

    console.log("getting resposne crtd user in contx api....", resp);
    if (resp.success) {
      setCrtdUsers([...resp.payload]);
    }
  }

  // **************************************** ADMIN STATE HANDLERS ****************************************

  return (
    <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>
  );
};

export { MyContext, MyContextProvider };
