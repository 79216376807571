import { useState, useContext, useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import { MyContext } from "../../context/context";
import { FileSyncButton } from "../utility/button/button";

import { NavLink } from "react-router-dom";

import "./homePage.css";

function HomePage() {
  const [searchVal, setSearchVal] = useState("");
  const [allProdList, setAllProdList] = useState([]);

  const { userDetails } = useContext(MyContext);

  // console.log("user details home page -------->", userDetails);

  const ArrList = [1, 2, 3, 4, 5, 6, 7, 8];
  function handleSearchInput(e) {
    let value = e.target.value;

    let arr = userDetails?.product_access?.filter((x)=>{
     let name = x?.name?.toLowerCase() || "";
      return name.includes(value.toLowerCase());
    });
    // console.log("search filter list ---->",arr,allProdList);
    setAllProdList([...arr]);

    setSearchVal(value);
  }

  // useEffect(()=>{
  // },[searchVal]);
  //   let arr = allProdList?.filter((x)=>x?.name.includes(searchVal.toLowerCase()));
  //   console.log("search filter list ---->",arr);
    // setAllProdList([...arr]);

  useEffect(()=>{
    setAllProdList([...userDetails?.product_access]);
  },[])
  
  return (
    <div className="homePageMainContWrppr">
      <h1 className="homePageMainHead">Product Catalogue</h1>
      <p className="homePageSubHead">Here you can access all your products</p>
      <InputCompHomePage
        handleChange={handleSearchInput}
        searchValue={searchVal}
      />
      <div className="prodListWrpprHomePage">
        {allProdList?.map((x) => (
          <ProductCardHomePage
            metaData={{
              src: x?.logo || "/assests/img/docuSummLogoDummy.svg",  
              title: `${x?.name}`,
              description: `${x?.description}`,
              ...x,
            }}
          />
        ))}
      </div>
    </div>
  );
}

function InputCompHomePage({ handleChange, searchValue }) {
  return (
    <>
      <div className="inputWrpprHomePage">
        <input
          type="text"
          value={searchValue}
          onChange={handleChange}
          className="inputElemHomePage"
          placeholder="Search Your Product Here..."
        />
        <BiSearch className="inputCompHomePageSrchBtn" />
      </div>
    </>
  );
}

function ProductCardHomePage({ metaData }) {
  const { userDetails } = useContext(MyContext);

  let usrTyp = userDetails?.user_type,
    navUrl = "";

  if (usrTyp === "admin") {
    navUrl = "/admin/product/";
  } else if (usrTyp === "user") {
    navUrl = "/product/";
  }

  return (
    <div className="productCardMainWrpprHomePage">
      <div className="prodImgwrpprHomePage">
        <img src={metaData?.src || ""} alt="Logo" />
      </div>
      <p className="prodTitleHomePage">{metaData?.title}</p>
      <p className="prodDescripHomePage">{metaData?.description}</p>
      <NavLink to={`${navUrl+metaData?.id}`} className={"navlinkWrppr"}>
        <FileSyncButton title="Open" />
      </NavLink>
    </div>
  );
}

export default HomePage;
