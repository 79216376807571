import axios from "axios";
import { reDirectTo } from "../components/utility/functions/helperFunc";
import { toast } from "react-toastify";

const AdminBaseUrl = process.env.REACT_APP_AdminBaseUrl; //    REACT_APP_AdminBaseUrl_local    REACT_APP_AdminBaseUrl

// const AdminBaseUrl_local = process.env.REACT_APP_AdminBaseUrl_local;

export async function sendUploadedData(data) {
  try {
    console.log("getting data...", data);
    const url = "/process_file/";
    let accToken =
      localStorage.getItem("MRKTNADMNACCTKN") ||
      localStorage.getItem("MRKTNUSERACCTKN");
    if (!accToken)
      return { payload: "no token found, please login", success: false };
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    let response = await axios.post(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    let showIntErr = true;
    console.log("uploading file request error", error);
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    //"token_not_valid"
    if (error?.response?.data?.code === "token_not_valid") {
      toast.error("session timeout !");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
      showIntErr = false;
    } else {
      // let msg = "";
      // if (error?.message) {
      //   msg = error.message;
      // }
      // toast.error("problem sending request: " + `${msg}`);
      showIntErr = false;
      let errObj = error?.response?.data;
      console.log("errObj process file...", errObj);
      toast.error(errObj?.message || "");
    }
    if (showIntErr) toast.error("Internal Error Occured");
    return { success: false, data: error };
  }
}

export async function fileTextExtraction(inpData,userType) {
  try {
    const url = "/text_extract/";
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.post(AdminBaseUrl + url, inpData, config);
    console.log(
      "text extraction response from django...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("text extraction error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    console.log("text extraction error", error);
    let showIntErr = true;

    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }

    if (showIntErr) toast.error("Internal Error Occured");

    return { success: false, payload: error?.response?.data };
  }
}


// ********************************************** ADMIN PANEL API'S **********************************************

export async function fetchPromptForAdmin() {
  try {
    const url = "/get_rules/";
    let accToken = localStorage.getItem("MRKTNADMNACCTKN");
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.get(AdminBaseUrl + url, config);
    console.log("fetchfiledata response from node...", response?.data);

    return { success: true, payload: response?.data?.data };
  } catch (error) {
    console.log("fetching prompt to admin panel error", error);
    let showIntErr = true;

    // let msg = "";
    // if (error?.message) {
    //   msg = error.message;
    // }
    // toast.error("problem fetching prompts request: " + `${msg}`);
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }

    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }

    if (error?.response?.statusText === "Unauthorized") {
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
      showIntErr = false;
    }
    if (showIntErr) toast.error("Internal Error Occured");
    return { success: false, payload: error?.response?.data };
  }
}

export async function addPromptToAdmin(d = {}) {
  try {
    let tSA = [];
    tSA.push(d);
    const url = "/add_rules/";
    let accToken = localStorage.getItem("MRKTNADMNACCTKN");
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.post(
      AdminBaseUrl + url,
      { content: d.rules },
      config
    );
    console.log("createfiledata response...", response);
    if (response.status === 201) {
      let resp = await fetchPromptForAdmin();
      console.log("getting fetched rules after adding", resp);
      return resp;
    } else {
      return { payload: response?.data, success: false };
    }
  } catch (error) {
    console.log("adding prompt to admin panel error", error);
    let showIntErr = true;

    // let msg = "";
    // if (error?.message) {
    //   msg = error.message;
    // }
    // toast.error("problem adding prompt: " + `${msg}`);
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }
    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) toast.error("Internal Error Occured");
    return { success: false, payload: error };
  }
}

export async function updatePromptInAdmin(d = []) {
  try {
    const url = "/update_rules/";
    let accToken = localStorage.getItem("MRKTNADMNACCTKN");
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    console.log("getting data to send to update rules..", d);

    // inserting rules in new array....
    let newArr = [];
    d.forEach((e) => {
      let obj = { id: e.id, content: e.rules };
      newArr.push(obj);
    });

    // inserting rules in new array....

    // return
    let response = await axios.put(AdminBaseUrl + url, newArr, config);
    console.log("updateFileData response...", response);
    if (response.status === 201) {
      let resp = await fetchPromptForAdmin();
      return resp;
    } else {
      return { payload: response?.data, success: false };
    }
  } catch (error) {
    console.log("updating prompt in admin panel error", error);
    let showIntErr = true;
    // let msg = "";
    // if (error?.message) {
    //   msg = error.message;
    // }
    // toast.error("problem updating prompt: " + `${msg}`);
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }
    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) toast.error("Internal Error Occured");
    return { success: false, payload: error };
  }
}

export async function deletePromptInAdmin(d = []) {
  try {
    console.log("updated arr after delete prompt", d);
    // return
    let accToken = localStorage.getItem("MRKTNADMNACCTKN");
    console.log("getting acc tokn for delete", accToken);
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    const url = "/delete_rules/";
    let response = await axios.delete(AdminBaseUrl + url, {
      data: d,
      ...config,
    });
    console.log("deleteFileData response...", response);
    if (response.status === 201) {
      let resp = await fetchPromptForAdmin();
      return resp;
    } else {
      return { payload: response?.data, success: false };
    }
  } catch (error) {
    console.log("deleting prompt in admin panel error", error);
    let showIntErr = true;
    // let msg = "";
    // if (error?.message) {
    //   msg = error.message;
    // }
    // toast.error("problem deleting prompt: " + `${msg}`);
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }
    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) {
      toast.error("Internal Error Occured");
    }
    return { success: false, payload: error };
  }
}

// ********************** AUTHENTICATION AND AUTHORIZATION **********************

export async function signUpAdminPanel(d = {}) {
  try {
    console.log("signUp admin data to send", d);

    // temp statement...
    const url = "/org/signup/";
    let response = await axios.post(AdminBaseUrl + url, d);
    console.log("admin signUp response...", response);
    // let accessToken = response?.data?.access;

    // ******* setting access token in cookies *******

    // document.cookie = `MRKTNADMNACCTKN=${accessToken}`;
    // let accToken = localStorage.setItem("MRKTNADMNACCTKN");

    // ****** redirecting to admin ******
    let crrntURL = window.location.href;
    crrntURL = crrntURL.split("/")[0];
    crrntURL = crrntURL + "/admin";
    window.location.href = crrntURL;

    // ****** redirecting to admin ******

    // ******* setting access token in cookies *******

    return { payload: response?.data?.data, success: true };
  } catch (error) {
    console.log("signUp admin panel error", error);
    let showIntErr = true;
    // let msg = "";
    // if (error?.message) {
    //   msg = error.message;
    // }
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }
    if (showIntErr) {
      toast.error("Internal Error Occured");
    }
    return { success: false, payload: error?.response?.data };
  }
}

export async function getProfile(token) {
  try {
    const url = "/profile/";
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.get(AdminBaseUrl + url, config);
    console.log("admin get profile response...", response);

    return { payload: response?.data, success: true };
  } catch (error) {
    console.log("get admin profile error", error);
    let showIntErr = true;
    // let msg = "";
    // if (error?.message) {
    //   msg = error.message;
    // }
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) {
      toast.error("Internal Error Occured");
    }
    return { success: false, payload: error?.response?.data };
  }
}

export async function signUpUserPanel(d = {}) {
  try {
    console.log("signUp user data to send", d);
    // temp statement...
    const url = "/userSignUp";
    let response = await axios.post(AdminBaseUrl + url, d);
    console.log("user signUp response...", response);
    return { payload: response?.data?.payload, success: true };
  } catch (error) {
    console.log("signUp user panel error", error);
    let showIntErr = true;
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }
    if (showIntErr) {
      toast.error("Internal Error Occured");
    }
    return { success: false, payload: error };
  }
}

export async function createUserByAdmin(d = {}) {
  try {
    console.log("signUp user data to send", d);
    // temp statement...
    const url = "/user/signup/";
    let accToken = localStorage.getItem("MRKTNADMNACCTKN");
    if (!accToken)
      return { payload: "no token found, please login", success: false };
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.post(AdminBaseUrl + url, d, config);
    console.log("create user by admin response...", response);
    if (response?.status === 200) {
      toast.success("user created successfully!");
      return { payload: response?.data, success: true };
    } else {
      toast.error("problem creating user!");
      return { payload: response?.data, success: false };
    }
  } catch (error) {
    console.log("error creating user", error);
    let showIntErr = true;
    // let msg = "";
    // if (error?.message) {
    //   msg = error.message;
    // }
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let msgOBJ = error?.response?.data || {};
    for (let k in msgOBJ) {
      if (Array.isArray(msgOBJ[k])) {
        toast.error(msgOBJ[k][0]);
      } else {
        toast.error(msgOBJ[k]);
      }
    }
    // toast.error("problem creating user: " + `${msg}`);
    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) {
      toast.error("Internal Error Occured");
    }
    return { success: false, payload: error?.response?.data };
  }
}

export async function fetchCrtdUsers() {
  try {
    const url = "/org/users/";
    let accToken = localStorage.getItem("MRKTNADMNACCTKN");
    if (!accToken)
      return { payload: "no token found, please login", success: false };
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };

    let resp = await axios.get(AdminBaseUrl + url, config);
    console.log("fetching users by admin....", resp);
    if (resp?.status === 200) {
      return { payload: resp?.data, success: true };
    } else {
      return { payload: resp?.data, success: false };
    }
  } catch (error) {
    console.log("error creating user", error);
    let showIntErr = true;
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }
    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) toast.error("Internal Error Occured");
    return { success: false, payload: error?.response?.data };
  }
}

export async function resetUserPassword(d = {}) {
  console.log("data obj in api......", d);
  try {
    const url = "/reset_password/";
    let accToken = localStorage.getItem("MRKTNUSERACCTKN");
    if (!accToken)
      return { payload: "no token found, please login", success: false };
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };

    let resp = await axios.put(AdminBaseUrl + url, d, config);
    console.log("fetching users by admin....", resp);
    if (resp?.status === 200) {
      return { payload: resp?.data, success: true };
    } else {
      return { payload: resp?.data, success: false };
    }
  } catch (error) {
    console.log("reset password error", error);
    let showIntErr = true;
    // let msg = "";
    // if (error?.message) {
    //   msg = error.message;
    // }
    // let errObj = error?.response?.data;
    // for (let k in errObj) {
    //   toast.error(`${errObj[k]}`);
    // }
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) {
      toast.error("Internal Error Occured");
    }
    return { success: false, payload: error?.response?.data };
  }
}

export async function loginAdminPanel(d = {}) {
  try {
    // console.log("login user data to send", d);
    console.log("admn bs url..........", AdminBaseUrl);
    // temp statement...
    const url = "/login/";
    // AdminBaseUrlLocal = "http://localhost:8001";
    let response = await axios.post(AdminBaseUrl + url, d); //AdminBaseUrl
    console.log("admin login response...", response);
    // return
    let ursTyp = response?.data?.data?.user_type;
    let accessToken = response?.data?.access;
    console.log("getting acc tkn and usr type", accessToken, ursTyp);
    if (response.status === 200) {
      // ******* setting access token in cookies *******

      if (ursTyp === "admin") {
        localStorage.setItem("MRKTNADMNACCTKN", accessToken);
        // document.cookie = `MRKTNADMNACCTKN=${accessToken}`;
        // reDirectTo("/admin");
      } else if (ursTyp === "user") {
        localStorage.setItem("MRKTNUSERACCTKN", accessToken);
        // document.cookie = `MRKTNUSERACCTKN=${accessToken}`;
        // reDirectTo("/");
      }

      return { payload: response?.data, success: true };
    } else {
      // reDirectTo("/login");
      toast.error("problem logging in, try again !");
      return { payload: response?.data?.data, success: false };
    }
  } catch (error) {
    console.log("admin login panel error", error, error.code);
    let showIntErr = true;
    // let msg = "";
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }

    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }
    if (showIntErr) toast.error("Internal Error Occured");

    return { success: false, payload: error?.response?.data };
  }
}

export async function forgotPassAdminPanel(d = {}) {
  try {
    console.log("forgotPass user data to send", d);
    // temp statement...
    const url = `/forget_password/?email=${d.email}`;
    let response = await axios.get(AdminBaseUrl + url);
    console.log("admin forgotPass response...", response);
    // return

    if (response.status === 200) {
      // ******* setting access token in cookies *******

      return { payload: response?.data, success: true };
    } else {
      // reDirectTo("/login");
      toast.error("problem sending mail, try again !");
      return { payload: response?.data?.data, success: false };
    }
  } catch (error) {
    console.log("admin forgot password panel error", error);
    let showIntErr = true;
    // let msg = "";
    // if (error?.message) {
    //   msg = error.message;
    // }
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }
    if (showIntErr) {
      toast.error("Internal Error Occured");
    }
    return { success: false, payload: error?.response?.data };
  }
}

export async function frgtPssVryfUsrAdminPanel(d = {}, token) {
  try {
    console.log("forgotPass vf usr data to send", d);
    if (!token) toast.error("please use a valid link sent to your mail id");
    // temp statement...
    const url = `/verify_user/${token}/`,
      data = {
        password: d.password,
        confirm_password: d.confirm_password,
      };
    console.log("new data obj to post....", data);
    let response = await axios.post(AdminBaseUrl + url, data);
    console.log("admin forgotPass vf usr response...", response);
    // return

    if (response.status === 200) {
      // ******* setting access token in cookies *******

      return { payload: response?.data, success: true };
    } else {
      // reDirectTo("/login");
      toast.error("problem setting forgot password, try again !");
      return { payload: response?.data?.data, success: false };
    }
  } catch (error) {
    console.log("admin forgot password vf usr panel error", error);
    let showIntErr = true;
    // let msg = "";
    // if (error?.message) {
    //   msg = error.message;
    // }
    // let errObj = error?.response?.data;
    // // for (let k in errObj) {
    // //   toast.error(`${errObj[k]}`);
    // // }
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    if (showIntErr) toast.error("Internal Error Occured");
    return { success: false, payload: error?.response?.data };
  }
}
// ********************** AUTHENTICATION AND AUTHORIZATION **********************

// ********************** PRODUCT API **********************

export async function fetchAllProducts() {
  try {
    const url = "/all_products/";
    let accToken = localStorage.getItem("MRKTNADMNACCTKN");
    if (!accToken)
      return { payload: "no token found, please login", success: false };
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };

    let resp = await axios.get(AdminBaseUrl + url, config);
    console.log("fetching all products by admin....", resp);
    if (resp?.status === 200) {
      return { payload: resp?.data, success: true };
    } else {
      return { payload: resp?.data, success: false };
    }
  } catch (error) {
    console.log("error creating user", error);
    let showIntErr = true;
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }
    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) toast.error("Internal Error Occured");
    return { success: false, payload: error?.response?.data };
  }
}

export async function deleteMultiProducts(d = []) {
  try {
    const url = "/user/delete_profile/";
    let accToken = localStorage.getItem("MRKTNADMNACCTKN");
    if (!accToken)
      return { payload: "no token found, please login", success: false };
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };

    // let resp = await axios.get(AdminBaseUrl + url, config);
    let resp = await axios.delete(AdminBaseUrl + url, {
      data: d,
      ...config,
    });
    console.log("delete multiple users....", resp);
    if (resp?.status === 200) {
      return { payload: resp?.data, success: true };
    } else {
      return { payload: resp?.data, success: false };
    }
  } catch (error) {
    console.log("error creating user", error);
    let showIntErr = true;
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }
    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) toast.error("Internal Error Occured");
    return { success: false, payload: error?.response?.data };
  }
}

export async function editMultiProducts(d = []) {
  try {
    const url = "/user/update_profile/";
    let accToken = localStorage.getItem("MRKTNADMNACCTKN");
    if (!accToken)
      return { payload: "no token found, please login", success: false };
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };

    // let resp = await axios.get(AdminBaseUrl + url, config);
    let resp = await axios.put(AdminBaseUrl + url, d, config);
    console.log("edit multiple users....", resp);
    if (resp?.status === 200) {
      return { payload: resp?.data, success: true };
    } else {
      return { payload: resp?.data, success: false };
    }
  } catch (error) {
    console.log("error creating user", error);
    let showIntErr = true;
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }
    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) toast.error("Internal Error Occured");
    return { success: false, payload: error?.response?.data };
  }
}
// ********************** PRODUCT API **********************

// ********************************************** ADMIN PANEL API'S **********************************************

// ********************** SEARCH PANEL API **********************

export async function sendVoiceDataForAdmin(dataVC, userType) {
  try {
    const url = "/search_panel/";
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.post(AdminBaseUrl + url, dataVC, config);
    console.log(
      "fetchfiledata response from node...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return {
        success: true,
        payload: {
          text: {
            ques: response?.data?.input_text,
            ans: response?.data?.output_text,
          },
          audio: response?.data?.output_file_path,
        },
      };
    } else {
      console.log("inside speech recgonition error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    let showIntErr = true;
    console.log("fetching prompt to admin panel error", error);
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) {
      toast.error("Internal Error Occured");
    }
    return { success: false, payload: error?.response?.data };
  }
}

export async function getUsrConvForSrchPnl(userType) {  // ------------------------------- Un-used api -------------------
  try {
    const url = "/user/conversations/";
    // const AdminBaseUrl = "http://localhost:8001";
    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.get(AdminBaseUrl + url, config);
    console.log(
      "user conversation from node...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("user conversation error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    let showIntErr = true;
    console.log("fetching user conversation error", error);
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) {
      toast.error("Internal Error Occured");
    }
    return { success: false, payload: error?.response?.data };
  }
}

export async function searchByTextSrchPnl(inpVal,userType) {
  try {
    const url = "/search_panel_text/";
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.post(
      AdminBaseUrl + url,
      { input_text: inpVal },
      config
    );
    console.log(
      "text search results from node...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("text search error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    let showIntErr = true;
    console.log("text search error", error);
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj?.message) {
      showIntErr = false;
      toast.error(errObj?.message || "");
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) {
      toast.error("Internal Error Occured");
    }
    return { success: false, payload: error?.response?.data };
  }
}

// ********************** SEARCH PANEL API **********************

// ********************** PPT MAKER API **********************

export async function generatePPtFile(inpData,userType) {
  try {
    const url = "/presentation_maker/";
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.post(AdminBaseUrl + url, inpData, config);
    console.log(
      "generate ppt response from django...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("generate ppt status error", response?.data?.message);
      toast.error(response?.data?.message || response?.data?.statusText);
      return { success: false, payload: "" };
    }
  } catch (error) {
    let showIntErr = true;
    console.log("generate ppt error", error);
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    } else if (error?.code === "ERR_BAD_RESPONSE") {
      showIntErr = false;
      toast.error("Internal Server Error");
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) {
      toast.error("Internal Error Occured");
    }
    return { success: false, payload: error?.response?.data };
  }
}

export async function voiceToTextPPt(inpData,userType) {
  try {
    const url = "/voice_to_text/";
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.post(AdminBaseUrl + url, inpData, config);
    console.log(
      "voice to text ppt response from django...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("text search error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    let showIntErr = true;
    console.log("voice to text ppt error", error);
    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      showIntErr = false;
      toast.error("Server Network Timeout");
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }
    if (showIntErr) {
      toast.error("Internal Error Occured");
    }
    return { success: false, payload: error?.response?.data };
  }
}

export async function fileToTextPPt(inpData,userType) {
  try {
    const url = "/file_to_text/";
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.post(AdminBaseUrl + url, inpData, config);
    console.log(
      "file_to_text ppt response from django...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("file_to_text error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    console.log("file_to_text ppt error", error);
    let showIntErr = true;

    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }

    if (showIntErr) toast.error("Internal Error Occured");

    return { success: false, payload: error?.response?.data };
  }
}

// ********************** PPT MAKER API **********************

// ********************** ENG TUTOR API **********************

export async function voiceToTextConvtrEngTut(inpData,userType) {
  try {
    const url = "/voice_to_text/";
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.post(AdminBaseUrl + url, inpData, config);
    console.log(
      "file_to_text ppt response from django...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("file_to_text error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    console.log("file_to_text ppt error", error);
    let showIntErr = true;

    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }

    if (showIntErr) toast.error("Internal Error Occured");

    return { success: false, payload: error?.response?.data };
  }
}

export async function fetchUsrConversationEngTut(page = 1,userType) {
  try {
    const url = `/eng_tutor/chats/?page=${page}`;
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.get(AdminBaseUrl + url, config);
    console.log(
      "file_to_text ppt response from django...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("file_to_text error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    console.log("file_to_text ppt error", error);
    let showIntErr = true;

    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }

    if (showIntErr) toast.error("Internal Error Occured");

    return { success: false, payload: error?.response?.data };
  }
}

export async function sendMessageEngTut(inpData,userType) {
  try {
    const url = "/eng_tutor/chats/";
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.post(AdminBaseUrl + url, inpData, config);
    console.log(
      "file_to_text ppt response from django...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("file_to_text error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    console.log("file_to_text ppt error", error);
    let showIntErr = true;

    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }

    if (showIntErr) toast.error("Internal Error Occured");

    return { success: false, payload: error?.response?.data };
  }
}

export async function getAllSuppLanguagesEngTut(userType) {
  try {
    const url = "/all_languages/";
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status");
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.get(AdminBaseUrl + url, config);
    console.log(
      "get all supported languages response...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("get all supported languages conditional error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    console.log("get all supported languages ppt error", error);
    let showIntErr = true;

    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }

    if (showIntErr) toast.error("Internal Error Occured");

    return { success: false, payload: error?.response?.data };
  }
}

export async function deleteLangTutChatEngTut(userType) {
  try {
    const url = "/eng_tutor/delete_chats/";
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.delete(AdminBaseUrl + url, config);
    console.log(
      "delete lang tut chat response...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("delete lang tut chat conditional error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    console.log("delete lang tut chat error error", error);
    let showIntErr = true;

    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }

    if (showIntErr) toast.error("Internal Error Occured");

    return { success: false, payload: error?.response?.data };
  }
}

export async function setLangToLearnEngTut(data={},userType) {
  try {
    const url = "/user/update/";
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType,"    data ---->",data);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.put(AdminBaseUrl + url,data,config);
    console.log(
      "set lang to learn api response...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("set lang to learn api conditional error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    console.log("set lang to learn api error", error);
    let showIntErr = true;

    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }

    if (showIntErr) toast.error("Internal Error Occured");

    return { success: false, payload: error?.response?.data };
  }
}

// ********************** ENG TUTOR API **********************


// ********************** NOTES ENG TUTOR API ********************

export async function fetchNotesData(userType){
  try {
    const url = "/notes/get/";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status");
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.get(AdminBaseUrl + url, config);
    console.log(
      "get all notes response...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("get all notes conditional error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    console.log("get all notes error", error);
    let showIntErr = true;

    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }

    if (showIntErr) toast.error("Internal Error Occured");

    return { success: false, payload: error?.response?.data };
  }
}

export async function createNotes(inpData,userType){ 
  try {
    const url = "/notes/create/";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.post(AdminBaseUrl + url, inpData, config);
    console.log(
      "create notes response from django...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("create notes error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    console.log("create notes error", error);
    let showIntErr = true;

    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }

    if (showIntErr) toast.error("Internal Error Occured");

    return { success: false, payload: error?.response?.data };
  }
}

export async function updateNotes(inpData,userType){ 
  try {
    const url = "/notes/update/";
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType,"    data ---->",inpData);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    let response = await axios.put(AdminBaseUrl + url,inpData,config);
    console.log(
      "update notes api response...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("update notes api conditional error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    console.log("update notes api error", error);
    let showIntErr = true;

    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }

    if (showIntErr) toast.error("Internal Error Occured");

    return { success: false, payload: error?.response?.data };
  }
}

export async function deleteNotes(inpData,userType){
  try {
    const url = "/notes/delete/";
    // const AdminBaseUrl = "http://localhost:8001";

    console.log("getting user type action API ------------->",userType);

    if(!userType){
      toast.error("problem fetching user status")
    }

    let accToken = "";
    if (userType === "admin") {
      console.log("indside usertype admin ------------->");
      accToken = localStorage.getItem("MRKTNADMNACCTKN");
    } else if (userType === "user") {
      console.log("indside usertype user ------------->");
      accToken = localStorage.getItem("MRKTNUSERACCTKN");
    }
    if (!accToken) {
      return { payload: "no token found, please login", success: false };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accToken}`,
      },
    };
    
    let response = await axios.delete(AdminBaseUrl + url, {
      data: inpData,
      ...config,
    });
    console.log(
      "delete notes response...",
      response?.data,
      "status:---->",
      response?.data?.status
    );

    if (response?.data?.status) {
      return { success: true, payload: response?.data };
    } else {
      console.log("delete notes conditional error", response?.data?.message);
      toast.error(response?.data?.message);
      return { success: false, payload: "" };
    }
  } catch (error) {
    console.log("delete notes error", error);
    let showIntErr = true;

    if (error?.code === "ERR_NETWORK") {
      // console.log("axios error");
      toast.error("Server Network Timeout");
      showIntErr = false;
    }
    let errObj = error?.response?.data;
    if (errObj) {
      toast.error(errObj?.message || "");
      showIntErr = false;
    }

    if (error?.response?.statusText === "Unauthorized") {
      showIntErr = false;
      toast.error("Session Timeout!");
      let x = setTimeout(() => {
        reDirectTo("/login");
        clearTimeout(x);
      }, 1000);
    }

    if (showIntErr) toast.error("Internal Error Occured");

    return { success: false, payload: error?.response?.data };
  }
}

// ********************** NOTES ENG TUTOR API ********************