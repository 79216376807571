import "./headingCont.css";

export function HeadingCont() {
  return (
    <>
      <div className="headingCont">
        {/* <img
          src="/assests/img/companyLogo.png"
          className="companyLogo"
          alt="compLogo"
        /> */}
        <h2 className="headingTitle">
          Document Summarizer AI
        </h2>
      </div>
    </>
  );
}

export function HeadingContAdmin() {
  return (
    <>
      <div className="headingCont">
      <h2 className="headingTitle makeAdmnBlue">
          Document Summarizer AI
        </h2>
      </div>
    </>
  );
}

export function HeadingContSearchPanel() {
  return (
    <>
      <div className="headingCont">
        
        <h2 className="headingTitle">
          Advance AI Search Tool
        </h2>
      </div>
    </>
  );
}