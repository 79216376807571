import "./selectDropDown.css";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { AiFillCaretDown, AiOutlineClose } from "react-icons/ai";
import { Checkbox } from "@mui/material";

export function FormSelectInp({
  title = "",
  error,
  // nameStr,
  handlChange,
  // placeHldr,
  // idStr = "",
  optionList = [],
  selProdList = [],
  initialLabelDropDown = "",
  multiSel = true,
  disabled = false,
}) {
  // const [selOptions, setSelOptions] = useState([]);

  function handleSelectedOptions(value) {
    console.log("getting value in select arr list------->", value);
    console.log("getting in select arr product list------->", selProdList);
    if (selProdList?.some((x) => x?.id === value?.id)) {
      let newArr = selProdList?.filter((e) => value?.id !== e?.id);
      handlChange(newArr);
      // setSelOptions([...newArr]);
    } else {
      if (multiSel) {
        handlChange([...selProdList, value]);
      } else {
        handlChange([{ ...value }]);
      }
      // setSelOptions([...selOptions, value]);
    }
  }

  function handleRemoveSelOpt(value) {
    let newArr = selProdList?.filter((e) => value?.id !== e?.id);
    handlChange(newArr);
    // setSelOptions([...newArr]);
  }
  return (
    <div className="adminSignupFormInpWrppr">
      {title && <p className="adminSignupFormInpLable">{title}</p>}
      <div className="adminSignupFormInpElemWrppr adminSignupFormSelectElemWrppr">
        <CustomSelectDropDown
          optList={optionList}
          handleSelOptions={(v) => {
            handleSelectedOptions(v);
          }}
          multi={multiSel}
          selectedArrList={selProdList}
          initialLabel={initialLabelDropDown}
          disabled={disabled}
        />
        {multiSel
          ? selProdList?.length > 0 && (
              <div className="custSelectedProdLableListWrppr">
                {selProdList?.map((e) => (
                  <CustomSelProdLabel
                    title={e?.name}
                    handleRemSelOpt={() => {
                      handleRemoveSelOpt(e);
                    }}
                  />
                ))}
              </div>
            )
          : ""}
      </div>
      {error && <p className="adminSignupFormInpErrLable">{error}</p>}
    </div>
  );
}

export function CustomSelectDropDown({
  optList = [],
  handleSelOptions,
  multi = false,
  selectedArrList = [],
  initialLabel = "Select Options",
  disabled = false,
}) {
  const [drpDwnMenu, setDrpDwnMenu] = useState(false);

  const selDrpDwnCont = useRef(null);

  console.log("selected arr list -------->", selectedArrList);
  console.log("multi select prop: -------->", multi);

  function handleSelect(value) {
    // console.log("selected value ---->", value);
    handleSelOptions(value);
    !multi && setDrpDwnMenu(false);
  }

  function handleClickOutSide(e) {
    let target = e.target;
    if (selDrpDwnCont?.current && !selDrpDwnCont?.current.contains(target)) {
      // console.log("clicked outside ------->");
      setDrpDwnMenu(false);
    }
  }

  function handleClickSelectBtn(e) {
    e.preventDefault();
    // console.log("button clicked------->");
    if (!(optList?.length > 0)) {
      toast.warn("no list to select from");
      return;
    }
    setDrpDwnMenu(!drpDwnMenu);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);

    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, []);

  return (
    <>
      <div className="customSelDrpDwnWrppr" ref={selDrpDwnCont}>
        <button
          className="customSelDrpDwnBtn"
          onClick={handleClickSelectBtn}
          disabled={disabled}
        >
          {initialLabel} <AiFillCaretDown style={{ marginLeft: "5px" }} />
        </button>
        {drpDwnMenu && optList?.length > 0 ? (
          <ul className={"customSelDrpDwnMenu"}>
            {optList?.map((e, i) => {
              return (
                <li
                  className={`customSelDrpDwnOpt ${
                    multi
                      ? ""
                      : selectedArrList?.some((x) => x?.id === e?.id)
                      ? "actvCustmSelDrpDwn"
                      : ""
                  }`}
                  onClick={() => handleSelect(e)}
                  key={`customSelDrpDwnOptDNDK${i}`}
                >
                  {e?.name}
                  {/* {selectedArrList?.some(x=>x?.id === e?.id) ? (
                      <AiFillMinusCircle color="#00AFEF" />
                    ) : (
                      <AiFillPlusCircle color="#00AFEF" />
                    )} */}
                  {multi && (
                    <Checkbox
                      checked={selectedArrList?.some((x) => x?.id === e?.id)}
                      sx={{
                        color: "#00AFEF",
                        "&.Mui-checked": { color: "#00AFEF" },
                      }}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export function CustomSelProdLabel({
  title,
  handleRemSelOpt,
  onlyLabel = false,
}) {
  function handleClose(e) {
    e.preventDefault();
    console.log("remove this product...", title);
    handleRemSelOpt();
  }
  return (
    <button className="customSelProdLabel">
      {title}{" "}
      {!onlyLabel && (
        <AiOutlineClose
          onClick={handleClose}
          className="closeBtnCustomSelProdLabel"
        />
      )}
    </button>
  );
}
