import "./header.css";
import { reDirectTo } from "../utility/functions/helperFunc";
import { MyContext } from "../../context/context";
import { useContext, useEffect } from "react";
// import { readAndUpdateRoutes } from "../utility/functions/helperFunc";
import { BiSolidUserCircle } from "react-icons/bi";

// const BaseUrl_Local = "";
// const BaseUrl_Server = "https://marktine-summarizer.azurewebsites.net";

function Header({ userStatus }) {
  const { admin } = useContext(MyContext);
  function logout() {
    console.log("clicking logout btn...");
    // const ADMIN_COOKIE = "MRKTNADMNACCTKN";
    // const USER_COOKIE = "MRKTNUSERACCTKN";

    localStorage.clear();
    reDirectTo("/admin");
  }

  function handleHdrPg(route = "", currPg = "") {
    // re-renders the header, triggers header update in useEffect
    reDirectTo(route);
  }

  function readAndUpdateRoutes(str = "") {
    let crrntURL = window.location.href;
    console.log("current url: ---------->", crrntURL);
    if (!crrntURL.includes("/admin")) {
      console.log("inside not admin scope ---------->", crrntURL);
      let crrntURLNew = crrntURL.split("3000");
      crrntURLNew = crrntURLNew[crrntURLNew.length - 1];
      if (crrntURLNew === "/" || crrntURLNew === "") {
        console.log("inside not admin scope ---------->, home screen");
        admin.updateHeaderState("HomePage");
      } else if (crrntURLNew === "/summarizer") {
        admin.updateHeaderState("Summarizer");
      } else if (crrntURLNew === "/ppt_maker") {
        console.log("inside not admin scope ---------->, ppt screen");
        admin.updateHeaderState("PPTMaker");
      } else if (crrntURLNew === "/eng_tutor") {
        admin.updateHeaderState("Eng_Tutor");
      }
      return;
    }

    crrntURL = crrntURL.split("/admin");
    // console.log("readAndUpdate111111----------->",crrntURL);
    crrntURL = crrntURL[crrntURL.length - 1];
    // console.log("readAndUpdateRoutes----------->",crrntURL);

    if (crrntURL === "/voiceSearchPanel") {
      // console.log("inside voiceSearchPanel----------->",crrntURL);
      admin.updateHeaderState("Search Panel");
    } else if (crrntURL === "/addUser") {
      // console.log("inside addUser----------->",crrntURL);
      admin.updateHeaderState("User");
    } else if (crrntURL === "/app") {
      // console.log("inside app----------->",crrntURL);
      admin.updateHeaderState("Summarizer");
    } else if (crrntURL === "/rules") {
      // console.log("inside admin----------->");
      admin.updateHeaderState("Rules");
    } else if (crrntURL === "/eng_tutor") {
      admin.updateHeaderState("Eng_Tutor");
    } else if (crrntURL === "" || crrntURL === "/") {
      admin.updateHeaderState("HomePage");
    }
  }

  console.log("hdr pg context value........", admin.hdrPg);
  useEffect(() => {
    readAndUpdateRoutes();
  }, []);
  return (
    <>
      <div className="docSummHeader">
        <div className="logoCont">
          <img
            src="/assests/img/logoMarktine.png"
            className="logoImg"
            alt="Logo"
          />
        </div>
        <div className="optionsCont">
          {userStatus === "admin" ? (
            <>
              <button
                className={`hdrpgSwitchBtn ${
                  admin.hdrPg === "HomePage" ? "actvHdrPgSwtBtn" : ""
                }`}
                onClick={() => handleHdrPg("/admin", "HomePage")}
                style={{ margin: "0px 20px" }}
              >
                Home Page
              </button>
              <button
                className={`hdrpgSwitchBtn ${
                  admin.hdrPg === "Rules" ? "actvHdrPgSwtBtn" : ""
                }`}
                onClick={() => handleHdrPg("/rules", "Rules")}
              >
                Rules
              </button>

              <button
                className={`hdrpgSwitchBtn ${
                  admin.hdrPg === "User" ? "actvHdrPgSwtBtn" : ""
                }`}
                onClick={() => handleHdrPg("/admin/addUser", "User")}
                style={{ margin: "0px 20px" }}
              >
                User Management
              </button>
            </>
          ) : (
            <>
              <button
                className={`hdrpgSwitchBtn ${
                  admin.hdrPg === "HomePage" ? "actvHdrPgSwtBtn" : ""
                }`}
                onClick={() => handleHdrPg("/", "HomePage")}
                style={{ margin: "0px 20px" }}
              >
                Home Page
              </button>
            </>
          )}

          <BiSolidUserCircle className="optionProfl" />

          <button
            className={"hdrpgSwitchBtn"}
            onClick={logout}
            style={{ margin: "0px 20px" }}
          >
            Log out
          </button>
        </div>
      </div>
    </>
  );
}

export default Header;
