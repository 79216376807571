import "./downloadCont.css";
// import { TextContainer } from "../uploadCont/uploadCont.js";
import { FileSyncButton } from "../utility/button/button";
import { useEffect, useState,useContext } from "react";
import {MyContext} from '../../context/context.js';
import {sendUploadedData} from '../../actions/action.js';
import LoadingComponent from "../loadingSpinner/loadingSpinner";
import {toast} from 'react-toastify';
import {RiSendPlaneFill} from "react-icons/ri";
import {MdContentCopy} from "react-icons/md";



function DownloadCont() {

  // const [wrdLimit,setWrdLimit] = useState('');
  const [summVal,setSummVal] = useState('');

  const {  
    updateResponse,
    files,
    text,
    updateWordLimit,
    wordLimitCont,
    responseCont,
    updateLoadingState,
    isLoading,
    primary,
     } = useContext(MyContext);

  function handleSummaryVal(v){
    setSummVal(v);
  }

  async function handleButtelPoint() {
    // setSummVal('');
    if(!files[0]&&!text){
      console.log("no files returning 0");
      return;
    }
    console.log("calling buttel points api...");

    const formData = new FormData();
  // Append each file to the FormData object
  // let newFilesArr=[];
  console.log("calling parameters======>","primary",primary,"files",files,"wordLimitCont",wordLimitCont,"text",text);

  for (let i = 0; i < files.length; i++) {
    formData.append("files", primary || files[i]);
  }
  formData.append("word_limit", wordLimitCont || 0);
  formData.append("doc_layout", "bullet points");
  formData.append("text_string", text || "");
  // Append each file to the FormData object
  updateLoadingState(true);
  let response = await sendUploadedData(formData);
  updateLoadingState(false);
  updateResponse(response);


  }

  function handleWordLimit(e){
    let val = e.target.value;
    // setWrdLimit(val);
    updateWordLimit(val);
  }

  async function handleParagraph() {
    // setSummVal('');
    if(!files[0]&&!text){
      console.log("no files returning 0");
      return;
    }
    console.log("calling paragraph api...");
    console.log("calling parameters======>","primary",primary,"files",files,"wordLimitCont",wordLimitCont,"text",text);
    const formData = new FormData();


  // Append each file to the FormData object
  // let newFilesArr=[];
  // console.log("newFilesArray======>",newFilesArr);
  for (let i = 0; i < files.length; i++) {
    formData.append("files", primary || files[i]);
  }
  formData.append("word_limit", wordLimitCont || 0);
  formData.append("doc_layout", "Paragraph");
  formData.append("text_string", text || "");
  // Append each file to the FormData object
  updateLoadingState(true);
  let response = await sendUploadedData(formData);
  updateLoadingState(false);
  updateResponse(response);

  }

  async function handleWordSubmit(){
    // setSummVal('');
    if(!files[0]&&!text){
      console.log("no files returning 0");
      return;
    }  
  const formData = new FormData();


  // Append each file to the FormData object
  // let newFilesArr=[];
  // console.log("newFilesArray======>",newFilesArr);
  console.log("calling parameters======>","primary",primary,"files",files,"wordLimitCont",wordLimitCont,"text",text);
  for (let i = 0; i < files.length; i++) {
    formData.append("files", primary || files[i]);
  }
  formData.append("word_limit", wordLimitCont);
  formData.append("doc_layout", "empty");
  formData.append("text_string", text || "");
  // Append each file to the FormData object
  updateLoadingState(true);
  let response = await sendUploadedData(formData);
  updateLoadingState(false);
  updateResponse(response);
  }
  
  async function copyGenrtdSumm(){
    await navigator.clipboard.writeText(summVal);
    console.log("copyinh generate text!",summVal);
  }

  function handleDownloadData(){
    console.log("response context api",responseCont);
    if(!responseCont?.success){
      console.log("no response returning 0");
      return;
    }
    console.log("intiating download process..");
    
    
    // const content = 'Hello, world!'; // The content of the .txt file
    const element = document.createElement('a');
    const file = new Blob([summVal], { type: 'text/plain' });
  
    element.href = URL.createObjectURL(file);
    element.download = 'summary.txt';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

  }

  function handleKeyDwn(e){
    if(e.keyCode === 13){
      e.preventDefault();
      handleWordSubmit();
    }
  }


  return (
    <>
      <div className="downloadDocWrppr">
        <div className="optionsWrppr">
          <OptionsWrapper label={"Word Limit"}>
            <input type="number" className="wordLimitInp" placeholder="Word Limit Ex: 200" value={wordLimitCont} onChange={(e)=>{handleWordLimit(e)}} onKeyDown={(e)=>{handleKeyDwn(e)}} />
            {/* <img src="./assests/img/share button.svg" alt="send" className="shareBtnWordLimit" onClick={handleWordSubmit} /> */}
            <div className="sendReactIconWrppr shareBtnWordLimit" onClick={handleWordSubmit}>
              <RiSendPlaneFill size={"22px"}/>
            </div>
          </OptionsWrapper>
          <OptionsWrapper label={"Summary in"}>
            <div style={{marginRight:"13px"}}>
            <FileSyncButton
              title={"Bullets Points"}
              src={"BulletIcon.png"}
              bkg="makeBlackBtn"
              handleClick={() => {
                handleButtelPoint();
              }}
            />
            </div>
            <FileSyncButton
              title={"Paragraph"}
              src={"paragrphIcon.png"}
              bkg="makeBlackBtn"
              handleClick={() => {
                handleParagraph();
              }}
            />
          </OptionsWrapper>
        </div>
        <div className="contentWrppr">
          <DownldTextCont handlSumm={(v)=>{handleSummaryVal(v)}} summValue={summVal} />
          {isLoading?<LoadingComponent/>:''}
          {/* <img src="./assests/img/copyIcon.png" alt="copy" className="donwloadDocCopyIcon" onClick={copyGenrtdSumm} /> */}
          <MdContentCopy className="donwloadDocCopyIcon" onClick={copyGenrtdSumm} />
        </div>
        <div className="uplBttonWrppr">
          <FileSyncButton
            title={"Download Summary"}
            src={"downloadBtnIcon.png"}
            bkg={"makeBlueBtn"}
            handleClick={() => {
              handleDownloadData();
            }}
          />
        </div>
      </div>
    </>
  );
}

export default DownloadCont;

function OptionsWrapper({ children, label }) {
  return (
    <>
      <div className="optBtnWrpprMain">
        <span className="optBtnLabel">{label}</span>
        <div className="optBtnWrppr">{children}</div>
      </div>
    </>
  );
}

function DownldTextCont({handlSumm,summValue}) {
  // const [summVal,setSummVal] = useState('');
  const {
    responseCont,
     } = useContext(MyContext);

  useEffect(()=>{
    // setSummVal(responseCont);
    console.log("response from context api...",responseCont?.data?.data?.Summary);
    let errr = responseCont?.data?.data?.Status?.success;
    console.log("getting response testing toast....",responseCont,'err',errr);
    if((errr!== undefined)&&!errr)toast.error('problem getting summary');
    let newObj = responseCont?.data?.data?.Summary,str='';
    if(newObj?.["Cumulative Compilation"]?.data){
      str+=`${newObj["Cumulative Compilation"].data}`;
    }else{
      for(let key in newObj){
        str+= `${newObj[key].data || ''}\n`;
      }
    }
    console.log("getting data extracted...",str);
    // setSummVal(str);
    handlSumm(str);
    
  },[responseCont])

  return (
    <>
      <textarea className="dwonloadTextArea" readOnly={true} value={summValue} placeholder="Your summarize Content Here..." />
    </>
  );
}



// ********************************* LOADING ******************************************

// const LoadingComponent = ({ text="loading", size="300px", color="black" }) => {
//   return (
//     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',position: "absolute",
//     bottom: "18px",
//     right: "28px", }}>
//       <div className="loading-spinner" style={{ width: size, height: size }}>
//         <div className="loading-spinner-inner" style={{ borderTopColor: color }}></div>
//       </div>
//       <div style={{ marginLeft: '10px' }}>{text}</div>
//     </div>
//   );
// };

// ********************************* LOADING ******************************************